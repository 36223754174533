import {
  CustomHtmlField,
  DefinitionBlock,
  FieldType,
  Targets,
} from 'models/publisher/block';
import { useProgram } from 'contexts/program';
import { RenderStatusContext } from 'contexts/publisher/compose/render-status';
import { useStyleEditor } from 'contexts/publisher/compose/style';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useToggle } from 'hooks/useToggle';
import { useUniqueId } from 'hooks/useUniqueId';
import * as React from 'react';
import { useRenderer } from 'hooks/content-blocks';
import styles from './editor.module.css';
import { FullHtmlEditor } from '../FullHtmlEditor';
import { InlineEditor } from '../InlineEditor';
import { Loading } from '../Loading';
import { CodeViewCallback } from '../useEditableInstance';
import { useRenderCache } from '../useRenderCache';
import { ErrorsOverlay } from '../ErrorsOverlay';

export const Renderer: React.FC<{
  block: DefinitionBlock;
  onChange: (fieldName: string, data: FieldType) => void;
  blockId: string;
  enableRender?: boolean;
  enableLiveEdits?: boolean;
  onFocus: () => void;
  onDropzoneUploading: (isUploading: boolean) => void;
  codeViewCallback: CodeViewCallback;
  showCodeViewButton?: boolean;
  showEditor: (() => void) | false;
  selectBlock: () => void;
}> = ({
  block,
  onChange,
  blockId,
  enableRender,
  enableLiveEdits,
  onFocus: parentOnFocus,
  onDropzoneUploading,
  codeViewCallback,
  showCodeViewButton,
  showEditor,
  selectBlock,
}) => {
  const { style } = useStyleEditor();
  const { update } = React.useContext(RenderStatusContext);
  const focus = useToggle();

  const onFocus = React.useCallback(() => {
    parentOnFocus();
    focus.enable();
  }, [focus, parentOnFocus]);

  const render = useRenderer(block, style, Targets.web);

  const isCustomHtmlBlock = React.useMemo(() => {
    return block.name === 'custom_html';
  }, [block.name]);

  const html = useRenderCache({
    html: render.html,
    errors: render.errors,
    enableRender,
  });

  const { id: programId } = useProgram();

  const htmlTemplatesEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.HTMLTemplates'
  ).data?.value;

  const looksOk = render.errors.length < 1;
  const toolbarId = `dynamic-block-inline-editor-toolbar-${useUniqueId()}`;
  const hasPreviousRender = html !== '';
  const isLoading = !hasPreviousRender && looksOk;
  const isInitting = !hasPreviousRender && !looksOk;

  const isErrored = hasPreviousRender && !looksOk;
  React.useEffect(() => {
    update(blockId, looksOk);
  }, [blockId, looksOk, update]);

  const [autoOpened, ranAutoOpen] = React.useState(false);
  const autoOpenRefs = React.useRef({ showEditor, selectBlock });
  autoOpenRefs.current = { showEditor, selectBlock };
  React.useEffect(() => {
    if (!autoOpened && isInitting && autoOpenRefs.current.showEditor) {
      autoOpenRefs.current.selectBlock();
      autoOpenRefs.current.showEditor();
      ranAutoOpen(true);
    }
  }, [autoOpened, isInitting, autoOpenRefs]);

  React.useEffect(() => {
    if (isInitting && showEditor) showEditor();
  }, [isInitting, showEditor]);

  if (isCustomHtmlBlock && htmlTemplatesEnabled) {
    return (
      <>
        <FullHtmlEditor
          html={html}
          onChange={onChange}
          onLeave={() => {}}
          blockId={blockId}
          block={block as DefinitionBlock<{ customHtml: CustomHtmlField }>}
        />
        {!looksOk && <ErrorsOverlay errors={render.errors} />}
      </>
    );
  }
  return (
    <div>
      <div className={styles.editorToolbarContainer}>
        <div id={toolbarId} className={styles.editorToolbar} />
      </div>
      <div className="editable" style={{ position: 'relative' }}>
        {isLoading && <Loading />}
        {!isLoading && (
          <InlineEditor
            blockId={blockId}
            html={html}
            block={block}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={focus.disable}
            onDropzoneUploading={onDropzoneUploading}
            codeViewCallback={codeViewCallback}
            showCodeViewButton={showCodeViewButton}
            disableFroala={!enableLiveEdits}
            toolbarId={toolbarId}
          />
        )}
        {isErrored && <ErrorsOverlay errors={render.errors} />}
      </div>
    </div>
  );
};
