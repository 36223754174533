import React from 'react';

import { useProgram } from 'contexts/program';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Text } from 'DesignSystem/Typography';
import { useValueSuggestionsQuery } from 'hooks/audience';
import { DateTime } from 'luxon';
import { ComplexExpression } from 'models/expression';
import { RecurringTrigger, timeZones } from 'models/journeys/journey';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { capitalizeWords } from 'utility/strings';
import { pluralize } from 'utility/text';
import {
  getJourneyTriggerSchedule,
  TriggerScheduleParams,
} from 'services/api-journey';
import { useQuery } from 'react-query';
import { editableTextToQuery } from '../../../JourneyDrawer/shared/AudienceSelect';
import { isEveryone as everyone, parseCriterion } from './utils';

interface TriggerScheduleItem {
  readonly dateTime: DateTime;
}

type RecurringNextRunTimeFormat =
  | 'MMMM d, yyyy h:mma'
  | 'EEEE, MMMM d, yyyy h:mma';

function format(
  dateTime: DateTime,
  dateFormat: RecurringNextRunTimeFormat,
  timeZone: string
): string {
  const timezoneValue = timeZones.find(
    (tz) => tz.name === timeZone || tz.group.includes(timeZone)
  );
  return `${dateTime.toFormat(dateFormat)} ${timezoneValue?.label}`;
}

function useInitiationTriggerSchedule({
  type,
  ...params
}: TriggerScheduleParams & { dateFormat: string }) {
  const programId = useProgram().id;
  return useQuery({
    queryKey: [type, params],
    queryFn: async () => {
      const data = await getJourneyTriggerSchedule(programId, {
        type,
        ...params,
      });
      const schedule: readonly TriggerScheduleItem[] = [
        ...data.data.schedule.map<TriggerScheduleItem>((at) => {
          const run = DateTime.fromMillis(at).setZone(params.timeZone);
          return {
            dateTime: run,
          };
        }),
      ];

      return {
        ...data,
        schedule,
      };
    },
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60,
  });
}

interface RecurringNextRunsProps {
  trigger: Pick<
    RecurringTrigger,
    'timeZone' | 'timeOfDay' | 'startTriggeringOn'
  >;
  readonly scheduleFilter?: ScheduleFilter;
  readonly limit?: number;
  readonly dateFormat?: RecurringNextRunTimeFormat;
  readonly renderItem?: (formattedRun: string) => JSX.Element;
}

export class ScheduleFilter {
  private constructor(
    public readonly filter: (schedule: TriggerScheduleItem) => boolean
  ) {}

  static readonly Future = new ScheduleFilter(
    (schedule: TriggerScheduleItem) => schedule.dateTime > DateTime.now()
  );

  static readonly Any = new ScheduleFilter((_: TriggerScheduleItem) => true);
}

export function RecurringNextRuns({
  trigger,
  limit = 3,
  dateFormat = 'EEEE, MMMM d, yyyy h:mma',
  scheduleFilter = ScheduleFilter.Any,
  renderItem = (formattedRun) => (
    <Text className={{ Body: true }} key={formattedRun} data-testid="testing">
      {formattedRun}
    </Text>
  ),
}: RecurringNextRunsProps): JSX.Element {
  const { data: scheduleData, isLoading } = useInitiationTriggerSchedule({
    type: 'recurring',
    timeZone: trigger.timeZone,
    timeOfDay: trigger.timeOfDay,
    startTriggeringOn: trigger.startTriggeringOn,
    dateFormat,
  });

  const nextThreeRuns = (
    scheduleData?.schedule
      .filter(scheduleFilter.filter)
      .map((schedule) =>
        format(schedule.dateTime, dateFormat, trigger.timeZone)
      ) ?? []
  ).slice(0, limit);

  return (
    <Flex start style={{ gap: 12 }}>
      <Flex column alignStart start>
        {nextThreeRuns.map((formattedRun) => renderItem(formattedRun))}
      </Flex>
      {isLoading && <LoadingSpinner size="small" />}
    </Flex>
  );
}
export const RecurringBody: React.FC<{
  trigger: RecurringTrigger;
  criterion?: ComplexExpression;
}> = ({ trigger, criterion }) => {
  const { id: programId } = useProgram();
  const { isLoading, data: options = [] } = useValueSuggestionsQuery(
    programId,
    'group',
    trigger.triggerCriterion?.values.join('|') ?? '*',
    editableTextToQuery('*'),
    undefined,
    true
  );
  const audienceNameById = options.reduce<Record<string, string>>(
    (acc, option) => ({
      ...acc,
      [option.value]: option.label,
    }),
    {}
  );

  const triggerCriterion =
    trigger.triggerCriterion &&
    `Audience is ${trigger.triggerCriterion.values
      .map(
        (value) =>
          audienceNameById[value] ??
          capitalizeWords(value.replace(/_/g, ' '), { ios: 'iOS' })
      )
      .join(', ')}`;
  const isEveryone = !criterion || everyone(criterion);
  const parsedCriterion = !isEveryone && criterion && parseCriterion(criterion);

  if (isLoading)
    return (
      <Flex>
        <LoadingSpinner />
      </Flex>
    );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: '4px 8px',
        alignItems: 'start',
      }}
    >
      <Text className={{ Semibold: true }}>Start Type:</Text>
      <Text className={{ Body: true }}>Daily</Text>

      {triggerCriterion && (
        <>
          <Text className={{ Semibold: true }}>Trigger:</Text>
          <Text className={{ Body: true }}>{triggerCriterion}</Text>
        </>
      )}

      <Text className={{ Semibold: true }}>Filter Members:</Text>
      {isEveryone ? (
        <Text className={{ Body: true }}>None, everyone is eligible</Text>
      ) : (
        parsedCriterion && (
          <Text className={{ Body: true }}>
            {parsedCriterion.count} {pluralize(parsedCriterion.count, 'filter')}{' '}
            applied
          </Text>
        )
      )}
      <Text className={{ Semibold: true }}>Journey Start Date:</Text>
      <RecurringNextRuns
        trigger={trigger}
        limit={1}
        dateFormat="MMMM d, yyyy h:mma"
      />

      <Text className={{ Semibold: true }}>Next Three Run Times:</Text>
      <RecurringNextRuns trigger={trigger} />
    </div>
  );
};
