import * as React from 'react';
import * as Blocks from 'models/publisher/block';
import { UpdateOpts, useFieldForm } from '../useFieldForm';
import { Attachment } from './Attachment';
import { BoxIntegration } from './BoxIntegration';
import { ButtonLink } from './ButtonLink';
import { DateTime } from './DateTime';
import { DynamicBlock } from './DynamicBlock';
import { Gallery } from './Gallery';
import { Iframe } from './Iframe';
import { Image } from './Image';
import { Links } from './Links/index';
import { Poll } from './Poll';
import { Social } from './Social';
import { Users } from './Users';
import { Video } from './Video';

// This is a type-safe wrapper
/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
export const Field: React.FC<FieldEditorProps> = ({
  data = {},
  default_data = {},
  ...props
}) => {
  if (props.field === 'attachment')
    return (
      <Attachment
        {...props}
        data={Blocks.toAttachmentFieldData(data)}
        default_data={Blocks.toAttachmentFieldData(data)}
      />
    );
  if (props.field === 'box-integration')
    return (
      <BoxIntegration
        {...props}
        data={Blocks.toBoxIntegrationFieldData(data)}
        default_data={Blocks.toBoxIntegrationFieldData(data)}
      />
    );
  if (props.field === 'date')
    return (
      <DateTime
        {...props}
        data={Blocks.toDateFieldData(data)}
        default_data={Blocks.toDateFieldData(default_data)}
      />
    );
  if (props.field === 'image')
    return (
      <Image
        {...props}
        data={Blocks.toImageFieldData(data)}
        default_data={Blocks.toImageFieldData(default_data)}
      />
    );
  if (props.field === 'image[]')
    return (
      <Gallery
        {...props}
        data={Blocks.toImagesFieldData(data)}
        default_data={Blocks.toImagesFieldData(default_data)}
      />
    );
  if (props.field === 'link[]')
    return (
      <Links
        {...props}
        data={Blocks.toLinksFieldData(data)}
        default_data={Blocks.toLinksFieldData(data)}
      />
    );
  if (props.field === 'button-link')
    return (
      <ButtonLink
        {...props}
        data={Blocks.toButtonLinkFieldData(data)}
        default_data={Blocks.toButtonLinkFieldData(default_data)}
      />
    );
  if (props.field === 'poll')
    return (
      <Poll
        {...props}
        data={Blocks.toPollFieldData(data)}
        default_data={Blocks.toPollFieldData(default_data)}
      />
    );
  if (props.field === 'social[]')
    return (
      <Social
        {...props}
        data={Blocks.toSocialsFieldData(data)}
        default_data={Blocks.toSocialsFieldData(data)}
      />
    );
  if (props.field === 'user[]')
    return (
      <Users
        {...props}
        data={Blocks.toUsersFieldData(data)}
        default_data={Blocks.toUsersFieldData(default_data)}
      />
    );
  if (props.field === 'video')
    return (
      <Video
        {...props}
        data={Blocks.toVideoFieldData(data)}
        default_data={Blocks.toVideoFieldData(default_data)}
      />
    );
  if (props.field === 'dynamic_block')
    return (
      <DynamicBlock
        {...props}
        data={Blocks.toDynamicBlockFieldData(data)}
        default_data={Blocks.toDynamicBlockFieldData(default_data)}
      />
    );
  if (props.field === 'html')
    return (
      <Iframe
        {...props}
        data={Blocks.toIframeFieldData(data)}
        default_data={Blocks.toIframeFieldData(default_data)}
      />
    );
  return null;
};

export type FieldEditorProps = {
  field: Blocks.FieldDefinition['type'];
  data?: Blocks.FieldData[string];
  default_data?: Blocks.FieldData[string];
  onChange: (data: Blocks.FieldType, opts?: UpdateOpts) => void;
  valid: boolean;
  errors: ReturnType<typeof useFieldForm>['errors'];
};
