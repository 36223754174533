import React from 'react';
import { Button } from 'DesignSystem/Form';
import { useFlashMessage } from 'contexts/flasher';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { FileUploadButton } from 'shared/FileUploadButton';
import { ImageUploader } from 'hooks/useImageUploader';
import styles from '../../profile-picture.module.css';

export type ImageUploadInputProps = {
  buttonLabel: string;
  onChange: (avatarUrl: string) => void;
  showRemoveButton: boolean;
  iconUploader: ImageUploader;
  setIsUploading: (isUploading: boolean) => void;
};

export const ImageUploadInput: React.FC<ImageUploadInputProps> = ({
  buttonLabel = 'Upload image',
  onChange,
  showRemoveButton,
  iconUploader,
  setIsUploading,
}) => {
  const [clearCounter, setClearCounter] = React.useState(1);
  const { setFlashMessage } = useFlashMessage();

  const handleFileSelect = React.useCallback(
    (newFile: File) => {
      if (newFile.size >= 10 * 1000 * 1024) {
        setFlashMessage({
          message: 'Only files up to 10MB in size are accepted',
          severity: 'error',
        });
        setClearCounter((counter) => counter + 1);
      } else {
        setIsUploading(true);
        iconUploader.update(newFile);
      }
    },
    [setIsUploading, setFlashMessage, setClearCounter, iconUploader]
  );

  const handleFileRemove = React.useCallback(() => {
    onChange('');
    iconUploader.remove();
  }, [onChange, iconUploader]);

  return (
    <div className={styles.ImageUploadActions}>
      <FileUploadButton
        accept="image/*"
        shouldClear={clearCounter}
        type="secondary"
        onFileSelect={handleFileSelect}
      >
        {buttonLabel}
      </FileUploadButton>
      {showRemoveButton && (
        <Button
          icon={<Icon name="Delete" size={20} />}
          borderless
          secondary
          className={styles.ImageUploadRemove}
          onClick={handleFileRemove}
        />
      )}
    </div>
  );
};
