import React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { Link } from '@reach/router';
import { useProgram } from 'contexts/program';
import { styles as Text } from 'DesignSystem/Typography';
import { ContentFiltersContext } from 'contexts/content/filters';
import { useReachQuery } from 'hooks/reach';
import { useContentsInfiniteQuery } from 'hooks/content';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Poll } from 'models/poll';
import { canViewResults, Content, isContent } from 'models/content';
import { FetchProps } from 'services/api-content';
import { Icon } from 'shared/Icon';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MetricsTooltip } from '../../ContentMetricsTooltip/MetricsTooltip';
import styles from './week-day.module.css';

function NumberOfDay({ date }: { date: DateTime }) {
  return <div className={Text.Heading}>{date.toFormat('dd')}</div>;
}

function DayOfWeek({ date }: { date: DateTime }) {
  return <div className={Text.Body}>{date.toFormat('EEEE')}</div>;
}

function DayHeader({ date }: { date: DateTime }) {
  return (
    <div className={styles.DayHeader}>
      <div className={styles.header}>
        <NumberOfDay date={date} />
        <DayOfWeek date={date} />
      </div>
    </div>
  );
}

const getDayLinkUrl = (
  content: Content | Poll,
  resultsSummaryEnabled?: boolean
): string => {
  return resultsSummaryEnabled && isContent(content) && canViewResults(content)
    ? `../../app/content/results/${content.id}`
    : `../../edit/publisher/${content.id}/review`;
};

type WeekCalendarProps = {
  date: DateTime;
  programId: number;
};

type DayComponentProps = {
  data: Array<Content | Poll>;
  date: DateTime;
  reach: Exclude<ReturnType<typeof useReachQuery>['data'], undefined>;
  isLoadingReach: boolean;
  defaultAvatar?: string;
  resultsSummaryEnabled?: boolean;
  totalRecords?: number;
  timeline: 'today' | 'past' | 'future';
};

const DayComponent: React.FC<DayComponentProps> = ({
  data,
  date,
  reach,
  defaultAvatar,
  isLoadingReach,
  timeline,
  resultsSummaryEnabled,
  totalRecords,
}) => {
  const { id: programId } = useProgram();

  const { data: reportVersion } = useFeatureFlagsQuery(
    programId,
    'Planner.Metrics.ContentReportVersion'
  );

  const displayViewers = (reportVersion?.value as string) === 'non_migrated';

  const getLabel = (
    isLoading: boolean,
    displayVersion: boolean,
    value: number
  ) => {
    if (isLoading) {
      return '...';
    }
    return displayVersion ? `${value} Viewers` : `${value} Reached Users`;
  };

  const formattedDate = date.toFormat('yyyy-MM-dd');
  return (
    <div
      className={cx(styles.column, {
        [styles.today]: timeline === 'today',
      })}
    >
      <DayHeader date={date} />
      <div style={{ paddingTop: '10px', flexGrow: 1 }}>
        {!data.length ? (
          <div className={styles.empty}>
            <Icon iconName="Content" iconType="SVG" />
            No campaigns <br />
            scheduled
          </div>
        ) : (
          <>
            {data.map((content: Content | Poll) => {
              const value = reach[content.id] ?? 0;
              const label = getLabel(isLoadingReach, displayViewers, value);

              const labelWidth = '40%';
              const reachIsReady = !isLoadingReach;
              const isEndOfWeek = date.weekday >= 5;
              const linkTo = getDayLinkUrl(content, resultsSummaryEnabled);
              return (
                <div
                  className={styles.item}
                  key={`week-content-${content.type}-${content.id}`}
                >
                  <Link to={linkTo}>
                    <div>
                      <div
                        className={styles.cover}
                        style={{
                          backgroundImage: `url(${
                            ((content as unknown) as Content).imageUrl ||
                            defaultAvatar
                          })`,
                        }}
                      />
                      <span className={styles.time}>
                        {content.publishedAt &&
                          DateTime.fromISO(content.publishedAt).toFormat('t')}
                      </span>
                    </div>
                    <div className={styles.title}>
                      {content.type === 'content_planner'
                        ? content.title || content.summary
                        : content.questionText}
                    </div>
                    <div className={styles.author}>
                      {content.contentAuthor?.displayName ||
                        content.contentAuthor?.defaultDisplayName ||
                        ''}
                    </div>
                    <div className={styles.spread}>
                      {content.type === 'content_planner' &&
                      content.publicationState === 'draft' ? (
                        <div className={styles.draft}>Draft</div>
                      ) : (
                        timeline !== 'future' && (
                          <div className={styles.reach}>{label}</div>
                        )
                      )}
                    </div>
                  </Link>
                  {content.type !== 'poll' &&
                    content.createdAt &&
                    content.publicationState !== 'draft' &&
                    content.publicationState !== 'scheduled' &&
                    reachIsReady && (
                      <MetricsTooltip
                        data={content}
                        startDate={DateTime.fromISO(content.createdAt)}
                        direction={isEndOfWeek ? 'right' : 'left'}
                      >
                        <Link
                          to={linkTo}
                          key={content.id}
                          className={styles.hotspot}
                          style={{ width: labelWidth }}
                        />
                      </MetricsTooltip>
                    )}
                </div>
              );
            })}
            {totalRecords && totalRecords > 1 && (
              <Link
                className={styles.seeAll}
                to={`../content/sent?publishDate=${formattedDate}`}
              >
                See all {totalRecords} campaigns
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const WeekDay: React.FC<
  WeekCalendarProps & { fetchProps: FetchProps }
> = ({ date, programId, fetchProps }) => {
  const start = date.startOf('day');
  const end = date.endOf('day');
  const newFetchProps = {
    ...fetchProps,
    publishedSince: start.toSeconds(),
    publishedBefore: end.toSeconds(),
    sortDirection: 'asc',
  } as FetchProps;

  const { filters } = React.useContext(ContentFiltersContext);
  const { isLoading, data, meta } = useContentsInfiniteQuery(
    newFetchProps,
    filters
  );

  const reach = useReachQuery({
    programId,
    ids: (data || [])
      .map((c) => {
        return c.data.map((d) => d.id);
      })
      .flat(),
  });

  const { iconImageUrl } = useProgram();
  const resultsSummaryEnabled = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.ResultsSummary'
  ).data?.value;

  const timeline: DayComponentProps['timeline'] = React.useMemo(() => {
    const now = DateTime.now();
    if (date.hasSame(now, 'day')) return 'today';
    if (date.diff(now, 'days').days < 0) return 'past';
    return 'future';
  }, [date]);

  return isLoading ? (
    <div className={styles.column}>
      <DayHeader date={date} />
      <div className={styles.spinner}>
        <LoadingSpinner />
      </div>
    </div>
  ) : (
    <DayComponent
      timeline={timeline}
      totalRecords={meta?.totalRecords}
      isLoadingReach={reach.isLoading}
      resultsSummaryEnabled={resultsSummaryEnabled}
      reach={reach.data || {}}
      data={data[0]?.data || []}
      date={date}
      defaultAvatar={iconImageUrl}
    />
  );
};
