import React from 'react';
import { StylesConfig } from 'react-select';
import cx from 'classnames';
import { ExpressionBuilder } from 'App/Program/Editors/AudienceBuilder/ExpressionBuilder';
import { useProgram } from 'contexts/program';
import { useStickyResponse } from 'hooks/common';
import { useAudienceUsersQuery } from 'hooks/audience';
import {
  Audience,
  audienceQuery as audienceQueryDetector,
  defaultAudience,
} from 'models/audience';
import { DynamicBlockVariantAudience } from 'models/dynamic_blocks/dynamic_block_variant';
import {
  emptyExpression,
  Expression,
  normalizeExpression,
  SimpleExpression,
  stripEnclosingParens,
  textToExpression,
} from 'models/expression';
import styles from './VariantModal.module.css';

const criterionCustomStyles: StylesConfig = {
  indicatorsContainer: (presetStyles, props) =>
    props.isDisabled
      ? {
          ...presetStyles,
          display: 'none',
        }
      : presetStyles,
};

const baseExpression: Readonly<Partial<SimpleExpression>> = Object.freeze({
  criterion: 'group',
});

export const VariantAudienceBuilder: React.FC<
  Required<DynamicBlockVariantAudience> & {
    onChange: (change: Required<DynamicBlockVariantAudience>) => void;
  }
> = ({ audienceQuery, totalUsers, onChange }) => {
  const { id: programId } = useProgram();
  const [audience, setAudience] = React.useState<Audience>({
    ...defaultAudience({ programId, query: audienceQuery }),
    expression: normalizeExpression(
      audienceQuery
        ? textToExpression({ query: stripEnclosingParens(audienceQuery) })
        : emptyExpression(baseExpression)
    ),
    totalUsers,
  });

  const [simpleExpressionsCount, setSimpleExpressionsCount] = React.useState<
    number
  >(getSimpleExpressionsCount(audience.expression));

  const {
    getExpression,
    setExpression,
    editableTextToQuery,
    queryToEditableText,
  } = audienceQueryDetector(audience, (a) => {
    setAudience(a);
    setSimpleExpressionsCount(getSimpleExpressionsCount(a.expression));
  });

  const { isLoading, data: usersCountData } = useStickyResponse(
    useAudienceUsersQuery(programId, audience.query ?? '')
  );

  React.useEffect(() => {
    if (!isLoading && usersCountData) {
      onChange({
        audienceQuery: queryToEditableText(audience.query ?? ''),
        totalUsers: usersCountData.totalObjects,
      });
    }
  }, [
    audience.query,
    isLoading,
    onChange,
    queryToEditableText,
    usersCountData,
  ]);

  return (
    <div
      className={cx(styles.variantAudienceBuilder, {
        [styles.multi]: simpleExpressionsCount > 1,
      })}
    >
      <ExpressionBuilder
        depth={0}
        onChange={setExpression}
        onDelete={() => {}}
        isAlone
        value={getExpression()}
        editableTextToQuery={editableTextToQuery}
        hideExpressionHeader
        criterionCustomStyles={criterionCustomStyles}
        isCriterionDisabled
        applyDisabledStyles
        baseExpression={baseExpression}
      />
    </div>
  );
};

const getSimpleExpressionsCount = (expression?: Expression): number => {
  switch (expression?.type) {
    case 'simple':
      return 1;
    case 'complex':
      return expression.terms.reduce(
        (sum, term) => sum + getSimpleExpressionsCount(term.expression),
        0
      );
    default:
      return 0;
  }
};
