type IntegrationDefinition = {
  label: string;
  featureFlag: string;
};

export enum SupportedIntegration {
  WORKDAY = 'workday',
  UKG_PRO = 'ukg-pro',
}

export const Integrations: Record<
  SupportedIntegration,
  IntegrationDefinition
> = {
  [SupportedIntegration.WORKDAY]: {
    label: 'Workday',
    featureFlag: 'License.Integration.MergeWorkday',
  },
  [SupportedIntegration.UKG_PRO]: {
    label: 'UKG Pro',
    featureFlag: 'License.Integration.MergeUkgPro',
  },
};

export const getIntegrationLabel = (type: SupportedIntegration): string =>
  Integrations[type].label;
