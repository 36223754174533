import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button, Icon } from '@socialchorus/shared-ui-components';
import { Box } from 'DesignSystem/Components';
import { KNOWLEDGE_BASE_URL } from 'utility/constants';
import { Blocks } from 'models/library';
import { defaultDesign } from 'contexts/design';
import { Design } from 'models/design';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import { makeBlockDefinitions } from 'contexts/publisher/compose/blocks';
import { useProgram } from 'contexts/program';
import styles from './editor.module.css';
import { BlockPicker } from '../../BlockPicker';

export const DefaultBlockVariant: React.FC<{
  variant: DynamicBlockVariant;
  updateVariantDesign: (design: Design) => void;
}> = ({ variant, updateVariantDesign }) => {
  const [insertingFromLibrary, setInsertingFromLibrary] = React.useState<
    boolean
  >(false);
  const { id: programId } = useProgram();

  const selectFromLibrary = React.useCallback(
    (animate: (after: () => void) => void) => (items: Blocks[]) => {
      animate(() => {
        const flattened = items.flatMap((block) => block.asset.blocks);
        makeBlockDefinitions(flattened, programId, (definitions) => {
          const newDesign = {
            ...defaultDesign,
            blocks: definitions,
            parentType: 'dynamic_block',
            parentSource: 'kai',
            parentId: variant.id?.toString(),
          };
          updateVariantDesign(newDesign);
        });
        setInsertingFromLibrary(false);
      });
    },
    [programId, updateVariantDesign, variant.id]
  );

  // omit "dynamic_block" from the library picker!!!
  const openBlockPicker = React.useCallback(() => {
    setInsertingFromLibrary(true);
  }, []);

  if (variant.design) return null;
  return (
    <Flex column center className={styles.defaultContentBlock}>
      {variant.default && (
        <>
          <Box className={styles.lineHeight}>
            <Text.Body bold>This is your default content block</Text.Body>
          </Box>
          <Box
            padding={[14, 0]}
            center
            width={442}
            className={styles.lineHeight}
          >
            <Text.Body>
              Adding content here displays as fallback for any non-targeted
              users viewing the campaign. Leaving this variant blank hides the
              block from any non-targeted users.
            </Text.Body>
          </Box>
        </>
      )}
      {!variant.default && (
        <>
          <Flex column className={styles.lineHeight}>
            <Text.Body bold>You have a blank dynamic block variant</Text.Body>
            <Text.Body bold>Start by adding some blocks</Text.Body>
          </Flex>
        </>
      )}

      <Box padding={[0, 0, 14, 0]}>
        <Button
          onClick={openBlockPicker}
          size="compact"
          variant="primary"
          label="Blocks"
          leftIcon={<Icon>add</Icon>}
        />
      </Box>
      <Flex column className={styles.checkOutTheKnowledgeBase}>
        <Text.Body block>Don&apos;t know where to start?</Text.Body>
        <Text.Body block>
          <a rel="noreferrer" target="_blank" href={KNOWLEDGE_BASE_URL}>
            Check out the Knowledge Base.
          </a>
        </Text.Body>
      </Flex>
      {insertingFromLibrary && (
        <BlockPicker
          useModal
          maxSelections={1}
          selectFromLibrary={selectFromLibrary}
          onCancel={() => setInsertingFromLibrary(false)}
        />
      )}
    </Flex>
  );
};
