import * as React from 'react';
import { Blocks } from 'models/library';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { BlocksPicker } from 'shared/Library/BlocksPicker';

export const BlockPicker: React.FC<{
  selectFromLibrary: (
    animate: (after: () => void) => void
  ) => (items: Blocks[]) => void;
  onCancel(): void;
  useModal?: boolean;
  maxSelections?: number;
}> = ({ useModal, onCancel, selectFromLibrary, maxSelections = 10 }) => {
  if (!useModal)
    return (
      <SlideIn name="block-editor-content-library-insertAbove">
        {(slideOut) => (
          <BlocksPicker
            onChange={selectFromLibrary(slideOut)}
            onCancel={() => slideOut(onCancel)}
            maxSelections={10}
          />
        )}
      </SlideIn>
    );
  return (
    <BlocksPicker
      useModal
      onChange={selectFromLibrary((done) => done())}
      onCancel={onCancel}
      maxSelections={maxSelections}
    />
  );
};
