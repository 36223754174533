import React, { useState } from 'react';
import { Comment } from 'services/api-content';
import { useCommentReplies } from 'hooks/content/comment-actions';
import { Button } from '@socialchorus/shared-ui-components';
import { CommentItem } from './CommentItem';
import styles from './comments-modal-v2.module.css';

type CommentProps = {
  comment: Comment;
  isFiltered: boolean;
  commentAttachmentsEnabled: boolean;
  nestedRepliesEnabled?: boolean;
};

export const Comments: React.FC<CommentProps> = ({
  comment,
  isFiltered,
  commentAttachmentsEnabled,
  nestedRepliesEnabled,
}) => {
  const { id, programId, contentId, replyCount, replyDeletedCount } = comment;
  const [showReplies, setShowReplies] = useState<boolean>(false);

  const { data: replies, hasNextPage, fetchNextPage } = useCommentReplies({
    programId,
    contentId,
    commentId: id,
    enabled: showReplies,
  });

  const toggleShowReplies = () => {
    setShowReplies((prev) => !prev);
  };

  const hasReplies = replyCount > 0 || replyDeletedCount > 0;
  const getRepliesLabel = () => {
    if (hasReplies) {
      if (showReplies) {
        return `Hide ${replies.length > 1 ? 'replies' : 'reply'}`;
      }

      const repliesCount = replyCount + replyDeletedCount;
      return `Show ${repliesCount} ${repliesCount > 1 ? 'replies' : 'reply'}`;
    }

    return '';
  };

  const showMoreRepliesLabel = hasNextPage ? 'Show more replies' : '';
  const repliesLabel = getRepliesLabel();

  return (
    <div>
      <CommentItem
        key={comment.id}
        comment={comment}
        isFiltered={isFiltered}
        commentAttachmentsEnabled={commentAttachmentsEnabled}
      />
      {!isFiltered && (
        <>
          {hasReplies && (
            <div className={styles.repliesLabel}>
              <Button
                variant="text"
                onClick={() => toggleShowReplies()}
                label={repliesLabel}
              />
            </div>
          )}
          {showReplies && (
            <div className={styles.repliesBox}>
              {replies.map((reply) => (
                <React.Fragment key={reply.id}>
                  {nestedRepliesEnabled &&
                  (reply.attributes.replyCount > 0 ||
                    reply.attributes.replyDeletedCount > 0) ? (
                    <Comments
                      comment={reply.attributes}
                      isFiltered={isFiltered}
                      commentAttachmentsEnabled={commentAttachmentsEnabled}
                      nestedRepliesEnabled={nestedRepliesEnabled}
                    />
                  ) : (
                    <CommentItem
                      comment={reply.attributes}
                      isFiltered={isFiltered}
                      commentAttachmentsEnabled={commentAttachmentsEnabled}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          {hasNextPage && (
            <div
              aria-hidden="true"
              onClick={(e) => {
                e.stopPropagation();
                fetchNextPage();
              }}
            >
              <div className={styles.repliesLabel}>{showMoreRepliesLabel}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
