import * as React from 'react';
import cx from 'classnames';
import { DefinitionBlock, FieldData, Targets } from 'models/publisher/block';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { DevReveal, PrettyJson } from 'DevMode';
import { ErrorBoundary } from 'components/ErrorBoundary';
import styles from './editor.module.css';
import { Static } from '../Static';
import { Renderer } from './Renderer';
import { useEditableInstance } from '../useEditableInstance';

type StaticProps = {
  block: DefinitionBlock;
  blockId: string;
  delivery?: Targets;
};

type EditableProps = StaticProps & {
  block: DefinitionBlock;
  onChangeData: (blockId: string, data: FieldData) => void;
  enableRender: boolean;
  enableLiveEdits: boolean;
  codeViewCallback: () => void;
  showCodeViewButton?: boolean;
  showVariantEditor: (() => void) | false;
};

const RuntimeRenderError: React.FC = () => (
  <div style={{ height: '300px', textAlign: 'center', paddingTop: '80px' }}>
    There was an error rendering this block.
    <br />
    This error has been reported and we are looking into a fix.
  </div>
);

const Editable: React.FC<EditableProps> = ({
  block,
  blockId,
  onChangeData,
  enableRender,
  codeViewCallback,
  showCodeViewButton,
  showVariantEditor,
}) => {
  const instance = useEditableInstance({
    block,
    blockId,
    isDragging: false,
    onChangeData,
    removeByBlockId: () => {},
  });
  return (
    <>
      <div id={blockId} className={cx(styles.instance)}>
        <DevReveal view={<PrettyJson value={block} />}>
          <ErrorBoundary ErrorComponent={RuntimeRenderError}>
            <Renderer
              block={block}
              onChange={instance.onChangeFieldData}
              blockId={blockId}
              enableRender={enableRender}
              enableLiveEdits={instance.enableLiveEdits}
              onFocus={instance.selectBlock}
              onDropzoneUploading={instance.onDropzoneUploading}
              codeViewCallback={codeViewCallback}
              showCodeViewButton={showCodeViewButton}
              showEditor={showVariantEditor}
              selectBlock={instance.selectBlock}
            />
          </ErrorBoundary>
        </DevReveal>
      </div>
    </>
  );
};

export const DynamicBlockVariantRow: React.FC<StaticProps | EditableProps> = (
  props
) => {
  const { canEdit } = React.useContext(DesignPermissionContext);

  if (canEdit) return <Editable {...(props as EditableProps)} />;
  return <Static {...props} />;
};
