import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { DynamicBlock } from 'models/dynamic_blocks/dynamic_block';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import { deepCamelcaseKeys, request } from './api-shared';
import { BASE_STYLING } from '../models/publisher/style';
import { resolveBlocks } from './api-content-blocks';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}/v2`;

export type DynamicBlockData = {
  data: {
    attributes: {
      id?: number;
      program_id: number;
      resource_id: number;
      resource_type: 'Content' | 'Design';
      uuid: string;
      status: string;
      dynamic_block_variants: DynamicBlockVariant[];
    };
  };
};

export type DynamicBlockMetadata = {
  data: {
    variants: DynamicBlockVariant[];
  };
};

async function mapServerDataToDynamicBlock(
  data: DynamicBlockData['data']['attributes']
): Promise<DynamicBlock> {
  const transformed: DynamicBlock = camelcaseKeys(data as never, {
    deep: true,
    stopPaths: ['dynamic_block_variants.design.blocks'],
  });

  const settledBlocksPromises = await Promise.allSettled(
    transformed.dynamicBlockVariants.map((variant) =>
      variant.design
        ? resolveBlocks(transformed.programId, variant.design.blocks || [])
        : undefined
    )
  );

  return {
    ...transformed,
    dynamicBlockVariants: transformed.dynamicBlockVariants.map(
      (variant: DynamicBlockVariant, index) => {
        const settledPromise = settledBlocksPromises[index];
        const resolvedBlocks =
          (settledPromise.status === 'fulfilled' && settledPromise.value) || [];

        return {
          ...variant,
          design: variant.design
            ? {
                ...variant.design,
                id: variant.design?.id || 'new',
                blocks: resolvedBlocks,
                styles: variant.design.styles ?? BASE_STYLING,
              }
            : undefined,
        };
      }
    ),
  };
}

function mapDynamicBlockToServerData(
  data: DynamicBlock
): DynamicBlockData['data']['attributes'] {
  return snakecaseKeys(data);
}

export const fetchById = async (
  programId: number,
  id: string
): Promise<DynamicBlock> => {
  if (!id || id === 'new') throw new Error('No ID provided');
  const url = `${apiRoot}/programs/${programId}/dynamic_blocks/${id}`;
  const response = await request(url);

  if (response.status === 200) {
    const json = await response.json();
    const { data } = json;
    return mapServerDataToDynamicBlock(data.attributes);
  }
  throw new Error(`Error fetching dynamic block: ${response.status}`);
};

export const upsertDynamicBlock = async (
  programId: number,
  dynamicBlock: Partial<DynamicBlock>
): Promise<DynamicBlock> => {
  const url = `${apiRoot}/programs/${programId}/dynamic_blocks${
    dynamicBlock.id ? `/${dynamicBlock.id}` : ''
  }`;

  const response = await request(url, {
    method: dynamicBlock.id ? 'PUT' : 'POST',
    body: JSON.stringify({
      data: {
        attributes: mapDynamicBlockToServerData(dynamicBlock as DynamicBlock),
      },
    }),
  });

  if (response.status === 200 || response.status === 201) {
    return response
      .json()
      .then((output) => mapServerDataToDynamicBlock(output.data.attributes));
  }

  throw new Error(`Error upserting dynamic block: ${response.status}`);
};

export async function destroy(id: number): Promise<void> {
  await request(`${apiRoot}/dynamic_blocks/${id}`, {
    method: 'DELETE',
  });
}

export async function fetchMetadata(
  program_id: number,
  uuid: string
): Promise<DynamicBlockMetadata> {
  const response = await request(
    `${apiRoot}/programs/${program_id}/dynamic_blocks/${uuid}/metadata`
  );
  return response
    .json()
    .then((output) => deepCamelcaseKeys(output.data.attributes));
}
