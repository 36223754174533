import React, { useState } from 'react';
import { editableTextToQueryType } from 'models/audience';

import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { capitalizeWords } from 'utility/strings';

import cx from 'classnames';
import { MenuPlacement } from 'react-select';
import { DisabledSelect } from '../Select';
import { DateSelect } from './DateSelect';
import { TextInput } from './TextInput';
import { StringCustomSelect } from './StringCustomSelect';
import {
  StringSuggestedSelect,
  AudienceTitleSelect,
} from './StringSuggestedSelect';
import { isDateCriterion } from '../CriterionSelect';
import styles from '../AudienceBuilder.module.css';

function isCustomDateCriterion(criterion: string): boolean {
  return criterion ? /^custom.*_timestamp$/.test(criterion) : false;
}

function isIgnoreYearValue(value: string[], criterion?: string): boolean {
  return (
    criterion === 'birthday' ||
    criterion === 'startday' ||
    value.some((val) => val.match(/:ignore_year/))
  );
}

export const ValueSelect: React.FC<{
  criterion?: string;
  criterionType?: string;
  operator?: string;
  onChange: (value: string[]) => void;
  value: string[];
  newDateOperatorsEnabled?: boolean;
  journeysEnabled?: boolean;
  isDisabled?: boolean;
  editableTextToQuery: editableTextToQueryType;
  audienceName?: string;
  hasError?: boolean;
  menuPlacement?: MenuPlacement;
  dobFormat?: string;
}> = ({
  criterion,
  criterionType,
  operator,
  onChange,
  value,
  newDateOperatorsEnabled,
  journeysEnabled,
  isDisabled,
  editableTextToQuery,
  audienceName,
  hasError,
  menuPlacement = 'auto',
  dobFormat,
}) => {
  const { id: programId } = useProgram();
  const { data } = useFeatureFlagsQuery(
    programId,
    'Studio.DatePicker.IgnoreYear'
  );
  const [ignoreYearFlag] = useState<boolean>(!!data?.value);
  const criterionName = criterion || '';

  let initialIgnoreYear = false;
  if (
    isDateCriterion(criterionName, criterionType) &&
    isIgnoreYearValue(value, criterionName)
  ) {
    initialIgnoreYear = true;
  }
  const [ignoreYear, setIgnoreYear] = useState<boolean>(initialIgnoreYear);

  const { data: showStandardRoles } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowStandardRoles'
  );

  const { data: showCustomRoles } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowCustomRoles'
  );

  const criterionToCapitalize = [
    'role',
    'classic_role',
    'roles',
    'experience_status',
    'provisioning_status',
  ];

  if (isDisabled) {
    const normalizedValues =
      criterion === 'group' || criterionToCapitalize.includes(criterion ?? '')
        ? value.map((val) =>
            capitalizeWords(val.replace(/_/g, ' '), { ios: 'iOS' })
          )
        : value;

    return (
      <DisabledSelect placeholder={normalizedValues.join(', ') || 'Value'} />
    );
  }

  const toggleIgnoreYear = () => {
    setIgnoreYear((tmp) => !tmp);
  };

  if (criterion && isCustomDateCriterion(criterion)) {
    return (
      <DateSelect
        operator={operator}
        criterion={criterionName}
        onChange={onChange}
        value={value}
        ignoreYearFlag={ignoreYearFlag}
        ignoreYear={ignoreYear}
        toggleIgnoreYear={toggleIgnoreYear}
      />
    );
  }

  if (
    criterionType === 'criteria/text' &&
    criterionName.startsWith('custom.')
  ) {
    return operator === 'contains' ? (
      <TextInput onChange={onChange} value={value} />
    ) : (
      <StringSuggestedSelect
        criterion={criterionName}
        onChange={onChange}
        value={value}
        editableTextToQuery={editableTextToQuery}
      />
    );
  }

  if (criterionName === 'group') {
    return (
      <AudienceTitleSelect
        onChange={onChange}
        value={value}
        capitalizeLabel={criterionToCapitalize.includes(criterionName)}
        editableTextToQuery={editableTextToQuery}
        audienceName={audienceName}
        menuPlacement={menuPlacement}
      />
    );
  }

  const roleFilterCallback = (optionValue: string): boolean => {
    const optionValueLower = optionValue.toLowerCase();
    const selectedValuesLower = value ? value.map((v) => v.toLowerCase()) : [];

    if (selectedValuesLower.includes(optionValueLower)) {
      return true;
    }

    const alwaysIncludeRoles = ['brand super admin', 'member'];
    const standardRoles = [
      'community admin',
      'content creator',
      'content publisher',
    ];

    if (alwaysIncludeRoles.includes(optionValueLower)) {
      return true;
    }

    if (showStandardRoles?.value) {
      if (standardRoles.includes(optionValueLower)) {
        return true;
      }
    }

    if (showCustomRoles?.value) {
      return !standardRoles.includes(optionValueLower);
    }

    return false;
  };

  if (
    criterionType === 'criteria/timestamp' &&
    criterionName.startsWith('custom.')
  ) {
    return (
      <DateSelect
        operator={operator}
        criterion={criterionName}
        onChange={onChange}
        value={value}
        ignoreYearFlag={ignoreYearFlag}
        ignoreYear={ignoreYear}
        toggleIgnoreYear={toggleIgnoreYear}
      />
    );
  }

  switch (criterionName) {
    case 'roles':
      return (
        <StringSuggestedSelect
          criterion={criterionName}
          onChange={onChange}
          value={value}
          capitalizeLabel
          editableTextToQuery={editableTextToQuery}
          filterCallback={roleFilterCallback}
          menuPlacement={menuPlacement}
        />
      );
    case 'business_unit':
    case 'cost_center':
    case 'country':
    case 'department':
    case 'division':
    case 'email_domain':
    case 'employee_id':
    case 'employee_type':
    case 'fid':
    case 'gender':
    case 'job_title':
    case 'locale':
    case 'locality':
    case 'manager':
    case 'name':
    case 'organization':
    case 'preferred_language':
    case 'region':
    case 'timezone':
    case 'username':
    case 'work_location':
    case 'email':
    case 'primary_email':
    case 'secondary_email':
    case 'mobile_app.id':
    case 'mobile_app.identifier':
    case 'mobile_app.name':
    case 'mobile_app.version':
    case 'mobile_app.platform':
    case 'topics.name':
    case 'preferred_channel':
    case 'usage_location':
    case 'role':
    case 'classic_role':
    case 'experience_status':
    case 'provisioning_status':
      return operator === 'contains' ? (
        <TextInput onChange={onChange} value={value} />
      ) : (
        <StringSuggestedSelect
          criterion={criterionName}
          onChange={onChange}
          value={value}
          capitalizeLabel={criterionToCapitalize.includes(criterionName)}
          editableTextToQuery={editableTextToQuery}
          menuPlacement={menuPlacement}
        />
      );
    case 'topics.followed':
    case 'shares.count.all_time':
    case 'shares.count.last_month':
    case 'shares.count.last_quarter':
    case 'shares.count.last_week':
    case 'shares.count.last_year':
    case 'shares.count.this_month':
    case 'shares.count.this_quarter':
    case 'shares.count.this_week':
    case 'shares.count.this_year':
      return <TextInput type="number" onChange={onChange} value={value} />;
    case 'verified_email':
    case 'reachable':
    case 'reachable_by_desktop':
    case 'reachable_by_email':
    case 'reachable_by_mobile_app':
    case 'reachable_by_push_notification':
      return (
        <StringCustomSelect
          onChange={onChange}
          value={value}
          options={[
            { value: 'true', label: 'True' },
            { value: 'false', label: 'False' },
          ]}
          menuPlacement={menuPlacement}
        />
      );
    case 'start_date':
    case 'startday':
    case 'birth_date':
    case 'birthday':
    case 'created_at':
    case 'activated_at':
    case 'blocked_at':
    case 'promotion_date':
    case 'requisition_approval_date':
      return (
        <DateSelect
          operator={operator}
          criterion={criterionName}
          onChange={onChange}
          value={value}
          ignoreYearFlag={ignoreYearFlag}
          ignoreYear={ignoreYear}
          toggleIgnoreYear={toggleIgnoreYear}
          newDateOperatorsEnabled={newDateOperatorsEnabled}
          journeysEnabled={journeysEnabled}
          dobFormat={dobFormat}
        />
      );

    default:
      return (
        <DisabledSelect
          className={cx({ [styles.genericError]: hasError })}
          placeholder={value.join(', ') || 'Value'}
        />
      );
  }
};
