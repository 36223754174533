import { useCallback } from 'react';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { LitmusRole } from 'models/role';

type UsePermissionFiltersReturnType = {
  filterPermissions: (roles: LitmusRole[]) => LitmusRole[];
  filterAliases: (aliases: { name: string }[]) => { name: string }[];
};

/**
 * Shared logic to filter permissions based on feature flags
 * @param programId
 */
export function usePermissionFilters(
  programId: number
): UsePermissionFiltersReturnType {
  const { data: fontsPageEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Library.FontsPage'
  );
  const { data: showActivitiesUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Activities.UI'
  );

  const { data: showPersonalizedFieldsToggle } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFields'
  );

  const { data: showAttributeManagementToggle } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFieldsV2'
  );

  const { data: showTopicLimitToggle } = useFeatureFlagsQuery(
    programId,
    'EE.UserGeneratedContent.TopicLimitsEnabled'
  );

  const { data: handleFeaturedPermission } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Featured'
  );

  const { data: handleLinkCustomSlugPermission } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.CustomURLsEnabled'
  );

  const { data: configureAuthorAliases } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.AuthorAliases'
  );

  const { data: setChannelDefaultsEnabled } = useFeatureFlagsQuery(
    programId,
    'SetChannelDefaults.Enabled'
  );

  const { data: engagementBoostDefaultEnabled } = useFeatureFlagsQuery(
    programId,
    'Orchestrate.EngagementBoostDefault.Enabled'
  );

  const { data: deliveryThresholdEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.DeliveryThresholdEnabled'
  );

  const { data: userTagging } = useFeatureFlagsQuery(
    programId,
    'EE.TagUser.Enabled'
  );

  const { data: designsEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.Designs'
  );

  const { data: boxKMEnabled } = useFeatureFlagsQuery(
    programId,
    'License.Integration.BoxKnowledgeManagement'
  );

  const { data: directDataDownloadEnabledForProgram } = useFeatureFlagsQuery(
    programId,
    'Studio.Intelligence.DirectDataDownload.Enabled'
  );

  const { data: journeyInsightsReportEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Intelligence.JourneyInsightsReport.Enabled'
  );
  const { data: journeyInsightsCategoryName } = useFeatureFlagsQuery(
    programId,
    'Studio.Intelligence.JourneyInsightsReport.CategoryName'
  );

  const directDataDownloadEnabled = !!directDataDownloadEnabledForProgram?.value;

  const campaignSettingsEnabled =
    setChannelDefaultsEnabled?.value ||
    engagementBoostDefaultEnabled?.value ||
    deliveryThresholdEnabled?.value;

  // Certain aliases should not be duplicated if the feature flag is not enabled
  const filterAliases = useCallback(
    (aliases: { name: string }[]) => {
      return aliases.filter((alias) => {
        if (
          !boxKMEnabled?.value &&
          [
            'configure_box_knowledge_management',
            'global_box_access',
            'configure_box_knowledge_management_credentials',
          ].includes(alias.name)
        ) {
          return false;
        }

        return true;
      });
    },
    [boxKMEnabled?.value]
  );

  const filterPermissions = useCallback(
    (roles: LitmusRole[]) => {
      return roles.filter((p) => {
        if (
          p.title === journeyInsightsCategoryName?.value &&
          !journeyInsightsReportEnabled?.value
        ) {
          return false;
        }

        if (!fontsPageEnabled?.value && p.name === 'library_fonts')
          return false;

        if (!designsEnabled?.value && p.name === 'manage_designs') return false;

        if (
          !showActivitiesUI?.value &&
          p.name === 'configure_admin_activity_feed'
        )
          return false;

        if (
          !showPersonalizedFieldsToggle?.value &&
          p.name === 'set_personalized_fields'
        )
          return false;

        if (
          !showAttributeManagementToggle?.value &&
          p.name === 'configure_attribute_management'
        )
          return false;

        if (!showTopicLimitToggle?.value && p.name === 'configure_topic_limit')
          return false;

        if (!configureAuthorAliases?.value && p.name === 'manage_aliases')
          return false;

        if (!handleFeaturedPermission?.value && p.name === 'set_featured')
          return false;

        if (
          !handleLinkCustomSlugPermission?.value &&
          p.name === 'link_custom_slug'
        )
          return false;

        if (
          !campaignSettingsEnabled &&
          p.name === 'configure_campaign_settings'
        )
          return false;

        if (p.name === 'journeys') return false;

        if (
          !userTagging?.value &&
          p.name === 'configure_experience_user_search'
        )
          return false;

        if (
          !boxKMEnabled?.value &&
          p.name === 'configure_box_knowledge_management'
        )
          return false;

        if (p.name === 'download_insights_data' && !directDataDownloadEnabled)
          return false;

        return true;
      });
    },
    [
      boxKMEnabled?.value,
      campaignSettingsEnabled,
      configureAuthorAliases?.value,
      designsEnabled?.value,
      directDataDownloadEnabled,
      fontsPageEnabled?.value,
      handleFeaturedPermission?.value,
      handleLinkCustomSlugPermission?.value,
      journeyInsightsCategoryName?.value,
      journeyInsightsReportEnabled,
      showActivitiesUI?.value,
      showAttributeManagementToggle?.value,
      showPersonalizedFieldsToggle?.value,
      showTopicLimitToggle?.value,
      userTagging?.value,
    ]
  );

  return { filterAliases, filterPermissions };
}
