import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { AuthorAliasBulkActionFilters, BulkSelection } from 'hooks/common';
import qs from 'qs';
import { AuthorAlias } from 'models/author-alias';
import { Page, PaginationData } from './common';
import {
  bossanovaDomain,
  request,
  deepCamelcaseKeys,
  prepareQueryString,
} from './api-shared';

export type FetchProps = {
  page?: number;
  pageSize?: number;
  query?: string;
  programId: number;
  search?: string;
  types?: string[];
  userIds?: string[];
  statuses?: string[];
};

export type AliasData = AuthorAlias & {
  creator?: {
    firstName: string;
    lastName: string;
  };
};

export type AuthorAliasBulkAffected = {
  affectedCount: number;
};

export type NewAliasData = {
  avatarUrlUpdated?: boolean;
  avatarUrl?: string;
  displayName?: string;
  userId?: string;
  aliasType?: string;
  createdBy?: number;
  description?: string;
};

export type AliasCollectionData = {
  data: Array<AliasData>;
  meta: PaginationData;
  type?: string;
};

export type BulkUploadJobStates = {
  administrator: number;
  analyst: number;
  members: number;
  publisher: number;
  programManager: number;
  topicContributer: number;
  duplicate: number;
  imported: number;
  totalItems: number;
  lineErrors: number;
};

export async function fetchById(
  id: number,
  programId: number
): Promise<AliasData> {
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/${id}`;
  const response = await request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    const result: { data: AliasData } = camelcaseKeys(await response.json(), {
      deep: true,
    });
    return result?.data;
  }
  throw new Error(`Error fetching author aliases: ${response.status}`);
}

export async function fetchByIds(
  ids: Array<number>,
  programId: number
): Promise<AliasCollectionData> {
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/bulk`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      alias_ids: ids,
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    const result = camelcaseKeys(await response.json(), { deep: true });

    if (result.meta) {
      result.meta.currentPage = result.meta.page;
    }

    return result;
  }
  throw new Error(`Error fetching author aliases: ${response.status}`);
}

export const fetchAuthorAliases = async (
  programId: number,
  pageNumber?: number,
  pageSize?: number,
  type?: string | null,
  search?: string | undefined
): Promise<AliasCollectionData> => {
  const blocked = false;
  const query = qs.stringify(
    snakecaseKeys({
      pageNumber,
      search,
      pageSize,
      type,
      blocked,
    }),
    { arrayFormat: 'brackets', encodeValuesOnly: true }
  );

  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/all?${query}`;
  const response = await request(url);
  if (response.status === 200) {
    const result = camelcaseKeys(await response.json(), { deep: true });

    if (result.meta) {
      result.meta.currentPage = result.meta.page;
    }

    return result;
  }
  throw new Error(`Error fetching author aliases: ${response.status}`);
};

export async function fetchAuthorAliasesPage({
  programId,
  ...queryParams
}: FetchProps): Promise<Page<AliasData>> {
  const queryString = prepareQueryString({ ...queryParams });
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/author_aliases?${queryString}`
  );
  if (response.status === 200) {
    return response
      .json()
      .then((body) => deepCamelcaseKeys(body))
      .then(({ data, meta, ...rest }) => {
        return {
          data,
          meta: {
            currentPage: meta.pageNumber,
            totalRecords: meta.totalObjects,
            ...meta,
          },
          ...rest,
        };
      });
  }

  throw new Error(`Error fetching authors: ${response.status}`);
}

export const bulkUnarchiveAuthorAliases = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: AuthorAliasBulkActionFilters
): Promise<AliasData> => {
  const { search, statuses, types, userIds } = filterConfig;
  const query = qs.stringify(
    snakecaseKeys({
      search,
      statuses,
      types,
      userIds,
    }),
    { arrayFormat: 'brackets' }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/unarchive/bulk?${query}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error bulk unarchive author alias: ${response.status}`);
};

export const bulkArchiveAuthorAliases = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: AuthorAliasBulkActionFilters
): Promise<AliasData> => {
  const { search, statuses, types, userIds } = filterConfig;
  const query = qs.stringify(
    snakecaseKeys({
      search,
      statuses,
      types,
      userIds,
    }),
    { arrayFormat: 'brackets' }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/archive/bulk?${query}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error bulk unarchive author alias: ${response.status}`);
};

export type BulkAffectedAuthorAliasesOptions = {
  bulkSelection: BulkSelection;
  filterConfig: AuthorAliasBulkActionFilters;
  performedAction: 'archive' | 'unarchive';
};

export const bulkAffectedAuthorAliases = (programId: number) => async ({
  bulkSelection,
  filterConfig,
  performedAction,
}: BulkAffectedAuthorAliasesOptions): Promise<AuthorAliasBulkAffected> => {
  const query = qs.stringify(
    snakecaseKeys({ ...filterConfig, performedAction }),
    { arrayFormat: 'brackets' }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/bulk/affected?${query}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error bulk unarchive author alias: ${response.status}`);
};

export const createAuthorAlias = async (
  programId: number,
  data: NewAliasData
): Promise<AliasData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 201) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error creating author aliases: ${response.status}`);
};

export const createAuthorAliasOnly = async (
  programId: number,
  data: NewAliasData
): Promise<AliasData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/create_only`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 201) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error creating author aliases: ${response.status}`);
};

export const archiveAuthorAlias = async (
  programId: number,
  aliasId: number
): Promise<AliasData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/${aliasId}/archive`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error archive author alias: ${response.status}`);
};

export const unarchiveAuthorAlias = async (
  programId: number,
  aliasId: number
): Promise<AliasData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/${aliasId}/unarchive`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error unarchive author alias: ${response.status}`);
};

export const updateAuthorAlias = async (
  programId: number,
  data: AliasData
): Promise<AliasData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/author_aliases/${data.id}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error updating author aliases: ${response.status}`);
};
