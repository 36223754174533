import React from 'react';
import {
  LocationProvider,
  Redirect,
  RouteComponentProps,
  Router,
} from '@reach/router';
import { Helmet } from 'react-helmet';
import { FlashMessageProvider } from 'contexts/flasher';
import { HistoryProvider } from 'contexts/history';
import { ConfirmationProvider } from 'contexts/confirmation';
import { ModalProvider } from 'contexts/modal';
import { DevModeProvider } from 'DevMode';
import { NumberFormatProvider } from 'shared/NumericalLabel/NumberFormatProvider';
import { Program } from './Program';
import { Callback } from './Authentication/Callback';
import { Logout } from './Authentication/Logout';
import { ProgramRedirect } from './Authentication/ProgramRedirect';
import { Relay } from './Authentication/Relay';
import { COMPANY_NAME } from '../utility/constants';
import { JourneysPermalinkPreview, PermalinkPreview } from './PermalinkPreview';
import { FeedbackProvider } from './Program/Main/Feedback/FeedbackProvider';

/**
   # Root Application

   ## Structure
     - The application is composed of nested routers.
     - The routers are often wrapped by some logic, or context.
     - These wrapped-routers are React components that are exported
       from sub-directories of this folder, `src/App/.../index.tsx`.

   ## Program: `/:program-id/*`
   The Program will verify access to the program id in the URL.

   ## Authorization: `/auth/*`
   The Authorization will forward the user to the program if it can.
 */

const DefaultRoute: React.FC<RouteComponentProps> = () => <ProgramRedirect />;

export const App: React.FC = () => (
  <>
    <Helmet titleTemplate="Studio - %s">
      <title>{COMPANY_NAME}</title>
    </Helmet>
    <FeedbackProvider>
      <NumberFormatProvider numberFormat={Intl.NumberFormat()}>
        <LocationProvider>
          <HistoryProvider>
            <ConfirmationProvider>
              <FlashMessageProvider>
                <ModalProvider>
                  <DevModeProvider code="firstup devmode">
                    <Router>
                      <Redirect
                        noThrow
                        from=":programId/edit/publisher/:id/orchestrate/setup"
                        to="/:programId/edit/publisher/:id/deliver/setup"
                      />
                      <Redirect
                        noThrow
                        from=":programId/edit/publisher/:id/orchestrate/rules"
                        to="/:programId/edit/publisher/:id/deliver/setup"
                      />
                      <Redirect
                        noThrow
                        from=":programId/edit/publisher/:id/orchestrate/features"
                        to="/:programId/edit/publisher/:id/deliver/setup"
                      />
                      <Callback path="auth/callback" />
                      <Logout path="auth/logout" />
                      <Relay path="auth/relay" />
                      <PermalinkPreview path=":programId/edit/publisher/:contentId/link-preview" />
                      <JourneysPermalinkPreview path=":programId/app/journeys/:journeyId/link-preview" />
                      <Program path=":programId/*" />
                      <DefaultRoute default />
                    </Router>
                  </DevModeProvider>
                </ModalProvider>
              </FlashMessageProvider>
            </ConfirmationProvider>
          </HistoryProvider>
        </LocationProvider>
      </NumberFormatProvider>
    </FeedbackProvider>
  </>
);
