import camelcaseKeys from 'camelcase-keys';
import {
  JourneyExecutionMetrics,
  StepMetrics,
  Steps,
} from 'models/journeys/journey';
import { prepareQueryString, request } from './api-shared';
import { NotFoundError } from './Errors/NotFoundError';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

type ServerJourneyExecutionMetrics = JourneyExecutionMetrics & {
  stepMetrics: [StepMetrics<keyof Steps>];
};

export const fetchJourneyMetrics = async (props: {
  programId: number;
  journeyId: number;
  graphId: number;
}): Promise<JourneyExecutionMetrics | undefined> => {
  const { programId, journeyId, graphId } = props;
  try {
    const response = await request(
      `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/graphs/${graphId}/metrics`
    );

    if (response.status === 200) {
      const serverJourneyExecutionMetrics = await response
        .json()
        .then(({ data }) => camelcaseKeys(data, { deep: true }));

      return serverJourneyExecutionMetrics
        ? deserializeJourneyMetrics(serverJourneyExecutionMetrics)
        : undefined;
    }
    throw new Error('Server error');
  } catch (e) {
    if (e instanceof NotFoundError) {
      throw new Error(
        "Journey doesn't exist or the user does not have access to it."
      );
    }

    throw e;
  }
};

const deserializeJourneyMetrics = (
  data: ServerJourneyExecutionMetrics
): JourneyExecutionMetrics => {
  return {
    ...data,
    stepMetrics: data.stepMetrics.reduce(
      (acc: Record<string, StepMetrics<keyof Steps>>, sm) => {
        acc[sm.stepId] = sm;
        return acc;
      },
      {}
    ),
  };
};

export type JourneyInsightsMetricsData = {
  journeyIdGraphIdExecutionIdStepid: string;
  uniqueCommunicationsSent: number;
  communicationsSent: number;
  uniqueCommunicationsDelivered: number;
  communicationsDelivered: number;
  uniqueEmailOpened: number;
  emailOpened: number;
  emailDelivered: number;
  uniqueEmailLinkClicked: number;
  emailLinkClicked: number;
  emailDropped: number;
  emailSent: number;
  notificationCenterMessageDelivered: number;
  notificationCenterMessageOpened: number;
  notificationCenterMessageDropped: number;
  notificationCenterMessageSent: number;
  mobilePushDelivered: number;
  mobilePushOpened: number;
  mobilePushSent: number;
  mobilePushDropped: number;
  updatedAt: string;
};
// Fetch journey metrics from deejay
export const fetchJourneyInsightsMetrics = async (props: {
  programId: number;
  journeyId: string | number;
  activationId: string | number;
  stepId: string | number;
}): Promise<{ data: JourneyInsightsMetricsData }> => {
  const { programId, journeyId, activationId, stepId } = props;
  const url = `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/activation/${activationId}/step/${stepId}/metrics`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(
    `Error fetching journey insights metrics: ${response.status}`
  );
};
export type JourneyStepsInsightsMetric = {
  stepId: number;
  uniqueUsersEntered?: number;
  uniqueUsersExited?: number;
  uniqueUsersCurrent?: number;
  uniqueUsersSent?: number;
  uniqueUsersDelivered?: number;
  uniqueUsersError?: number;
  uniqueUsersInteracted?: number;
  uniqueUsersOpened?: number;
};

export type JourneyStepsInsightsMetricsData = Array<JourneyStepsInsightsMetric>;
// Fetch journey steps metrics
export const fetchJourneyStepsInsightsMetrics = async (props: {
  programId: number;
  journeyId: string | number;
  activationId: string | number;
  query: { [key: string]: unknown };
}): Promise<{ data: JourneyStepsInsightsMetricsData }> => {
  const { programId, journeyId, activationId, query } = props;
  const url = `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/activation/${activationId}/steps_insights_metrics`;
  const urlQuery = prepareQueryString(query);

  const response = await request(`${url}?${urlQuery}`);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(
    `Error fetching journey insights metrics: ${response.status}`
  );
};

export type UserJourneyActivity = {
  userId: string;
  userName: string;
  emailAddress?: string;
  userEmailDelivered?: string;
  userEmailBounced?: string;
  userEmailUnsubscribed?: string;
  userEmailOpened?: string;
  userEmailClicked?: string;
  userEmailSkipped?: string;
  userNotificationDelivered?: string;
  userNotificationOpened?: string;
  userNotificationClicked?: string;
  userNotificationSkipped?: string;
  userPushDelivered?: string;
  userPushOpened?: string;
  userPushSkipped?: string;
  userAcknowledgedDate?: string;
  pollRespondedDate?: string;
};

type JourneyStepsUserActivityData = Array<UserJourneyActivity>;
// Fetch journey steps metrics
export const fetchJourneyStepUserActivity = async (props: {
  programId: number;
  journeyId: string | number;
  activationId: string | number;
  stepId: string | number;
  query: { [key: string]: unknown };
}): Promise<{
  data: JourneyStepsUserActivityData;
  meta: { totalRecords: number };
}> => {
  const { programId, journeyId, activationId, stepId, query } = props;
  const url = `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/activation/${activationId}/steps/${stepId}/user_activity`;
  const urlQuery = prepareQueryString(query);

  const response = await request(`${url}?${urlQuery}`);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(
    `Error fetching journey insights metrics: ${response.status}`
  );
};
