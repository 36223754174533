import * as React from 'react';
import { RenderError } from 'models/publisher/block';
import styles from '../dnd.module.css';

export const ErrorsOverlay: React.FC<{ errors: RenderError[] }> = ({
  errors,
}) => (
  <div className={styles.errorsContain}>
    {errors.map((error) => (
      <div key={JSON.stringify(error)}>
        <span>Error in {error.field_label}:</span>{' '}
        <strong>{error.message}</strong>
      </div>
    ))}
  </div>
);
