import * as React from 'react';
import { DynamicBlockFieldData } from 'models/donkey';
import { usePublisher } from 'contexts/publisher';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { v4 as uuidv4 } from 'uuid';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import { FieldFormProps } from '../../../useFieldForm';

const MAX_VARIANTS = 10;
const DEFAULT_NAME = 'Untitled Variant';

export type UseDynamicBlockFieldSettings = {
  dynamicBlockVariants: DynamicBlockVariant[];
  addVariant: () => void;
  deleteVariant: (uuid?: string) => void;
  swapOrder: (sourceId: string, targetId: string) => void;
  updateVariant: (updatedVariant: DynamicBlockVariant) => void;
};

export const useDynamicBlockFieldSettings: (
  fieldData: FieldFormProps<DynamicBlockFieldData>['data']
) => UseDynamicBlockFieldSettings = (fieldData) => {
  const { id: programId } = useProgram();
  const publisher = usePublisher();
  const { setFlashMessage } = useFlashMessage();

  const { update } = publisher.dynamicBlocks;

  const currentDynamicBlock = React.useMemo(() => {
    return publisher.dynamicBlocks.dynamicBlocks[fieldData.uuid];
  }, [fieldData.uuid, publisher.dynamicBlocks.dynamicBlocks]);

  const dynamicBlockVariants = React.useMemo(() => {
    return currentDynamicBlock.dynamicBlockVariants;
  }, [currentDynamicBlock.dynamicBlockVariants]);

  const getNewVariantName = () => {
    const existingUntitled = dynamicBlockVariants.filter((variant) =>
      variant.name.startsWith(DEFAULT_NAME)
    );

    return existingUntitled.length > 0
      ? `${DEFAULT_NAME} (${existingUntitled.length})`
      : DEFAULT_NAME;
  };

  const addVariant = () => {
    if (dynamicBlockVariants.length >= MAX_VARIANTS) {
      setFlashMessage({
        severity: 'error',
        message: `You can only have ${MAX_VARIANTS} variants`,
      });

      return;
    }

    const newVariant = {
      default: false,
      dynamicBlockId: currentDynamicBlock.id,
      name: getNewVariantName(),
      programId,
      order:
        Math.max(...dynamicBlockVariants.map((variant) => variant.order)) + 1,
      uuid: uuidv4(),
    } as DynamicBlockVariant;

    const orderedDynamicBlockVariants: DynamicBlockVariant[] = [
      ...dynamicBlockVariants,
      newVariant,
    ].sort((a, b) => a.order - b.order);

    update(currentDynamicBlock.uuid, {
      dynamicBlockVariants: orderedDynamicBlockVariants,
    });
  };

  const deleteVariant = (uuid?: string) => {
    if (!uuid) return;

    const i = dynamicBlockVariants.findIndex(
      (variant) => variant.uuid === uuid
    );

    if (i < 0) return;

    const { order } = dynamicBlockVariants[i];

    const updatedDynamicBlockVariants = [...dynamicBlockVariants];
    updatedDynamicBlockVariants.splice(i, 1);

    updatedDynamicBlockVariants.forEach((variant) => {
      if (variant.order > order) {
        Object.assign(variant, { order: variant.order - 1 });
      }
    });

    update(currentDynamicBlock.uuid, {
      dynamicBlockVariants: updatedDynamicBlockVariants,
    });
  };

  const swapOrder = (sourceId: string, targetId: string) => {
    const sourceOrder = dynamicBlockVariants.find(
      (variant) => variant.uuid === sourceId
    )?.order;

    const targetOrder = dynamicBlockVariants.find(
      (variant) => variant.uuid === targetId
    )?.order;

    if (!sourceOrder || !targetOrder) return;

    dynamicBlockVariants.forEach((variant) => {
      if (variant.uuid === sourceId) {
        Object.assign(variant, { order: targetOrder });
      } else if (variant.uuid === targetId) {
        Object.assign(variant, { order: sourceOrder });
      }

      return variant;
    });

    update(currentDynamicBlock.uuid, {
      dynamicBlockVariants,
    });
  };

  const updateVariant = (updatedVariant: DynamicBlockVariant) => {
    update(currentDynamicBlock.uuid, {
      dynamicBlockVariants: dynamicBlockVariants.map((variant) =>
        updatedVariant.uuid === variant.uuid
          ? {
              ...variant,
              ...updatedVariant,
            }
          : variant
      ),
    });
  };

  return {
    dynamicBlockVariants,
    addVariant,
    deleteVariant,
    swapOrder,
    updateVariant,
  };
};
