import React from 'react';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { DynamicBlockFieldData } from 'models/donkey';
import { Button, Icon } from '@socialchorus/shared-ui-components';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { FieldFormProps } from '../../useFieldForm';
import { VariantItem } from './components/VariantItem';
import styles from './DynamicBlock.module.css';
import { useDynamicBlockFieldSettings } from './hooks/useDynamicBlockFieldSettings';

export const DynamicBlock: React.FC<FieldFormProps<DynamicBlockFieldData>> = (
  props
) => {
  const { data: fieldData } = props;

  const {
    dynamicBlockVariants,
    addVariant,
    deleteVariant,
    swapOrder,
    updateVariant,
  } = useDynamicBlockFieldSettings(fieldData);

  const currentVariants = dynamicBlockVariants.filter(
    (variant) => !variant.default
  );

  const defaultVariant = dynamicBlockVariants.find(
    (variant) => variant.default
  );

  const [
    selectedVariant,
    setSelectedVariant,
  ] = React.useState<DynamicBlockVariant | null>();

  return (
    <div id="publisher-selected-sidebar">
      <Flex spread>
        <FlexItem className="text-caption" start>
          Priority
        </FlexItem>
        <FlexItem start>
          <Icon className={styles.iconPadding}>info</Icon>
        </FlexItem>
        <FlexItem end widen>
          <Button
            label="Add Variant"
            onClick={() => {
              addVariant();
            }}
            variant="outline"
            size="compact"
            rightIcon={<Icon>add</Icon>}
          />
        </FlexItem>
      </Flex>

      <DndProvider backend={HTML5Backend}>
        <Flex column>
          {currentVariants?.map((variant, i) => (
            <Flex className={styles.variantRow}>
              <FlexItem start>
                <div className={styles.variantBadge}>{i + 1}</div>
              </FlexItem>
              <FlexItem end widen>
                <VariantItem
                  key={variant.uuid}
                  variant={variant}
                  selected={selectedVariant?.uuid === variant.uuid}
                  onSelect={(selection) => setSelectedVariant(selection)}
                  onDelete={(removeVariant) =>
                    deleteVariant(removeVariant?.uuid)
                  }
                  onDrop={(sourceId, targetId) => swapOrder(sourceId, targetId)}
                  onUpdate={updateVariant}
                />
              </FlexItem>
            </Flex>
          ))}
          <Flex className={styles.variantRow}>
            <FlexItem start>
              <div className={styles.variantBadge}>-</div>
            </FlexItem>
            <FlexItem end widen>
              <VariantItem
                variant={defaultVariant}
                description="For non-targeted viewers"
              />
            </FlexItem>
          </Flex>
        </Flex>
      </DndProvider>
    </div>
  );
};
