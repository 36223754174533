import React from 'react';
import { createPortal } from 'react-dom';
import { useReactFlow } from 'reactflow';
import {
  ContentFiltersProvider,
  defaultState as defaultContentFiltersState,
} from 'contexts/content/filters';
import { Box, ConfirmModal } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { Body, color, Heading } from 'DesignSystem/Typography';
import { useJourneyState } from 'contexts/journeys/journey';
import { usePortal } from 'hooks/usePortal';
import {
  CHANNEL_NAMES,
  CommunicationStep,
  defaultEmailChannel,
  defaultNotificationCenterChannel,
  isEmailChannel,
  isNotifitcationCenterChannel,
} from 'models/journeys/journey';
import { MAIcon } from 'shared/MAIcon';
import { Button } from '@socialchorus/shared-ui-components';
import { defaultDesign, useDesign } from 'contexts/design';
import { asserts } from 'utility/asserts';
import { CloseButton } from '../JourneyCanvasHeader/Buttons';
import { DrawerState } from '../JourneyDrawer/drawer';
import { ContentListState, Drawer } from './Drawer';
import { ContentListTabs, Tab } from './ContentListTabs';
import { JourneyContentList } from './JourneyContentList';
import styles from './journey-content-list-drawer.module.css';
import { centerY } from '../JourneyCanvas/utils/node';
import {
  JourneyErrorKey,
  useJourneyValidationErrors,
} from '../JourneyErrors/useJourneyValidationErrors';
import { useJourneyContentDesigner } from '../JourneyContentDesigner/JourneyContentDesignProvider';
import { useNotificationCenterEnabled } from '../../../../../hooks/notification-center';
import { useDefaultEmailAddress } from '../../../../../hooks/email-alias';

const CAMPAIGNS_TAB: Tab = {
  label: 'Campaigns',
  id: 'content',
};

const TEMPLATES_TAB: Tab = {
  label: 'Templates',
  id: 'template',
};

const TABS = [CAMPAIGNS_TAB, TEMPLATES_TAB];

const defaultCommunicationChannels = [
  defaultEmailChannel(),
  defaultNotificationCenterChannel(),
];

export const JourneyContentListDrawer: React.FC = () => {
  const portal = usePortal('journey-content-list-drawer');
  const flowInstance = useReactFlow();

  const {
    activeStep,
    contentListState,
    drawerState,
    setContentListState,
    setDrawerState,
    updateStep,
    setActiveStepId,
  } = useJourneyState();
  const { resolveErrors } = useJourneyValidationErrors();
  const { update } = useDesign();
  const { setDesignerId } = useJourneyContentDesigner();
  const {
    createDesign,
    inlineEditingEnabled,
    setIsTemplateModalOpen,
  } = useJourneyContentDesigner();
  const notificationCenterEnabled = useNotificationCenterEnabled();
  const { data: defaultEmailAddress } = useDefaultEmailAddress();

  const [activeTabId, setActiveTabId] = React.useState<Tab['id']>(
    CAMPAIGNS_TAB.id
  );

  const [pendingCommunication, setPendingCommunication] = React.useState<
    CommunicationStep
  >();

  const handleReplacementConfirmation = React.useCallback(() => {
    if (!pendingCommunication) return;
    updateStep({ ...pendingCommunication, approved: false });
    setDesignerId(pendingCommunication.designId || 'new');
    setPendingCommunication(undefined);

    if (drawerState === DrawerState.Closed) setDrawerState(DrawerState.Partial);
  }, [
    drawerState,
    pendingCommunication,
    setDesignerId,
    setDrawerState,
    updateStep,
  ]);

  const onSelectContent = React.useCallback(
    (communication: Partial<CommunicationStep>) => {
      const resolvedErrors: JourneyErrorKey[] = [];
      if (!activeStep || activeStep.type !== 'communication') return;
      const newCommunication = { ...communication };

      if (newCommunication.channels && newCommunication.channels.length === 0) {
        delete newCommunication.channels;
      }

      activeStep.name = newCommunication.title ?? 'Untitled';
      resolvedErrors.push('title');
      resolvedErrors.push('designProcessingErrors');

      if (newCommunication.channels) {
        resolvedErrors.push('channelSelection');

        newCommunication.channelDelivery =
          newCommunication.channels.length > 1 ? 'optimize' : undefined;

        const emailChannel = newCommunication.channels.find(isEmailChannel);

        const notificationCenterChannel = newCommunication.channels.find(
          isNotifitcationCenterChannel
        );

        if (emailChannel && !emailChannel.emailSenderAlias) {
          emailChannel.emailSenderAlias = defaultEmailAddress;
        }

        if (emailChannel && emailChannel.emailSenderAlias) {
          emailChannel.programContactAddressId = parseInt(
            emailChannel.emailSenderAlias.id,
            10
          );
        }

        if (emailChannel && emailChannel.subject) {
          resolvedErrors.push('emailChannelSubject');
        }
        if (emailChannel && emailChannel.previewText) {
          resolvedErrors.push('emailChannelPreview');
        }
        if (
          (emailChannel && emailChannel.emailSenderAlias) ||
          (emailChannel && emailChannel.programContactAddressId)
        ) {
          resolvedErrors.push('emailChannelAddress');
        }

        if (notificationCenterChannel && notificationCenterChannel.text) {
          resolvedErrors.push('notificationCenterChannelTitle');
        }
      }

      const newChannels = (
        newCommunication.channels || defaultCommunicationChannels
      ).filter(
        ({ name }) => notificationCenterEnabled || name === CHANNEL_NAMES.EMAIL
      );

      if (activeStep.designId) {
        setPendingCommunication({
          ...activeStep,
          ...newCommunication,
          channels: newChannels,
          selectedChannels: newChannels.map((c) => c.name),
        });
        resolveErrors(activeStep.id, resolvedErrors);
        return;
      }

      const updatedStep = {
        ...activeStep,
        ...newCommunication,
      };
      updatedStep.selectedChannels = updatedStep.channels.map((c) => c.name);
      asserts(
        updatedStep.selectedChannels.length === updatedStep.channels.length,
        'The selectedChannels array should have the same length as the communication step channels array'
      );
      updateStep(updatedStep);

      if (newCommunication.designId) {
        resolvedErrors.push('designId');
      }
      setDesignerId(newCommunication.designId || 'new');
      if (drawerState === DrawerState.Closed) {
        // If we close the drawer before the `copyDesign` promise resolves, the active step will be set to undefined
        // and we will not render the drawer HTML.
        setActiveStepId(activeStep.id);
        setDrawerState(DrawerState.Partial);
        centerY(flowInstance, activeStep.id);
      }
      resolveErrors(activeStep.id, resolvedErrors);
    },
    [
      activeStep,
      drawerState,
      flowInstance,
      resolveErrors,
      setActiveStepId,
      setDesignerId,
      setDrawerState,
      updateStep,
      notificationCenterEnabled,
      defaultEmailAddress,
    ]
  );

  const closeDrawer = () => setContentListState(ContentListState.Closed);
  const isCommunicationStep = activeStep && activeStep.type === 'communication';

  const confirmMessage =
    'This will replace your current Communication content and settings with the content and settings from the campaign you have chosen. This cannot be undone.';

  const handleCreateDesign = React.useCallback(() => {
    setIsTemplateModalOpen(true);
    createDesign();
    update({
      ...defaultDesign,
      parentType: 'CommunicationNode',
      parentSource: 'hermes',
    });
  }, [createDesign, setIsTemplateModalOpen, update]);

  if (!portal.ready) return null;
  return createPortal(
    <Drawer state={contentListState} onOverlayClick={closeDrawer}>
      <Flex column className={styles.journeyContentListContainer}>
        <Flex spread>
          <Heading semibold>
            {isCommunicationStep && activeStep.designId ? 'Replace' : 'Add'}{' '}
            Content
          </Heading>
          <CloseButton handleClose={closeDrawer} />
        </Flex>
        {inlineEditingEnabled && (
          <Flex column start className={styles.journeyContentListActions}>
            <Button
              label="Create New"
              onClick={handleCreateDesign}
              variant="primary"
            />
            <Box padding={[16, 0]}>
              <Text.Body>OR</Text.Body>
            </Box>
            <Text.Subheading semibold>Select existing</Text.Subheading>
          </Flex>
        )}

        <ContentListTabs
          tabs={TABS}
          activeId={activeTabId}
          onSelectTab={setActiveTabId}
          className={styles.journeyContentListTabs}
        />
        <ContentFiltersProvider
          skipQueryParams
          defaultFiltersState={defaultContentFiltersState(defaultFiltersState)}
        >
          <JourneyContentList
            onSelectContent={onSelectContent}
            contentType={activeTabId}
          />
        </ContentFiltersProvider>
      </Flex>
      {pendingCommunication && (
        <ConfirmModal
          width={431}
          onConfirm={handleReplacementConfirmation}
          confirmLabel="Replace"
          title="Replace Content?"
          onCancel={() => setPendingCommunication(undefined)}
          titleIcon={
            <MAIcon name="warning" className={Text.color.orangeFull} />
          }
        >
          <Body color={color.gray60}>{confirmMessage} </Body>
        </ConfirmModal>
      )}
    </Drawer>,
    portal.target
  );
};

const defaultFiltersState = {
  standard: {
    publicationState: {
      values: ['published', 'scheduled', 'draft'],
      isVisible: true,
    },
    studioVersions: {
      isVisible: false,
    },

    // Hack to not remove the template_id from the url.
    // This is not really a filter option, but starting a Journey from a template
    // adds a template_id query param to the url, which is used to fetch the
    // journey template.  The content filters in the content list selector updates
    // the query params based on the selected filters.  Having the `template_id` key
    // here keeps the query param in the url, and the `isVisible` property being false
    // does not allow it to be selected as a filter option in the content list selector.
    template_id: {
      name: 'template_id',
      field: 'template_id',
      label: 'Template',
      values: [],
      isVisible: false,
    },
  },
};
