import { Button, Icon } from '@socialchorus/shared-ui-components';
import { ConfirmModal } from 'DesignSystem/Components';
import React from 'react';

type VariantDeleteModalProps = {
  onDelete: () => void;
  onCancel: () => void;
};

export const VariantDeleteModal: React.FC<VariantDeleteModalProps> = (
  props
) => {
  const { onDelete, onCancel } = props;
  return (
    <ConfirmModal
      title="Delete Variant?"
      titleIcon={
        <div>
          <Icon>warning</Icon>
        </div>
      }
      onCancel={onCancel}
      confirmButton={
        <Button variant="primary" label="Yes, Delete" onClick={onDelete} />
      }
    >
      Are you sure you want to delete this variant? Once deleted, the variant
      and its associated content will no longer be available.
      <br />
      <br />
      This action cannot be undone.
    </ConfirmModal>
  );
};
