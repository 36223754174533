import { Settings } from 'models/publisher/settings';
import { CallToAction } from 'models/publisher/call-to-action';
import { FeatureFlags } from 'models/feature-flag';
import { DataBlock } from 'models/publisher/block';
import { MAX_PREVIEW_TEXT_SIZE } from 'models/context-communication';
import { DateTime } from 'luxon';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useProgram } from 'contexts/program';
import { useState } from 'react';
import { usePublisher } from 'contexts/publisher';
import { Notification, validateTextsPresence } from 'models/notification';
import { selectedChannels } from 'models/channel';
import { useProgramCustomizationsQuery } from './feature-flags';
import {
  useBasicValidator,
  ValidateFunc,
  ValidatorsType,
} from './useBasicValidator';

type ValidatorType = {
  featureFlags?: FeatureFlags;
  notificationCenterEnabled?: boolean;
  settings: Settings;
  callToAction: CallToAction;
  blocks: Array<DataBlock>;
};

const reviewValidators: ValidatorsType<ValidatorType> = {
  no_topic_or_audience: ({ settings }) =>
    settings.contentTopics.length === 0 && settings.audiences.length === 0,
  no_title_or_description: ({ callToAction }) =>
    callToAction.title.length === 0 && callToAction.summary.length === 0,
  no_blocks: ({ blocks }) => blocks.length === 0,
  notification_datetime_in_past: ({ settings }) =>
    isNotifiationInPast(settings),
  archive_date_in_past: ({ settings }) => isArchiveDateInPast(settings),
  notification_datetimes_are_missing: ({ settings }) =>
    isNotificationsMissingDates(settings),
  notification_datetimes_same_date: ({ settings }) =>
    isNotificationDateOverlap(settings),
  notification_has_empty_text: ({ settings }) =>
    isNotificationTextBlank(settings.notifications),
  no_delivery_for_audience: ({ settings }) =>
    audienceHasNoDeliveryEndpoint(settings),
  message_notification_length: ({ settings }) =>
    messageNotificationLengthExceeded(settings),
  campaign_limit_exceeded: ({ settings }) => !!settings.isSizeLimitExceeded,
};

// The list of validators for the new Review includes the ones for the old page, and adds new
const newReviewPageValidators: ValidatorsType<ValidatorType> = {
  ...reviewValidators,
  notifications_and_disabled_engagement_boost: ({ settings }) =>
    notificationsAndDisabledEngagementBoost(settings),
  notification_has_empty_text: ({
    settings,
    notificationCenterEnabled = false,
  }) =>
    isNotificationTextBlankForNewReview(settings, notificationCenterEnabled),
};

const notificationsAndDisabledEngagementBoost = (
  settings: Settings
): boolean => {
  const { notifications, retries, audiences } = settings;
  return notifications.length > 1 && retries === 0 && audiences.length > 0;
};

export const usePublisherValidator: () => {
  validate: ValidateFunc<ValidatorType>;
} = () => {
  return useBasicValidator<ValidatorType>(newReviewPageValidators);
};

export const useNewReviewValidator: () => {
  isValid: boolean;
  errors: Array<string>;
  validate: () => boolean;
} = () => {
  return useStatefulValidators(newReviewPageValidators);
};

const useStatefulValidators = (
  validators: ValidatorsType<ValidatorType>
): {
  isValid: boolean;
  errors: Array<string>;
  validate: () => boolean;
} => {
  const { settings } = useSettings({ syncDefaults: true });
  const validator = useBasicValidator<ValidatorType>(validators);

  const { id: programId } = useProgram();
  const publisher = usePublisher();
  const { post } = publisher;
  const { data: featureFlags } = useProgramCustomizationsQuery(programId);
  const { callToAction, blocks } = post;
  const [errors, setErrors] = useState<Array<string>>([]);

  const validate = () => {
    const { errors: errs } = validator.validate({
      featureFlags,
      settings,
      callToAction,
      blocks,
    });
    setErrors(errs);
    return errs.length === 0;
  };

  return { isValid: errors.length === 0, errors, validate };
};

// TODO: Could be worth revisiting NotificationDateTimeParts.
// TODO: Figure out how n?.dateTime but is not dateTime type is possible (line 52)
// Determine if there is more than one notifiation scheduled for the same day.
const isNotificationDateOverlap = (settings: Settings): boolean => {
  if (settings.notifDeliveryTimesEnabled) {
    const result = settings.notifications
      .filter((n) => !!n?.dateTime && DateTime.isDateTime(n.dateTime))
      .reduce((acc, notif) => {
        const dStr = notif?.dateTime && notif.dateTime.toFormat('ddLLLyy');

        if (dStr) {
          const count = (acc.get(dStr) || 0) + 1;
          acc.set(dStr, count);
        }

        return acc;
      }, new Map<string, number>());

    return !!Array.from(result.values()).find((count) => count > 1);
  }
  return false;
};

const isNotificationsMissingDates = (settings: Settings): boolean => {
  if (settings.notifDeliveryTimesEnabled) {
    return settings.notifications.some((n) => !n?.dateTime);
  }
  return false;
};

const isNotificationTextBlank = (notifs: Notification[]): boolean => {
  if (notifs.length > 1) {
    return notifs.slice(1).some((n) => n.text.length < 1);
  }
  return false;
};

// isNotificationTextBlank having into account that empty notifications use title and description by default
const isNotificationTextBlankForNewReview = (
  settings: Settings,
  notificaitonCenterEnabled: boolean
): boolean => {
  const {
    notifications,
    retries,
    optimizedDeliveryEnabled,
    deliveryChannels,
  } = settings;

  if (
    (retries === -1 || optimizedDeliveryEnabled === true) &&
    notifications.length >= 1
  ) {
    return !notifications.every((notification) =>
      validateTextsPresence(
        notification,
        selectedChannels(deliveryChannels),
        notificaitonCenterEnabled
      )
    );
  }
  return false;
};

const isNotifiationInPast = (settings: Settings): boolean => {
  const notifs = settings.notifications;

  if (settings.publicationState !== 'published' && notifs.length > 0) {
    const firstNotif = notifs[0];
    return !!(
      firstNotif?.dateTime &&
      !firstNotif?.isPublishDateTime &&
      firstNotif.dateTime < (settings.publishedAt || DateTime.now())
    );
  }
  return false;
};

const isArchiveDateInPast = (settings: Settings): boolean => {
  if (settings.archiveAt) {
    return (
      settings.archiveAt < DateTime.now() &&
      settings.publicationState === 'archived'
    );
  }
  return false;
};

const audienceHasNoDeliveryEndpoint = (settings: Settings): boolean => {
  return (
    settings.audiences.length > 0 &&
    settings.contentTopics.length === 0 &&
    settings.deliveryChannels.email === false &&
    settings.deliveryChannels.assistant === false &&
    settings.deliveryChannels.push === false &&
    settings.includeInForYou === false
  );
};

const messageNotificationLengthExceeded = (settings: Settings): boolean => {
  return settings.notifications.some(({ previewText }) =>
    previewText ? previewText?.length > MAX_PREVIEW_TEXT_SIZE : false
  );
};
