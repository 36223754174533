import React, { createContext, useEffect, useState } from 'react';
import { DashboardFilterMapping } from 'services/api-insights';
import { useDashboardFiltersQuery } from '../hooks/useDashboardFilters';

type DashboardContextType = {
  dashboardId: string;
  filters: Record<string, DashboardFilterMapping> | undefined;
  appliedFilters: Record<string, DashboardFilterMapping>;
  dashboardFilters: Record<string, Record<string, DashboardFilterMapping>>;
  updateFilterValue: (name: string, value: string[]) => void;
  clearAppliedFilters: () => void;
};

const defaultState: DashboardContextType = {
  dashboardId: '',
  filters: undefined,
  appliedFilters: {},
  dashboardFilters: {},
  updateFilterValue: () => {},
  clearAppliedFilters: () => {},
};

export const DashboardFilterContext = createContext<DashboardContextType>(
  defaultState
);

const transformFilterState = (
  filters: Record<string, DashboardFilterMapping> | undefined
): Record<string, DashboardFilterMapping> => {
  if (filters === undefined) return {};
  return Object.values(filters).reduce(
    (
      filterMap: Record<string, DashboardFilterMapping>,
      filter: DashboardFilterMapping
    ) => {
      const map = { ...filterMap };
      const filterKey = filter.filter_key.split('|');
      filterKey.forEach((key) => {
        map[key] = {
          filter_key: key,
          value: undefined,
          filter_type: filter.filter_type,
          name: filter.name,
          order: filter.order,
          // todo: revert hardcoding and grab from database
          required: filter.name === 'initiatives',
        };
      });
      return map;
    },
    {}
  );
};

export const DashboardFilterProvider: React.FC<DashboardContextType> = ({
  dashboardId = '',
  children,
}) => {
  const { data: filters } = useDashboardFiltersQuery(dashboardId);
  const [appliedFilters, setAppliedFilters] = React.useState<
    Record<string, DashboardFilterMapping>
  >({});
  const [dashboardFilters, setDashboardFilters] = useState<
    Record<string, Record<string, DashboardFilterMapping>>
  >({});

  const updateFilterValue = React.useCallback(
    (name: string, value: string[]) => {
      setDashboardFilters((f) => {
        const next = structuredClone(f);
        next[dashboardId][name].value = value;
        setAppliedFilters(next[dashboardId]);
        return next;
      });
    },
    [dashboardId]
  );

  const clearAppliedFilters = () => {
    setDashboardFilters((f) => {
      const next = f;
      Object.keys(next[dashboardId]).forEach((x) => {
        if (next[dashboardId][x].filter_type === 'filter')
          next[dashboardId][x].value = undefined;
      });
      return next;
    });
  };

  useEffect(() => {
    if (filters === undefined) return;
    if (dashboardFilters[dashboardId] === undefined) {
      setDashboardFilters((f) => {
        const next = f;
        const newDashboardFilters = transformFilterState(filters);
        // check for already applied parameters
        if (Object.keys(appliedFilters).length) {
          Object.keys(newDashboardFilters).forEach((x) => {
            if (
              newDashboardFilters[x].filter_type === 'parameter' &&
              appliedFilters[x]?.value
            ) {
              newDashboardFilters[x].value = appliedFilters[x]?.value;
            }
          });
        }
        next[dashboardId] = newDashboardFilters;
        setAppliedFilters(next[dashboardId]);
        return next;
      });
    } else {
      // rehydrate appliedFilters with previously selected values for filters, which are specific to a single dashboard
      Object.keys(dashboardFilters[dashboardId]).forEach((k) => {
        const filter = dashboardFilters[dashboardId][k];
        if (filter.value) {
          setAppliedFilters((f) => {
            const next = f;
            if (next[filter.filter_key]) {
              next[filter.filter_key] =
                dashboardFilters[dashboardId][filter.filter_key];
            }
            return next;
          });
        }
      });
    }
  }, [
    dashboardId,
    dashboardFilters,
    filters,
    appliedFilters,
    updateFilterValue,
  ]);

  return (
    <DashboardFilterContext.Provider
      value={{
        dashboardId,
        filters,
        appliedFilters,
        dashboardFilters,
        updateFilterValue,
        clearAppliedFilters,
      }}
    >
      {children}
    </DashboardFilterContext.Provider>
  );
};

export default DashboardFilterProvider;
