import { useNumberFormat } from './NumberFormatProvider';

export function NumericalLabel({
  children,
}: {
  children: number | null | undefined;
}): JSX.Element {
  const numberFormat = useNumberFormat();

  if (children === null || children === undefined) {
    return <></>;
  }

  const formattedNumber = numberFormat.format(children);

  return <>{formattedNumber}</>;
}
