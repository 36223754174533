import React from 'react';
import { Redirect } from '@reach/router';
import { useActor } from 'hooks/authentication';
import { LoadingScreen } from 'shared/LoadingScreen';
import { AuthenticationError } from './AuthenticationError';
import { Logout } from './Logout';

// Redirect the user to the correct program path based on the tenant in which they're authenticated.
// If they're not already authenticated, the call to useActor (with no tenant provided) will
// authenticate them, and a tenant will be chosen by the auth service.
export const ProgramRedirect: React.FC = () => {
  const { actor, error } = useActor();
  if (actor?.programId) {
    return <Redirect to={`../${actor?.programId}`} noThrow />;
  }
  if (error) {
    return <AuthenticationError error={error} />;
  }
  if (actor && !actor.programId) {
    // Logged in but no program: this means we've logged to IAM through a different app than New
    // Studio, and it's remembering our non-program-specific login. Log out so we can start over
    // with the Kai IAM app, which will pick a program for us.
    return <Logout />;
  }
  return <LoadingScreen />;
};
