import { RouteComponentProps } from '@reach/router';
import {
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import React, { useContext, useState } from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useUserMappings } from 'hooks/merge-integration';
import { getIntegrationLabel } from 'models/MergeIntegration/integrations';
import { MergeEmployee } from 'models/MergeIntegration/types';
import { User } from 'models/user';
import { InfiniteList } from 'shared/InfiniteList';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Modal } from 'shared/Modal';
import { MergeIntegrationContext } from '../context';
import styles from './styles.module.css';

function prettyPrintMergeEmployee(employee: MergeEmployee) {
  return JSON.stringify(employee, null, 2);
}

function prettyPrintFirstupUser(user: User) {
  return JSON.stringify(
    {
      id: user.id,
      displayName: user.displayName,
      email: user.email,
    },
    null,
    2
  );
}

export const ViewUserMapping: React.FC<RouteComponentProps<{
  integrationId: string;
}>> = ({ integrationId }) => {
  const { type } = useContext(MergeIntegrationContext);

  const [modalContent, setModalContent] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data,
    meta,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useUserMappings(integrationId ?? '');

  const openModal = (content: string) => () => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const integrationLabel = getIntegrationLabel(type);

  return (
    <FormPage
      title="View User Mapping"
      breadcrumbs={[
        { to: '../../../..', label: 'Configure' },
        { to: '../../..', label: integrationLabel },
        { label: 'View User Mapping' },
      ]}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <Flex spread>
            <Flex column>Firstup ID</Flex>
            <Flex column>{integrationLabel} ID</Flex>
          </Flex>
          <hr />
          <InfiniteList
            itemCount={meta?.totalRecords || 0}
            itemHeight={40}
            height={600}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
          >
            {(index: number) => {
              const userMapping = data[index];
              return (
                <Flex spread className={styles.UserMappingTableRow}>
                  <Flex column>
                    <TooltipRoot>
                      <TooltipTrigger>
                        <button
                          type="button"
                          className={styles.UserMappingRowContent}
                          onClick={openModal(
                            prettyPrintFirstupUser(userMapping.firstupUser)
                          )}
                        >
                          {userMapping.firstupUser.id}
                        </button>
                      </TooltipTrigger>
                      <TooltipContent description="Click to view attributes" />
                    </TooltipRoot>
                  </Flex>

                  <Flex column>
                    <TooltipRoot>
                      <TooltipTrigger>
                        <button
                          type="button"
                          className={styles.UserMappingRowContent}
                          onClick={openModal(
                            prettyPrintMergeEmployee(userMapping.mergeEmployee)
                          )}
                        >
                          {userMapping.mergeEmployee.remoteId}
                        </button>
                      </TooltipTrigger>
                      <TooltipContent description="Click to view attributes" />
                    </TooltipRoot>
                  </Flex>
                </Flex>
              );
            }}
          </InfiniteList>

          <Modal
            title="Preview attributes"
            showTitle={false}
            showModal={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <pre>
              <code>{modalContent}</code>
            </pre>
          </Modal>
        </>
      )}
    </FormPage>
  );
};
