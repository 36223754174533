import React, { FC, useCallback, useMemo, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AudienceSelect } from 'components/audience/AudienceSelect';
import { FieldError } from 'shared/Fieldset/FieldError';
import { usePermissions } from 'contexts/permissions';
import { FieldWithError, Section } from './common';
import MainContainer from '../common/MainContainer';
import Footer from '../common/Footer';
import styles from './styles.module.css';
import PageHeading from '../common/PageHeading';
import ToggleField from './ToggleField';
import { useTopicFormCtx } from '../../context';
import CheckboxField from './CheckboxField';
import RadioGroupField from './RadioGroupField';
import Header from '../common/Header';
import { useIsErrorApplicable } from '../../../topicErrorHooks';
import { SettingsViewErrors } from './SettingsViewErrors';

const ALL_USERS_LABEL = 'All users';
const AUDIENCE_OPTIONS = [
  { label: ALL_USERS_LABEL, value: false },
  { label: 'Targeted Audience', value: true },
];

const AUTOFOLLOW_OPTIONS = [
  { label: 'Entire selected audience', value: false },
  { label: 'Subset of the selected audience', value: true },
];

const SettingsView: FC<RouteComponentProps> = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { state, baseUrl, handleChange } = useTopicFormCtx();
  const { fields } = state;

  const {
    permissions: { canViewAllUsersAudience },
  } = usePermissions();

  let audienceOptions = AUDIENCE_OPTIONS;
  if (!canViewAllUsersAudience) {
    audienceOptions = audienceOptions.filter(
      (option) => option.label !== ALL_USERS_LABEL
    );
    fields.targeted = true;
  }

  const audienceNames = useMemo(
    () => (fields.targeted ? fields.audience.map((a) => a.name) : []),
    [fields.audience, fields.targeted]
  );

  const handleFormChange = useCallback(
    (name, value) => {
      handleChange(name, value);
    },
    [handleChange]
  );

  const handleAudienceChange = useCallback(
    (audiences) => {
      handleFormChange('audience', audiences);
    },
    [handleFormChange]
  );
  const handleAutofollowAudienceChange = useCallback(
    (audiences) => {
      handleFormChange('autoFollowAudience', audiences);
    },
    [handleFormChange]
  );

  const missingTargetAudience = useIsErrorApplicable(
    'Missing Targeted Audience'
  );
  const missingAutoFollowAudience = useIsErrorApplicable(
    'Missing Auto-follow Audience'
  );

  return (
    <MainContainer className={styles.Container}>
      <Header />
      <div className={styles.Content} ref={contentRef}>
        <PageHeading>Settings</PageHeading>
        <div className={styles.SectionContainer}>
          <SettingsViewErrors />
          <Section title="Visibility">
            <ToggleField
              onChange={handleFormChange}
              name="published"
              checked={fields.published}
            >
              Show in Experience
            </ToggleField>
            {fields.published && (
              <CheckboxField
                checked={fields.isPromoted}
                name="isPromoted"
                onChange={handleFormChange}
              >
                Suggest this topic as a Recommended Topic
              </CheckboxField>
            )}
          </Section>
          <Section title="Audience">
            <RadioGroupField
              name="targeted"
              options={audienceOptions}
              value={fields.targeted}
              onChange={handleFormChange}
            />
            {fields.targeted && (
              <FieldWithError
                error={
                  missingTargetAudience && (
                    <FieldError
                      ariaLabel="Missing Targeted Audience"
                      error="Required"
                    />
                  )
                }
              >
                <AudienceSelect
                  placeholder="Select audiences"
                  variant="pills"
                  dropdownVariant="minimal"
                  selectedAudiences={fields.audience}
                  onSelectedAudiencesChange={handleAudienceChange}
                  hideAllUsersAudience
                />
              </FieldWithError>
            )}
          </Section>
          <Section title="Auto-Follow">
            <ToggleField
              onChange={handleFormChange}
              name="autoFollow"
              checked={fields.autoFollow}
            >
              Enable auto-follow
            </ToggleField>
            {fields.autoFollow && (
              <>
                <RadioGroupField
                  name="autoFollowCriterion"
                  options={AUTOFOLLOW_OPTIONS}
                  value={fields.autoFollowCriterion}
                  onChange={handleFormChange}
                />
                {fields.autoFollowCriterion && (
                  <FieldWithError
                    error={
                      missingAutoFollowAudience && (
                        <FieldError
                          ariaLabel="Missing Auto-follow Audience"
                          error="Required"
                        />
                      )
                    }
                  >
                    <AudienceSelect
                      placeholder="Select audiences"
                      variant="pills"
                      dropdownVariant="minimal"
                      name={audienceNames}
                      selectedAudiences={fields.autoFollowAudience}
                      onSelectedAudiencesChange={handleAutofollowAudienceChange}
                      hideAllUsersAudience
                    />
                  </FieldWithError>
                )}
              </>
            )}
          </Section>
          <Section title="Member Submissions">
            <ToggleField
              onChange={handleFormChange}
              name="isUserSubmittable"
              checked={fields.isUserSubmittable}
            >
              Allow members to submit content
            </ToggleField>
            {fields.isUserSubmittable && (
              <CheckboxField
                checked={fields.autoPublish}
                name="autoPublish"
                onChange={handleFormChange}
              >
                Auto-publish content from members
              </CheckboxField>
            )}
          </Section>
        </div>
      </div>

      <Footer ctaText="Next: Review" ctaHref={`${baseUrl}/review`} />
    </MainContainer>
  );
};

export default SettingsView;
