type Value = string;

function isArray(item: unknown) {
  return Array.isArray(item);
}

function isObject(item: unknown) {
  return item && typeof item === 'object' && !isArray(item);
}

function replaceArrayValues(_a: Value[], b: Value[]) {
  return [...b];
}

function mergeArrayValues(a: Value[], b: Value[]): typeof a {
  const ledger: { [value: string]: true } = {};
  const merged: typeof a = [];
  a.forEach((value) => {
    ledger[value] = true;
    merged.push(value);
  });
  b.forEach((value) => {
    if (!ledger[value]) {
      ledger[value] = true;
      merged.push(value);
    }
  });
  return merged;
}

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export function deepMerge<T>(
  target: T,
  source: DeepPartial<T> = {},
  options?: { arrays?: 'merge' | 'replace' }
): T {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((k) => {
      const key = k as keyof typeof source;
      if (isObject(source[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = deepMerge(target[key], source[key], options);
      } else if (isArray(target[key]) && isArray(source[key])) {
        const arrayFn =
          options?.arrays === 'replace' ? replaceArrayValues : mergeArrayValues;
        output[key] = (arrayFn(
          (target[key] as unknown) as Value[],
          (source[key] as unknown) as Value[]
        ) as unknown) as T[keyof T];
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

/**
 * @deprecated Consider using Lodash _.deepClone instead.
 * This function will not work correctly with objects implementing custom toJSON methods
 * such as a Luxon DateTime object:
 *
 * ```tsx
  const source = DateTime.fromISO('2000-01-01T00:00:00.000Z');
  // DateTime { ts: 2000-01-01T00:00:00.000+00:00, zone: UTC, locale: en-US } object
  console.log(source, typeof source);

  const copy = clone(source);
  // "2000-01-01T00:00:00.000Z" string
  console.log(copy, typeof copy);
 * ```
 *
 */
export function clone<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export const test = { mergeArrayValues };
