import React from 'react';
import cx from 'classnames';
import * as Text from 'DesignSystem/Typography';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { Box, FormModal } from 'DesignSystem/Components';
import { FetchedCaption } from 'hooks/video';
import { ConfirmDeleteV2 as ConfirmDelete } from 'shared/ConfirmDelete';
import {
  Download,
  Pencil,
  SettingsCogGear,
  Translate,
  Trash,
} from 'shared/icons';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { TranslationJob } from 'services/api-captions';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { Tag } from '@socialchorus/shared-ui-components';
import { useCaptionPreview } from '../../hooks/useCaptionPreview';
import { FLASH_MESSAGE_PROCESSING } from '../../hooks/useCaptionSettings';
import {
  useCaptionsJobs,
  useTranslateVideo,
} from '../../hooks/useCaptionsJobs';
import { CaptionDeletionModal } from './CaptionDeletionModal';
import { CaptionsTranslation } from './CaptionsTranslation';
import styles from './styles.module.css';

export const CaptionPreview: React.FC<{
  isDragging?: boolean;
  onEdit: () => void;
  onRemove: (options?: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
  }) => void;
  caption: FetchedCaption;
  filename: string;
  videoId?: number;
  isDesignAsset: boolean;
  locale?: string;
  isCaptionsUpdating: boolean;
}> = ({
  caption,
  onEdit,
  onRemove,
  filename,
  videoId,
  isDesignAsset,
  locale,
  isCaptionsUpdating,
}) => {
  const { id: programId } = useProgram();

  const { isDeleting, setDeleting, language, showDialog } = useCaptionPreview(
    caption
  );
  const {
    isCaptionTranscriptionEnabled,
    setTranslationJob,
  } = useCaptionsJobs();
  const EditIcon = isCaptionTranscriptionEnabled ? Pencil : SettingsCogGear;

  const [
    isShowingTranslationDialog,
    setisShowingTranslationDialog,
  ] = React.useState(false);
  const [
    isShowingTranslationConfirmationDialog,
    setisShowingTranslationConfirmationDialog,
  ] = React.useState(false);
  const showTranslationConfirmationDialog = () =>
    setisShowingTranslationConfirmationDialog(true);
  const closeTranslationConfirmationDialog = () =>
    setisShowingTranslationConfirmationDialog(false);

  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    []
  );
  const showTranslationDialog = () => setisShowingTranslationDialog(true);
  const closeTranslationDialog = () => setisShowingTranslationDialog(false);

  const handleStartTranslation = (job: TranslationJob) => {
    setTranslationJob(job);
  };

  const { startTranslation } = useTranslateVideo({
    onSuccess: handleStartTranslation,
    languages: selectedLanguages,
  });

  const { setFlashMessage } = useFlashMessage();

  const url = new URL(caption.url);
  const { pathname } = url;
  const extension = pathname.split('.').pop();

  return (
    <Box
      radius={4}
      relative
      data-test="block-caption-editor-preview"
      color={Text.background.gray05}
    >
      {isDeleting && isCaptionTranscriptionEnabled && (
        <CaptionDeletionModal
          onCancel={() => setDeleting(false)}
          onDelete={() => {
            onRemove({
              onSuccess: () => setDeleting(false),
            });
          }}
          language={language}
          isLoading={isCaptionsUpdating}
        />
      )}
      {isDeleting && !isCaptionTranscriptionEnabled && (
        <ConfirmDelete
          entity="Caption"
          onRemove={(event?: React.MouseEvent) => {
            if (event) event.stopPropagation();
            onRemove();
            setDeleting(false);
          }}
          cancelRemove={() => setDeleting(false)}
        />
      )}
      <Flex
        center
        className={cx({
          [styles.contentsWrapperOld]: !isCaptionTranscriptionEnabled,
          [styles.contentsWrapper]: isCaptionTranscriptionEnabled,
        })}
      >
        <FlexItem widen start>
          <Text.Body bold>{language}</Text.Body>
        </FlexItem>
        {isCaptionTranscriptionEnabled && (
          <Tag
            compact
            variant="info"
            label={caption.generated ? 'Auto' : 'Manual'}
          />
        )}
        <Button
          title="Edit Caption"
          minimal
          clearText
          icon={<EditIcon className={styles.settingsButton} />}
          onClick={onEdit}
          className={styles.iconButton}
        />

        {isCaptionTranscriptionEnabled && (
          <Button
            title="Translate Caption"
            minimal
            clearText
            icon={<Translate className={styles.settingsButton} />}
            onClick={() => {
              if (caption.generated && caption.language !== locale) {
                showTranslationConfirmationDialog();
              } else {
                showTranslationDialog();
              }
            }}
            className={styles.iconButton}
          />
        )}
        {isShowingTranslationConfirmationDialog && (
          <FormModal
            width={664}
            submitLabel="Translate"
            onSubmit={() => {
              closeTranslationConfirmationDialog();
              showTranslationDialog();
            }}
            entityText=""
            actionIcon={
              <Icon name="WarningTriangle" className={styles.warningIcon} />
            }
            actionText={`Translate ${language}?`}
            onCancel={() => {
              closeTranslationConfirmationDialog();
            }}
          >
            <div>
              {language} was created by translating another caption file. For
              the most accurate results, use the original caption file as the
              source for translation.
            </div>
          </FormModal>
        )}
        {isShowingTranslationDialog && (
          <FormModal
            width={400}
            disabled={selectedLanguages.length === 0}
            submitLabel="Continue"
            onSubmit={() => {
              startTranslation(
                {
                  programId,
                  videoId,
                  isDesignAsset,
                  languages: selectedLanguages,
                  sourceCaptionId: caption.id,
                },
                {
                  onSuccess() {
                    setFlashMessage({
                      severity: 'progress',
                      message: FLASH_MESSAGE_PROCESSING,
                    });
                  },
                }
              );
              closeTranslationDialog();
              setSelectedLanguages([]);
            }}
            entityText=""
            actionText="Auto-translate captions"
            onCancel={() => {
              closeTranslationDialog();
              setSelectedLanguages([]);
            }}
          >
            <CaptionsTranslation
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
            />
          </FormModal>
        )}

        {isCaptionTranscriptionEnabled && (
          <a
            title="Download Caption"
            target="_blank"
            rel="noreferrer"
            className={cx(styles.downloadLink, styles.iconButton)}
            href={caption.url}
            download={`${filename}-caption-${caption.language}.${extension}`}
          >
            <Download style={{ width: '18px' }} />
          </a>
        )}

        <Button
          title="Remove Caption"
          minimal
          clearText
          icon={<Trash className={styles.removeCaptionButton} />}
          onClick={showDialog}
          className={styles.iconButton}
        />
      </Flex>
    </Box>
  );
};
