import { useCallback, useMemo } from 'react';
import { usePollDataMutator } from './usePollDataMutator';

const MAX_ANSWERS = 5;
const MIN_ANSWERS = 2;
const DEFAULT_ANSWERS = ['Enter Answer 1', 'Enter Answer 2'];

export function usePollAnswers({
  poll,
  updatePoll,
}: ReturnType<typeof usePollDataMutator>): {
  answers: Array<{
    value: string;
    placeholder: string;
    onChange(answer: string): void;
    onRemove(): void;
  }>;
  canAddAnswer: boolean;
  addAnswer(): void;
  setAnswers(answers: string[]): void;
} {
  const answers = useMemo(
    () =>
      poll.options.map((option, index) => ({
        value: option,
        placeholder: `Answer ${index + 1}`,
        onChange: (answer: string) =>
          updatePoll({
            options: poll.options.map((text, optIdx) => {
              if (optIdx !== index) return text;
              return answer;
            }),
          }),
        onRemove: () => {
          let options = [
            ...poll.options.slice(0, index),
            ...poll.options.slice(index + 1),
          ];

          // prepare minimum options
          if (options.length < MIN_ANSWERS) {
            options =
              options[0] === DEFAULT_ANSWERS[1]
                ? DEFAULT_ANSWERS
                : [options[0], DEFAULT_ANSWERS[1]];
          }

          updatePoll({
            options,
          });
        },
      })),
    [poll.options, updatePoll]
  );
  const canAddAnswer = poll.options.length < MAX_ANSWERS;
  const addAnswer = useCallback(
    () => updatePoll({ options: [...poll.options, ''] }),
    [updatePoll, poll.options]
  );
  const setAnswers = useCallback(
    (options: string[]) => updatePoll({ options }),
    [updatePoll]
  );
  return { canAddAnswer, addAnswer, answers, setAnswers };
}
