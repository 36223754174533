import React from 'react';
import cx from 'classnames';
import { useNavigate, RouteComponentProps } from '@reach/router';
import { Section, Subsection } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { WithPermission } from 'shared/WithPermission';
import { AliasData, NewAliasData } from 'services/api-author-alias';
import { useCreateAuthorAliasOnly } from 'hooks/author-alias';
import { useUpdateUserAvatar } from 'hooks/user';
import { useUpsertUserIdentity, useUserIdentityQuery } from 'hooks/identities';
import { useUser } from 'contexts/user';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { TeamForm, UserForm } from './form';
import cardStyles from './selection-card.module.css';

const SelectionCard: React.FC<{
  title: string;
  description?: string;
  isSelected?: boolean;
  onClick: () => void;
}> = ({ title, description, isSelected, onClick }) => (
  <div
    role="button"
    onClick={onClick}
    tabIndex={-1}
    onKeyDown={() => {}}
    className={cx(cardStyles.wrapper, isSelected && cardStyles.isSelected)}
  >
    <div className={cardStyles.title}>{title}</div>
    <div className={cardStyles.description}>{description}</div>
  </div>
);

const Form: React.FC<{
  onChange: (v: NewAliasData) => void;
  errorMessage?: string;
  setErrorMessage: (value: string) => void;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
}> = ({
  onChange,
  errorMessage,
  setErrorMessage,
  isUploading,
  setIsUploading,
}) => {
  const [aliasType, setAliasType] = React.useState<string>('user');

  const handleTypeChange = (s: string) => {
    setAliasType(s);
    setErrorMessage('');
    onChange({} as NewAliasData);
  };

  const handleChange = (value: NewAliasData) => {
    const newData = {
      ...value,
      aliasType,
    };
    onChange(newData);
  };

  return (
    <Section title="Alias settings">
      <Subsection title="Alias Type" divider={false}>
        <div className={cardStyles.cardsContainer}>
          <SelectionCard
            title="User Alias"
            description="Publish on behalf of a Studio user or registered Member"
            isSelected={aliasType === 'user'}
            onClick={() => handleTypeChange('user')}
          />
          <SelectionCard
            title="Team Alias"
            description="Publish on behalf of a team or department within your organization"
            isSelected={aliasType === 'team'}
            onClick={() => handleTypeChange('team')}
          />
        </div>
      </Subsection>
      {aliasType === 'user' && (
        <UserForm
          onChange={handleChange}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      )}
      {aliasType === 'team' && (
        <TeamForm
          onChange={handleChange}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      )}
    </Section>
  );
};

export const NewAuthorAlias: React.FC<RouteComponentProps> = () => {
  const [aliasData, setAliasData] = React.useState<NewAliasData>(
    {} as NewAliasData
  );
  const [errorMessage, setErrorMessage] = React.useState('');

  const createAliasScope = (alias: AliasData) => {
    return {
      type: 'author_alias',
      name: `author_alias:${alias.id}`,
    };
  };

  const [isUploading, setIsUploading] = React.useState(false);

  const { id: programId } = useProgram();
  const { id: createdBy } = useUser();
  const { data: identity } = useUserIdentityQuery(programId, `${createdBy}`);
  const navigate = useNavigate();
  const authorAliasListUrl = `/${programId}/configure/author-aliases`;
  const { setFlashMessage } = useFlashMessage();
  const { create } = useCreateAuthorAliasOnly(programId, {
    onSuccess: (alias) => {
      const aliasScope = createAliasScope(alias);
      identity?.scopes.push(aliasScope);
      upsertUserIdentity({
        tenant: `program:${programId}`,
        name: `user:${createdBy}`,
        roles: identity?.roles ? identity?.roles : [],
        active: true,
        scopes: identity?.scopes ? identity?.scopes : [],
      });
      setFlashMessage({
        message: 'Custom alias created successfully',
        severity: 'info',
      });

      if (aliasData.aliasType === 'user') {
        if (aliasData.avatarUrlUpdated) {
          updateAvatar(Number(aliasData.userId), aliasData.avatarUrl || '');
        }
      }
      navigate(authorAliasListUrl);
    },
    onError: (error) => {
      const messages = JSON.parse(error).errors || [];
      setFlashMessage({
        message: messages.join('. ') || 'Error saving alias',
        severity: 'error',
      });
    },
  });

  const { upsert: upsertUserIdentity } = useUpsertUserIdentity({
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not assign alias to current user',
      }),
  });

  const { updateAvatar } = useUpdateUserAvatar(programId, {
    onError: () => {
      setFlashMessage({
        severity: 'error',
        message: 'Could not update user avatar',
      });
    },
  });

  const submit = () => {
    const { displayName } = aliasData;
    if (!(displayName || '').trim()) {
      return setErrorMessage('Display Name is a required field');
    }
    return create({ ...aliasData, createdBy });
  };

  return (
    <WithPermission permissions={['configureAliasesAccess']}>
      <FormPage
        title="Create Author Alias"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'Aliases', to: '..' },
          { label: 'Create Author Alias' },
        ]}
        actions={[
          {
            label: 'Save',
            disabled: isUploading,
            onClick: submit,
          },
        ]}
      >
        <Form
          onChange={setAliasData}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      </FormPage>
    </WithPermission>
  );
};
