import React from 'react';
import { FlexItem } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { border, PageHeading, Subheading } from 'DesignSystem/Typography';
import { NumericalLabel } from 'shared/NumericalLabel/NumericalLabel';

import styles from './step-reporting.module.css';

export const MetricItem: React.FC<{
  label: string;
  value: number;
  rate?: number;
}> = ({ label, value }) => {
  return (
    <FlexItem widen>
      <Box
        className={styles.metricItem}
        border={[border.width1, border.solid, border.radius16]}
        height={96}
        width="100%"
        padding={24}
      >
        <PageHeading block={false}>
          <span className={styles.metricValue}>
            <NumericalLabel>{value}</NumericalLabel>
          </span>
        </PageHeading>
        <Subheading semibold>{label}</Subheading>
      </Box>
    </FlexItem>
  );
};
