import React, { ReactNode } from 'react';
import { Tag } from '@socialchorus/shared-ui-components';
import { Box, Tooltip } from '../../../../../../../../DesignSystem/Components';

import styles from './Cards.module.css';

export const CompactStepCard: React.FC<{
  numerator: number;
  denominator: number;
  units: string;
  label: string;
  tooltip?: Array<ReactNode>;
}> = ({ numerator, denominator, units, label, tooltip }) => {
  const renderTooltip = () => {
    return (
      <>
        {tooltip?.map((t) => (
          <p>{t}</p>
        ))}
      </>
    );
  };
  const percentage = (denominator === 0
    ? 0
    : (numerator / denominator) * 100
  ).toFixed(0);
  return (
    <Box className={styles.compactStepCard}>
      <Box className={styles.compactStepCard__header}>
        <span className="text-tertiary-heading">{label}</span>
        {tooltip && tooltip.length > 0 && (
          <Tooltip content={renderTooltip()}>
            <i
              className="material-symbols-rounded"
              style={{ cursor: 'help', verticalAlign: 'middle' }}
            >
              info
            </i>
          </Tooltip>
        )}
      </Box>
      <Box className={styles.compactStepCard__body}>
        <span className="text-main-heading font-normal">
          <span className="font-bold">{numerator.toLocaleString()}</span>/
          <span className="font-bold text-black-40">
            {denominator.toLocaleString()}
          </span>
        </span>
        <Tag
          compact
          variant="info"
          className={styles.compactStepCard__percentage_pill}
          label={`${percentage}%`}
        />
        <span className="font-semibold">{units}</span>
      </Box>
    </Box>
  );
};
