import React from 'react';
import { DefaultAvatar } from 'shared/icons';
import { useProgramIdState } from 'contexts/program';
import { ImageData } from 'models/image';
import { useFlashMessage } from 'contexts/flasher';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useImageUploader } from 'hooks/useImageUploader';
import { ImageUploadInput } from './ImageUploadInput';
import styles from '../../profile-picture.module.css';

export const UserAvatarSection: React.FC<{
  avatarUrl?: string;
  onChange: (avatarUrl: string) => void;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
}> = ({ avatarUrl, onChange, isUploading, setIsUploading }) => {
  const [programId] = useProgramIdState();
  const { setFlashMessage } = useFlashMessage();

  const iconUploader = useImageUploader({
    onUpload: (data: ImageData) => {
      if (data.url) {
        onChange(data.url);
      } else {
        setIsUploading(false);
      }
    },
    programId,
    onError: (message) => {
      setFlashMessage({ message, severity: 'error' });
      setIsUploading(false);
    },
  });

  const base = { marginTop: '0' };
  return (
    <div>
      {isUploading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className={styles.TeamAvatar}
            style={
              avatarUrl
                ? { ...base, backgroundImage: `url(${avatarUrl})` }
                : base
            }
          >
            {!avatarUrl && <DefaultAvatar />}
          </div>
          <ImageUploadInput
            buttonLabel={avatarUrl ? 'Change image' : 'Upload image'}
            onChange={(newAvatarUrl) => {
              setIsUploading(true);
              onChange(newAvatarUrl);
            }}
            showRemoveButton={!!avatarUrl}
            iconUploader={iconUploader}
            setIsUploading={setIsUploading}
          />
        </>
      )}
    </div>
  );
};
