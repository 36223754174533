import { useQuery } from 'react-query';
import { useProgram } from 'contexts/program';
import { QueryResponse } from 'hooks/common';
import { FeatureFlag } from 'models/feature-flag';
import {
  Integrations,
  SupportedIntegration,
} from 'models/MergeIntegration/integrations';
import { fetchFeatureFlags } from 'services/api-feature-flags';

export function useMergeIntegrationFeatureFlagsQuery(): QueryResponse<
  Record<SupportedIntegration, FeatureFlag>
> {
  const { id: programId } = useProgram();

  const { isLoading, error, data } = useQuery<
    Record<SupportedIntegration, FeatureFlag>,
    Error
  >(
    ['feature_flag', programId, 'merge_integrations'],
    async () =>
      Object.fromEntries(
        await Promise.all(
          Object.values(SupportedIntegration).map(async (type) => [
            type,
            await fetchFeatureFlags(programId, Integrations[type].featureFlag),
          ])
        )
      ),
    { retry: false, refetchOnMount: false, refetchOnWindowFocus: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
}
