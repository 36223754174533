import cx from 'classnames';
import { Button } from 'DesignSystem/Form/InputElements';
import { DateTime } from 'luxon';
import { Option } from 'models/insight/json/filterJson';
import React, { useCallback, useMemo, useState } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { DashboardFilterContext } from '../contexts/DashboardFilterContext';
import styles from './dashboard-parameter.module.css';
import { DashboardFilterDropdown } from './DashboardFilterDropdown';
import { generateDateValues } from './filter-utils';

type DashboardParameterProps = {
  label: string;
  name: string;
  values: Option[];
  onChange: (name: string, value: string | number | boolean | Date) => void;
  updateFilter?: (value: string) => void;
  selected?: string | number | boolean | Date;
  clearDisabled?: boolean;
  queryHook?: () => void;
  valueTransform?: (data: {
    [key: string]: string;
  }) => { data: string; value: string };
};

const DashboardParameter: React.FC<DashboardParameterProps> = ({
  label,
  name,
  values,
  onChange,
  updateFilter,
  selected,
  clearDisabled = false,
}) => {
  const onParameterChanged = useCallback(
    (value: string[]) => {
      if (value.length > 1) {
        const newVal = value[value.length - 1];
        updateFilter?.(newVal);
      } else {
        const [newVal] = value.length === 0 ? [] : value;
        updateFilter?.(newVal);
      }
    },
    [updateFilter]
  );

  const onClose = React.useCallback(() => {
    if (selected) onChange(name, selected);
  }, [name, onChange, selected]);

  const dropdown = React.useMemo(
    () => (
      <DashboardFilterDropdown
        clearDisabled={clearDisabled}
        onChange={onParameterChanged}
        options={values}
        label={label}
        selectedOptions={
          values ? values.filter((opt: Option) => selected === opt.value) : []
        }
        multiSelect={false}
        onClose={onClose}
      />
    ),
    [clearDisabled, label, onClose, onParameterChanged, selected, values]
  );

  return dropdown;
};

const dateOptions = {
  quarter: generateDateValues('quarter'),
  month: generateDateValues('month'),
  year: generateDateValues('year'),
};

export const DateRangePickerContainer: React.FC<{
  onChange: (name: string, value: string[]) => void;
}> = ({ onChange }) => {
  const { appliedFilters } = React.useContext(DashboardFilterContext);

  const lastMonth = DateTime.now().startOf('month').minus({ months: 1 });
  const startDate = appliedFilters?.time_range_start_date?.value;
  const endDate = appliedFilters?.time_range_end_date?.value;
  const granularity = appliedFilters?.time_range_granularity_param?.value;

  const [selectedGranularity, setSelectedGranularity] = useState<string>(
    granularity ? granularity[0] : 'month'
  );
  const [selectedStartDate, setSelectedStartDate] = useState<{
    name: string;
    value: string;
  }>({
    name: 'time_range_start_date',
    value: startDate
      ? startDate[0]
      : lastMonth.minus({ months: 3 }).toLocaleString(DateTime.DATE_SHORT),
  });
  const [selectedEndDate, setSelectedEndDate] = useState<{
    name: string;
    value: string;
  }>({
    name: 'time_range_end_date',
    value: endDate ? endDate[0] : lastMonth.toLocaleString(DateTime.DATE_SHORT),
  });
  const [selectedDateOptions, setSelectedDateOptions] = useState<Option[]>(
    dateOptions[selectedGranularity as keyof typeof dateOptions]
  );

  const updateFilters = useCallback(
    (value: string) => {
      if (value === undefined) return;
      setSelectedGranularity(value);
      setSelectedDateOptions(dateOptions[value as keyof typeof dateOptions]);
      onChange('time_range_granularity_param', [value]);
    },
    [onChange]
  );

  const onParameterChanged = useCallback(
    (name: string, value: string) => {
      if (name === selectedStartDate.name) {
        setSelectedStartDate({ name, value });
      } else if (name === selectedEndDate.name) {
        setSelectedEndDate({ name, value });
      }
      if (value !== undefined) {
        onChange(name, [value]);
      }
    },
    [selectedStartDate.name, selectedEndDate.name, onChange]
  );

  const label = useMemo(() => {
    if (
      selectedStartDate.value &&
      selectedEndDate.value &&
      selectedDateOptions
    ) {
      const startLbl = selectedDateOptions.find(
        (o) => o.value === selectedStartDate.value
      )?.label;
      const endLbl = selectedDateOptions.find(
        (o) => o.value === selectedEndDate.value
      )?.label;
      if (startLbl && endLbl) {
        return `${endLbl} ${' vs.  '} ${startLbl}`;
      }
    }

    return '';
  }, [selectedStartDate.value, selectedEndDate.value, selectedDateOptions]);

  const reset = useCallback(() => {
    const currentGranularity = 'month';
    setSelectedGranularity(currentGranularity);
    setSelectedDateOptions(
      dateOptions[currentGranularity as keyof typeof dateOptions]
    );
    setSelectedStartDate({
      name: 'time_range_start_date',
      value: lastMonth.minus({ months: 3 }).toLocaleString(DateTime.DATE_SHORT),
    });
    setSelectedEndDate({
      name: 'time_range_end_date',
      value: lastMonth.toLocaleString(DateTime.DATE_SHORT),
    });
    onChange('time_range_granularity_param', [currentGranularity]);
    onChange('time_range_start_date', [
      lastMonth.minus({ months: 3 }).toLocaleString(DateTime.DATE_SHORT),
    ]);
    onChange('time_range_end_date', [
      lastMonth.toLocaleString(DateTime.DATE_SHORT),
    ]);
  }, [lastMonth, onChange]);

  const done = useCallback(
    (close: () => void) => {
      onChange('time_range_granularity_param', [selectedGranularity as string]);
      onChange(selectedStartDate.name, [selectedStartDate.value as string]);
      onChange(selectedEndDate.name, [selectedEndDate.value as string]);
      close();
    },
    [onChange, selectedGranularity, selectedStartDate, selectedEndDate]
  );

  const granularityParameterDropdown = React.useMemo(
    () => (
      <DashboardParameter
        label="Granularity"
        name="time_range_granularity_param"
        values={[
          { label: 'Quarterly', value: 'quarter' },
          { label: 'Yearly', value: 'year' },
          { label: 'Monthly', value: 'month' },
        ]}
        selected={selectedGranularity}
        onChange={() => {}}
        updateFilter={updateFilters}
        clearDisabled
      />
    ),
    [selectedGranularity, updateFilters]
  );
  const startDateParameterDropdown = React.useMemo(
    () => (
      <DashboardParameter
        label="Start Date"
        name={selectedStartDate.name}
        values={selectedDateOptions}
        selected={selectedStartDate.value}
        onChange={() => {}}
        updateFilter={(value: string) => {
          onParameterChanged(selectedStartDate.name, value);
        }}
      />
    ),
    [
      onParameterChanged,
      selectedDateOptions,
      selectedStartDate.name,
      selectedStartDate.value,
    ]
  );
  const endDateParameterDropdown = React.useMemo(
    () => (
      <DashboardParameter
        label="End Date"
        name={selectedEndDate.name}
        values={selectedDateOptions}
        selected={selectedEndDate.value}
        onChange={() => {}}
        updateFilter={(value: string) => {
          onParameterChanged(selectedEndDate.name, value);
        }}
      />
    ),
    [
      onParameterChanged,
      selectedDateOptions,
      selectedEndDate.name,
      selectedEndDate.value,
    ]
  );

  const dropdown = React.useCallback(
    (close: () => void) => {
      return (
        <div className={styles.dropdown}>
          <div className={styles.dropdownRow}>
            {granularityParameterDropdown}
          </div>
          <div className={styles.dropdownRow}>
            {endDateParameterDropdown}
            <span className={styles.delimiter}>{' vs.  '}</span>
            {startDateParameterDropdown}
          </div>
          <div className={styles.dropdownRow}>
            <Button
              borderless
              className={styles.buttonWhite}
              onClick={() => reset()}
              label="Reset"
              compact
            />
            <Button
              borderless
              className={styles.done}
              label="Done"
              onClick={() => done(close)}
              compact
            />
          </div>
        </div>
      );
    },
    [
      granularityParameterDropdown,
      endDateParameterDropdown,
      startDateParameterDropdown,
      reset,
      done,
    ]
  );
  return (
    <ClickDropdown dropdownRenderProp={dropdown}>
      <div
        className={cx(styles.container, 'kai-flex-row', {
          [styles.filterApplied]: label.length > 0,
        })}
      >
        <span className={styles.label}>{`Time Range: ${label}`}</span>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.append}
        >
          <path
            d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </ClickDropdown>
  );
};
