import React, { useContext } from 'react';
import { Icon } from '@socialchorus/shared-ui-components';
import { DateTime } from 'luxon';
import { getIntegrationLabel } from 'models/MergeIntegration/integrations';
import { useUserMappingConfiguration } from 'hooks/merge-integration';
import { MergeIntegrationContext } from '../../context';
import styles from './styles.module.css';

type UserMappingConfigPreviewProps = {
  integrationId: string;
};

export const UserMappingConfigPreview: React.FC<UserMappingConfigPreviewProps> = ({
  integrationId,
}) => {
  const { type } = useContext(MergeIntegrationContext);
  const { data: userMappingConfig } = useUserMappingConfiguration(
    integrationId
  );

  const integrationLabel = getIntegrationLabel(type);

  return (
    <>
      {userMappingConfig ? (
        <div className={styles.PreviewContainer}>
          <span className={styles.SettingsPreview}>
            <span className={styles.SettingsPreviewItem}>
              {userMappingConfig.mergeAttribute}
              <span className={styles.SettingsPreviewItemSource}>
                ({integrationLabel})
              </span>
            </span>
            <Icon>sync_alt</Icon>
            <span className={styles.SettingsPreviewItem}>
              {userMappingConfig.firstupAttributeQueryBlueprint.name}
              <span className={styles.SettingsPreviewItemSource}>
                (Firstup)
              </span>
            </span>
          </span>

          <span className={styles.SettingsLastUpdatedAt}>
            Last updated on{' '}
            {DateTime.fromJSDate(
              new Date(userMappingConfig.updatedAt)
            ).toFormat('MMM dd, yyyy')}
          </span>
        </div>
      ) : (
        'User mapping is not configured.'
      )}
    </>
  );
};
