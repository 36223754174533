import React from 'react';
import { Subsection } from 'DesignSystem/Form';
import { DefaultAvatar } from 'shared/icons';
import { ImageData } from 'models/image';
import { useProgramIdState } from 'contexts/program';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { AliasData } from 'services/api-author-alias';
import { useFlashMessage } from 'contexts/flasher';
import { useImageUploader } from 'hooks/useImageUploader';
import { ImageUploadInput } from './ImageUploadInput';
import styles from '../author-alias.module.css';

export const TeamAvatarSection: React.FC<{
  aliasData: Partial<AliasData>;
  onChange: (avatarUrl: string) => void;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
}> = ({
  aliasData: { avatarUrl, displayName },
  onChange,
  isUploading,
  setIsUploading,
}) => {
  const base = { marginTop: '0' };

  const { setFlashMessage } = useFlashMessage();
  const [programId] = useProgramIdState();

  const iconUploader = useImageUploader({
    onUpload: (data: ImageData) => {
      if (data.url) {
        onChange(data.url);
      }
      setIsUploading(false);
    },
    programId,
    onError: (message) => {
      setFlashMessage({ message, severity: 'error' });
      setIsUploading(false);
    },
  });

  return (
    <Subsection
      title="Display Avatar"
      description="(Optional) Will appear next to the display name in the member experience"
      divider={false}
    >
      <div>
        {isUploading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div
              className={styles.TeamAvatar}
              style={
                avatarUrl
                  ? { ...base, backgroundImage: `url(${avatarUrl})` }
                  : base
              }
              title={`${
                avatarUrl ? 'The ' : 'No'
              } avatar for the ${displayName} alias`}
            >
              {!avatarUrl && <DefaultAvatar />}
            </div>
            <ImageUploadInput
              buttonLabel={avatarUrl ? 'Change image' : 'Upload image'}
              onChange={(newAvatarUrl) => {
                onChange(newAvatarUrl);
              }}
              showRemoveButton={!!avatarUrl}
              iconUploader={iconUploader}
              setIsUploading={setIsUploading}
              type="team"
            />
          </>
        )}
      </div>
    </Subsection>
  );
};
