import { createContext } from 'react';
import { SupportedIntegration } from 'models/MergeIntegration/integrations';

type MergeIntegrationContextType = {
  type: SupportedIntegration;
};

export const MergeIntegrationContext = createContext<
  MergeIntegrationContextType
>({
  type: SupportedIntegration.WORKDAY,
});
