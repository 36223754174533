import * as React from 'react';
import { Button, Checkbox } from 'DesignSystem/Form';
import { useBulkSelect } from 'contexts/bulk-select';
import { BulkSelection } from 'hooks/common';
import { FAIcon } from 'shared/Icon/FAIcon';
import { Icon } from 'shared/Icon';
import { useState } from 'react';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { ContentFiltersContext } from 'contexts/content/filters';
import { uniqueId } from 'hooks/useUniqueId';
import { capitalize } from 'utility/strings';
import { HoverDropdown } from '../../hover-dropdown/HoverDropdown';
import {
  BulkSelectAction,
  BulkSelectActionType,
  ActionButtonType,
} from '../Actions';
import styles from './bulk-select-bar.module.css';
import { ConfirmModal } from './ConfirmModal';

export type BulkSelectBarPropType = {
  actions?: BulkSelectActionType[];
  moreActions?: ActionButtonType[];
  itemName?: string;
  setIsBulkSelectMode?: (isBulkMode: boolean) => void;
  headers?: React.ReactElement;
  recordsToUpdate?: number;
  allowSelection?: boolean;
  clearOnFilterUpdate?: boolean;
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

export const BulkSelectBar: React.FC<BulkSelectBarPropType> = (props) => {
  const {
    actions,
    moreActions,
    itemName,
    setIsBulkSelectMode,
    recordsToUpdate,
    allowSelection = true,
    clearOnFilterUpdate,
  } = props;
  const MAX_RECORD_ALLOWED = 2000;
  React.useEffect(() => {
    if (setIsBulkSelectMode) setIsBulkSelectMode(true);
  }, [setIsBulkSelectMode]);
  const {
    selectedIds,
    excludedIds,
    isAllSelected,
    selectedCount,
    bulkSelectEnabled,
    clearBulkSelect,
    selectAll,
    totalRecords,
    selectedAction,
    setSelectedAction,
    cancel: cancelBulkSelection,
  } = useBulkSelect();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleConfirm = React.useCallback(() => {
    if (!isAllSelected && selectedIds.length === 0 && excludedIds.length === 0)
      return;
    const bulkSelection: BulkSelection = isAllSelected
      ? { type: 'all', excludedIds }
      : { type: 'none', includedIds: selectedIds };
    setIsConfirmOpen(false);
    if (selectedAction?.onClick(bulkSelection)) clearBulkSelect();
  }, [
    isAllSelected,
    selectedAction,
    selectedIds,
    excludedIds,
    clearBulkSelect,
  ]);

  const { id: programId } = useProgram();
  const { filters } = React.useContext(ContentFiltersContext);

  const { data: confirmModalFlag } = useFeatureFlagsQuery(
    programId,
    'Studio.BulkSelect.Modal'
  );

  const isConfirmModalFlag = confirmModalFlag?.value;
  const handleAction = React.useCallback(
    (action: ActionButtonType) => {
      setSelectedAction(action);
    },
    [setSelectedAction]
  );

  const handleBeforeAction = React.useCallback(async () => {
    if (!isAllSelected && selectedIds.length === 0 && excludedIds.length === 0)
      return;
    const bulkSelection: BulkSelection = isAllSelected
      ? { type: 'all', excludedIds }
      : { type: 'none', includedIds: selectedIds };
    await selectedAction?.beforeAction?.(bulkSelection);
  }, [isAllSelected, selectedAction, selectedIds, excludedIds]);

  React.useEffect(() => {
    if (!selectedAction) return;
    if (isConfirmModalFlag && selectedAction.confirm !== false) {
      if (selectedAction.beforeAction) {
        handleBeforeAction().then(() => setIsConfirmOpen(true));
      } else {
        setIsConfirmOpen(true);
      }
    } else handleConfirm();
  }, [handleConfirm, isConfirmModalFlag, selectedAction, handleBeforeAction]);

  React.useEffect(() => {
    if (clearOnFilterUpdate) {
      clearBulkSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, clearOnFilterUpdate]);

  const handleCancel = React.useCallback(() => {
    setIsConfirmOpen(false);
    cancelBulkSelection();
  }, [cancelBulkSelection]);

  const actionButtons = React.useMemo(() => {
    const scimSelected = (scimIds: Array<string>) => {
      // eslint-disable-next-line prettier/prettier
      return selectedIds.some((id) => scimIds.includes(id));
    };
    return (
      <>
        {actions?.map((action) => {
          const shouldDisableForScim =
            action.disabled && action.scimIds && scimSelected(action.scimIds);
          return (
            <BulkSelectAction
              designSystem
              key={uniqueId()}
              handleAction={handleAction}
              isLoading={action.isLoading}
              type={action.type}
              toStatusLabel={action.toStatusLabel}
              actions={action.actions}
              beforeAction={action.beforeAction}
              groupLabel={action.groupLabel}
              disabled={
                !selectedCount || shouldDisableForScim || !allowSelection
              }
              hoverButtonTooltip={
                shouldDisableForScim
                  ? 'ID Provider generated audiences cannot be archived'
                  : undefined
              }
            />
          );
        })}
      </>
    );
  }, [actions, handleAction, selectedCount, allowSelection, selectedIds]);

  if (!bulkSelectEnabled || !totalRecords) return null;

  const selectedMoreThanMax = selectedCount > MAX_RECORD_ALLOWED;
  const itemLabel = itemName ? `${capitalize(itemName)}s` : '';

  return (
    <div className={styles.actionsWrapper}>
      {!!actions?.length && (
        <div className={styles.actionsLeft}>
          <Checkbox
            type="checkbox"
            checked={isAllSelected || (selectedCount > 0 && 'partial')}
            onChange={() => {
              if (isAllSelected) clearBulkSelect();
              else selectAll();
            }}
            disabled={!allowSelection}
          />
          {selectedCount > 0 && (
            <span className={styles.selectedCount}>
              {selectedCount} selected
            </span>
          )}
        </div>
      )}

      <div className={styles.actionsRight}>
        {actionButtons}
        {moreActions?.length && (
          <HoverDropdown
            disabled={!selectedCount || !allowSelection}
            dropdownClassName="dropdown-align-right"
            dropdownRenderProp={() => (
              <div className={styles['more-list']}>
                {moreActions?.map((action) => (
                  <div
                    key={action.label}
                    onClick={() => {
                      handleAction(action);
                    }}
                    className={styles['menu-item']}
                  >
                    {action.iconName ? <FAIcon name={action.iconName} /> : ''}
                    {action.label}
                  </div>
                ))}
              </div>
            )}
          >
            <Button
              compact
              disabled={!selectedCount}
              secondary
              label={
                <>
                  More <Icon iconName="caret-down" />
                </>
              }
            />
          </HoverDropdown>
        )}
        {isConfirmOpen && (
          <ConfirmModal
            actionTitle={selectedAction?.label || ''}
            hideLabel={selectedAction?.hideLabel}
            alert={
              selectedMoreThanMax
                ? `Bulk actions are limited to ${MAX_RECORD_ALLOWED} ${itemLabel} at a time. The first ${MAX_RECORD_ALLOWED} ${itemLabel} will be processed.`
                : undefined
            }
            customTitle={selectedAction?.customTitle}
            recordsToUpdate={
              selectedMoreThanMax ? MAX_RECORD_ALLOWED : recordsToUpdate
            }
            toStatusLabel={selectedAction?.toStatusLabel}
            itemName={itemName}
            count={selectedMoreThanMax ? MAX_RECORD_ALLOWED : selectedCount}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
        )}
      </div>
    </div>
  );
};
