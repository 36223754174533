import cx from 'classnames';
import { DateTime, Interval, WeekdayNumbers } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Box } from '../../../../../DesignSystem/Components';
import { Button } from '../../../../../DesignSystem/Form';
import { Flex, FlexItem } from '../../../../../DesignSystem/Layout/Flex';
import { ClickDropdown } from '../../../../../shared/ClickDropdown';
import { ChevronLeft, ChevronRight } from '../../../../../shared/icons';
import { DashboardFilterContext } from '../contexts/DashboardFilterContext';
import styles from './dashboard-parameter.module.css';

DateTime.local()
  .setZone('local')
  .set({ weekday: 7 as WeekdayNumbers });

type DatePickerTimeRange = {
  start?: DateTime;
  end?: DateTime;
};

type DatePickerProps = {
  start: {
    name: string;
    value?: DateTime;
  };
  end: {
    name: string;
    value?: DateTime;
  };
  onChange: (name: string, value: string[]) => void;
};

const generateDailyValues = (selectedDate: DateTime): DateTime[] => {
  const now = DateTime.now();
  const start = selectedDate.startOf('month');
  const end = selectedDate.endOf('month');
  const firstDayOfMonth = start.weekday % 7;

  const emptyDays = Array(firstDayOfMonth).fill(null);
  const monthDays = Interval.fromDateTimes(start, end)
    .splitBy({ days: 1 })
    .map((interval) => interval?.start)
    .filter((date) => date && date <= end && date >= now.minus({ years: 2 }));

  return [...emptyDays, ...monthDays];
};

export const DashboardDateRangePicker: React.FC<DatePickerProps> = ({
  start,
  end,
}) => {
  const [timeRange, setTimeRange] = useState<DatePickerTimeRange>({
    start: start.value ?? undefined,
    end: end.value ?? undefined,
  });
  const [appliedTimeRange, setAppliedTimeRange] = useState<DatePickerTimeRange>(
    {
      start: start.value ?? undefined,
      end: end.value ?? undefined,
    }
  );

  const { updateFilterValue } = React.useContext(DashboardFilterContext);

  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
  const initialLabel =
    start.value && end.value
      ? `${start.value.toFormat('MM/dd/yy')} to ${end.value.toFormat(
          'MM/dd/yy'
        )}`
      : undefined;
  const [label, setLabel] = useState(initialLabel);

  const isNextMonthDisabled =
    selectedDate.plus({ months: 1 }).startOf('month') > DateTime.now();
  const isPrevMonthDisabled =
    selectedDate.minus({ months: 1 }).startOf('month') <
    DateTime.now().minus({ years: 2 });

  const renderDaysOfWeek = () => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day) => (
      <FlexItem
        key={day + Math.random().toString()}
        className={styles.datePickerBodyHeader}
      >
        {day}
      </FlexItem>
    ));
  };

  const renderMonth = useCallback(
    (date: DateTime) => {
      const days = generateDailyValues(date);
      return (
        <div className={styles.monthContainer}>
          <div className={styles.monthBody}>
            {renderDaysOfWeek()}
            {days.map((day) => {
              if (day === null) {
                return (
                  <FlexItem
                    key={`empty-${Math.random().toString()}`}
                    className={styles.emptyDay}
                  />
                );
              }
              return (
                <DayButton
                  key={day.toISO()}
                  date={day}
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                />
              );
            })}
          </div>
        </div>
      );
    },
    [timeRange, setTimeRange]
  );

  const handlePrevMonth = () => {
    setSelectedDate((current) => current.minus({ months: 1 }));
  };

  const handleNextMonth = () => {
    setSelectedDate((current) => current.plus({ months: 1 }));
  };

  const handleApply = useCallback(
    (close: () => void, newTimeRange?: DatePickerTimeRange) => {
      const rangeToApply = newTimeRange || timeRange;
      if (rangeToApply.start && rangeToApply.end) {
        setAppliedTimeRange({
          start: rangeToApply.start,
          end: rangeToApply.end,
        });
        updateFilterValue('journey_entry_start_date', [
          rangeToApply.start.toFormat('MM/dd/yy'),
        ]);
        updateFilterValue('journey_entry_end_date', [
          rangeToApply.end.toFormat('MM/dd/yy'),
        ]);
        close();
      }
    },
    [updateFilterValue, timeRange]
  );

  useEffect(() => {
    if (
      appliedTimeRange.start === undefined ||
      appliedTimeRange.end === undefined
    ) {
      setLabel('');
      return;
    }
    setLabel(
      `${appliedTimeRange.start.toFormat(
        'MM/dd/yy'
      )} to ${appliedTimeRange.end.toFormat('MM/dd/yy')}`
    );
  }, [appliedTimeRange, setLabel]);

  const handleCancel = useCallback(
    (close: () => void) => {
      setTimeRange(appliedTimeRange);
      close();
    },
    [appliedTimeRange]
  );

  // const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
  //   () => {}
  // );

  const dropdown = useCallback(
    (close: () => void) => {
      return (
        <Box className={cx(styles.dropdown, styles.datePickerDropdown)}>
          <Box className={styles.datePickerHeader}>
            <Box className={styles.datePickerHeaderLeft}>
              {selectedDate.toLocaleString({ month: 'long', year: 'numeric' })}
            </Box>
            <Box className={styles.datePickerHeaderRight}>
              <Box className={styles.datePickerHeaderRightMonth}>
                {selectedDate
                  .plus({ months: 1 })
                  .toLocaleString({ month: 'long', year: 'numeric' })}
              </Box>
              <Box className={styles.datePickerHeaderChevronContainer}>
                <Button
                  className={styles.monthButton}
                  borderless
                  minimal
                  secondary
                  onClick={handlePrevMonth}
                  label={<ChevronLeft />}
                  disabled={isPrevMonthDisabled}
                />
                <Button
                  className={styles.monthButton}
                  borderless
                  minimal
                  secondary
                  onClick={handleNextMonth}
                  label={<ChevronRight />}
                  disabled={isNextMonthDisabled}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.datePickerBody}>
            <div className={styles.monthsContainer}>
              {renderMonth(selectedDate)}
              {renderMonth(selectedDate.plus({ months: 1 }))}
            </div>
          </Box>
          <Flex className={styles.datePickerFooter}>
            <FlexItem start>
              <Button
                className={styles.buttonWhite}
                borderless
                type="button"
                label="Clear"
                color="white"
                compact
                onClick={() => setTimeRange({})}
              />
            </FlexItem>
            <FlexItem end>
              <Button
                className={styles.buttonWhite}
                borderless
                type="button"
                label="Cancel"
                color="white"
                compact
                onClick={() => handleCancel(close)}
              />
              <Button
                type="button"
                label="Apply"
                compact
                onClick={() => handleApply(close)}
              />
            </FlexItem>
          </Flex>
        </Box>
      );
    },
    [
      selectedDate,
      isPrevMonthDisabled,
      isNextMonthDisabled,
      renderMonth,
      handleCancel,
      handleApply,
    ]
  );

  const relativeRangeDropdown = useCallback(
    () => (
      <RelativeRangePanel
        setTimeRange={setTimeRange}
        handleApply={(close, updatedTimerange) =>
          handleApply(close, updatedTimerange)
        }
        dropdownRenderProp={(close) => dropdown(close)}
      />
    ),
    [setTimeRange, dropdown, handleApply]
  );

  return (
    <Box>
      <Flex>
        <ClickDropdown
          dropdownRenderProp={relativeRangeDropdown}
          onDropdownClick={() => {}}
        >
          <div
            className={cx(styles.container, 'kai-flex-row', {
              [styles.filterApplied]: label?.length,
            })}
          >
            <span className={styles.label}>Journey Entry Date: {label}</span>
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.append}
            >
              <path
                d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
                stroke="currentColor"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </ClickDropdown>
      </Flex>
    </Box>
  );
};

const DayButton: React.FC<{
  date: DateTime;
  timeRange: DatePickerTimeRange;
  setTimeRange: React.Dispatch<React.SetStateAction<DatePickerTimeRange>>;
}> = ({ date, timeRange, setTimeRange }) => {
  const allowedStart = DateTime.now().minus({ years: 2 });
  const allowedEnd = DateTime.now();
  const isActive =
    timeRange?.start?.toString() === date.toString() ||
    timeRange?.end?.toString() === date.toString();
  const isInactive =
    timeRange?.start?.toString() !== date.toString() &&
    timeRange?.end?.toString() !== date.toString();
  const isInRange =
    timeRange.start &&
    timeRange.end &&
    date > timeRange.start &&
    date < timeRange.end;
  const isDisabled = date < allowedStart || date > allowedEnd;

  const handleClick = () => {
    if (!timeRange.start || (timeRange.start && timeRange.end)) {
      setTimeRange({ start: date });
    } else if (!timeRange.end && date > timeRange.start) {
      setTimeRange((prev) => ({ ...prev, end: date }));
    } else {
      setTimeRange({ start: date });
    }
  };

  return (
    <FlexItem
      className={cx(styles.dayButton, {
        [styles.active]: isActive,
        [styles.inactive]: isInactive && !isInRange,
        [styles.inRange]: isInRange,
      })}
    >
      <Button
        color="white"
        borderless
        label={date.day.toString()}
        onClick={handleClick}
        disabled={isDisabled}
      />
    </FlexItem>
  );
};

const RelativeRangePanel: React.FC<{
  setTimeRange: (timeRange: DatePickerTimeRange) => void;
  handleApply: (close: () => void, timerange: DatePickerTimeRange) => void;
  dropdownRenderProp: (close: () => void) => React.ReactNode;
}> = ({ setTimeRange, handleApply, dropdownRenderProp }) => {
  const rangeOptions = {
    'This Week': {
      start: DateTime.now().startOf('week').minus({ days: 1 }),
      end: DateTime.now().startOf('day'),
    },
    'Last Week': {
      start: DateTime.now().startOf('week').minus({ weeks: 1, days: 1 }),
      end: DateTime.now()
        .endOf('week')
        .startOf('day')
        .minus({ weeks: 1, days: 1 }),
    },
    'This Month': {
      start: DateTime.now().startOf('month'),
      end: DateTime.now().startOf('day'),
    },
    'Last Month': {
      start: DateTime.now().minus({ months: 1 }).startOf('month'),
      end: DateTime.now().minus({ months: 1 }).endOf('month').startOf('day'),
    },
    'This Year': {
      start: DateTime.now().startOf('year'),
      end: DateTime.now().startOf('day'),
    },
    'Last Year': {
      start: DateTime.now().minus({ years: 1 }).startOf('year'),
      end: DateTime.now().minus({ years: 1 }).endOf('year').startOf('day'),
    },
    'All Time': {
      start: DateTime.now().minus({ years: 2 }).startOf('year'),
      end: DateTime.now().startOf('day'),
    },
  };

  const handleClick = (newTimeRange: DatePickerTimeRange) => {
    setTimeRange(newTimeRange);
    handleApply(() => {}, newTimeRange);
  };

  return (
    <Box className={cx(styles.relativeRangeDropdown, styles.dropdown)}>
      <FlexItem start column>
        {Object.entries(rangeOptions).map(([key, value]) => (
          <Button
            alignContent="left"
            key={key}
            className={styles.relativeRangeButton}
            label={key}
            onClick={() => handleClick(value)}
            borderless
            minimal
          />
        ))}
        <div className={styles.customRangeButton}>
          <ClickDropdown
            dropdownRenderProp={dropdownRenderProp}
            dropdownClassName={styles.customRangeDropdown}
          >
            <Button
              alignContent="left"
              className={cx(styles.relativeRangeButton)}
              label={
                <Flex center>
                  <Flex start>
                    <span>Custom date range</span>
                    <SVGIcon
                      className={styles.customRangeIcon}
                      name="Calendar"
                      size={15}
                    />
                  </Flex>
                  <Flex end>
                    <ChevronRight className={styles.customRangeIcon} />
                  </Flex>
                </Flex>
              }
              borderless
              minimal
            />
          </ClickDropdown>
        </div>
      </FlexItem>
    </Box>
  );
};
