import React from 'react';
import cx from 'classnames';
import { Container, Icon } from '@socialchorus/shared-ui-components';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import styles from './VariantItem.module.css';
import { VariantDeleteModal } from './VariantDeleteModal';
import { VariantModal } from './VariantModal';

type VariantItemProps = {
  variant?: DynamicBlockVariant;
  description?: string;
  selected?: boolean;
  onSelect?: (variant?: DynamicBlockVariant) => void;
  onDelete?: (variant?: DynamicBlockVariant) => void;
  onDrop?: (sourceId: string, targetId: string) => void;
  onUpdate?: (variant: DynamicBlockVariant) => void;
};

const ItemTypes = {
  VARIANT: 'variant',
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const VariantItem: React.FC<VariantItemProps> = (props) => {
  const {
    variant,
    description,
    selected,
    onSelect,
    onDelete,
    onDrop,
    onUpdate,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const handle = React.useRef<HTMLDivElement>(null);

  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const isDefault = !onSelect;

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.VARIANT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current || !variant) {
        return;
      }

      const dragId = item.id;
      const hoverId = variant.uuid;

      // Don't replace items with themselves
      if (dragId === hoverId) {
        return;
      }

      // Determine draggable bounding box and intersections
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (item.index < variant.order && hoverClientY < hoverMiddleY) {
        return;
      }
      if (item.index > variant.order && hoverClientY > hoverMiddleY) {
        return;
      }

      // debounce drop event to prevent invariant failure due to non-existent target
      setTimeout(() => {
        if (onDrop) {
          onDrop(dragId, hoverId);
        }
      }, 10);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.VARIANT,
    item: () => {
      if (!variant) {
        return {};
      }

      return {
        index: variant.order || 0,
        id: variant.uuid || '',
        type: ItemTypes.VARIANT,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // connect reference to drap and drop providers
  drop(preview(ref));
  drag(handle);
  return (
    <>
      <Container
        ref={ref}
        className={cx(styles.variantContainer, {
          [styles.variantSelectable]: !isDefault,
          [styles.variantSelected]: selected,
          [styles.dragging]: isDragging,
        })}
        fullWidth
        onClick={() => onSelect?.(variant)}
      >
        <Flex className={styles.variantRow}>
          {!isDefault && (
            <FlexItem start>
              <Icon ref={handle} className={styles.handle}>
                drag_indicator
              </Icon>
            </FlexItem>
          )}
          <Flex
            column
            className={cx(styles.variantContent, {
              [styles.variantDefault]: isDefault,
            })}
          >
            <FlexItem className="text-caption-bold">{variant?.name}</FlexItem>
            {!isDefault && (
              <Flex>
                <FlexItem>
                  <Icon className={styles.audienceIcon}>group</Icon>
                </FlexItem>
                <FlexItem className={cx('text-caption', styles.variantCount)}>
                  {variant?.totalUsers ?? 0}
                </FlexItem>
              </Flex>
            )}
            {!!description && (
              <FlexItem className="text-caption">{description}</FlexItem>
            )}
          </Flex>
          {!isDefault && (
            <FlexItem end widen>
              <Icon
                className={styles.action}
                onClick={() => {
                  setIsModalOpened(true);
                }}
              >
                settings
              </Icon>
              <Icon
                className={styles.action}
                onClick={() => {
                  setIsDeleting(true);
                }}
              >
                delete
              </Icon>
            </FlexItem>
          )}
        </Flex>
      </Container>

      {isModalOpened && !!variant && (
        <VariantModal
          variant={variant}
          onCancel={() => {
            setIsModalOpened(false);
          }}
          onSubmit={(updatedVariant) => {
            onUpdate?.(updatedVariant);
            setIsModalOpened(false);
          }}
        />
      )}

      {isDeleting && (
        <VariantDeleteModal
          onCancel={() => setIsDeleting(false)}
          onDelete={() => {
            setIsDeleting(false);
            if (onDelete) {
              onDelete(variant);
            }
          }}
        />
      )}
    </>
  );
};
