import { ContributorTopicType } from 'models/topic';
import { LitmusRole, Role } from './role';

export type AdminPreferences = {
  hideWeekend: boolean;
  accessibilityColors: boolean;
};

export const DefaultAdminPreferences: AdminPreferences = {
  hideWeekend: true,
  accessibilityColors: false,
};

export type User = {
  accessibleBrands: Array<{ id: number; name: string }>;
  accessiblePrograms: Array<{
    id: number;
    name: string;
    brandId: number;
  }>;
  accessibleRegions: Array<{
    current: boolean;
    name: string;
    newStudioUrl: string;
  }>;
  admin_permission?: string;
  adminPreferences?: AdminPreferences;
  customAttributes?: Array<{
    name: string;
    value: string | Array<string>;
  }>;
  firstName?: string;
  id: number;
  lastName?: string;
  name?: string;
  email?: string;
  secondaryEmail?: string;
  username?: string;
  avatarUrl?: string;
  displayName?: string;
  role?: Role;
  roles?: Array<LitmusRole>;
  roleNames?: Array<string>;
  department?: string;
  workLocation?: string;
  jobTitle?: string;
  preferredName?: string;
  federatedIdentifier?: string;
  employeeId?: string;
  superAdmin?: boolean;
  activity90Day?: string;
  channel90Day?: string;
  lastVisitedAt?: string;
  locale?: string;
  city?: string;
  state?: string;
  status?: string;
  topic?: string;
  hidden?: boolean;
  membershipCreatedAt?: string;
  channels?: Array<{
    channel_id: number;
    name: string;
  }>;
  responses?: Array<{
    answer_id: number | undefined;
    answer_text: string | undefined;
    created_at: string | undefined;
    question_id: number | undefined;
    question_name: string | undefined;
    question_text: string | undefined;
    question_title: string | undefined;
    updated_at: string | undefined;
  }>;
  contributorChannels?: ContributorTopicType[];
};

export const fullName = ({
  firstName,
  lastName,
}: Pick<User, 'firstName' | 'lastName'>): string => `${firstName} ${lastName}`;

export const setFullName = (user: User, name: string): User => {
  const parts = name.split(' ');
  return { ...user, firstName: parts[0], lastName: parts[1] || '' };
};

export const defaultProgramId = (
  { accessiblePrograms }: User,
  brandId?: number
): number | undefined => {
  // if this method get called with brandId
  // this mean we need access to the default program inside this branch
  if (brandId) {
    return accessiblePrograms?.find((p) => p.brandId === brandId)?.id;
  }
  // if there is no brandId, but we have the currentProgram in the session
  // this should be the default programId
  const currentProgram = sessionStorage.getItem('currentProgram');
  if (currentProgram !== null) {
    return Number(currentProgram);
  }
  // don't have brandId param or currentProgram in the session, return first program
  return accessiblePrograms[0]?.id;
};
