import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from '@reach/router';
import { DateTime } from 'luxon';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
} from 'hooks/feature-flags';
import { usePublisherValidator } from 'hooks/validators';
import { isScheduled } from 'models/publisher/post';
import { useFlashMessage } from 'contexts/flasher';
import { useProductVocabulary } from 'hooks/useProductVocabulary';
import { titleCase } from 'utility/text';
import { isProcessing, isTopicOnly } from 'models/content';
import { useStateMutation } from 'hooks/state-mutator';
import { useHistory } from 'contexts/history';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Footer } from 'App/Program/Editors/Publisher/Footer';
import { SplitFooter } from 'App/Program/Editors/Publisher/Footer/SplitFooter';
import { useFlashServerErrors } from 'utility/errors';
import { Link } from 'hooks/useFooter';
import { usePublishConfirmed } from 'hooks/usePublishConfirmed';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import {
  CampaignProcessingMessage,
  ExperienceLinkMessage,
} from '../FlashMessages';
import { ConfirmationModal } from './ConfirmationModal';
import { useLeftMenuItems } from './LeftMenuItems';
import { useRightMenuItems } from './RightMenuItems';

// pretty basic, should cover more cases before being moved to a more general file
function pastTensify(value: string): string {
  const lowercaseValue = value.toLowerCase();
  if (lowercaseValue.endsWith('ed')) return value;
  if (lowercaseValue.endsWith('e')) return `${value}d`;
  if (lowercaseValue.endsWith('ing')) return value.replace(/ing$/, 'ed');

  return `${value}ed`;
}

export const PublisherFooter: React.FC<{
  nextPage?: string;
  validateDelivery?: () => boolean;
  disabledLinks?: string[];
}> = ({ nextPage, validateDelivery, disabledLinks }) => {
  const emailOnly = !!useFeatureFlag('emailOnly');
  const { id: programId } = useProgram();
  const { data: featureFlags } = useProgramCustomizationsQuery(programId);
  const {
    id: publisherId,
    save,
    cancelAutosave,
    post,
    status,
  } = usePublisher();
  const navigate = useNavigate();
  const history = useHistory();
  const { setFlashMessage } = useFlashMessage();
  const flashServerErrors = useFlashServerErrors();
  const { settings, callToAction, blocks } = post;
  const validator = usePublisherValidator();
  const { isValid } = validator.validate({
    featureFlags,
    settings: { ...settings, isSizeLimitExceeded: status.isSizeLimitExceeded },
    callToAction,
    blocks,
  });
  const {
    menuItems: leftMenuItems,
    isDuplicating,
    showNotesModal,
    NotesModal,
    showCampaignHistoryModal,
    CampaignHistoryModal,
  } = useLeftMenuItems();
  const { contentPermissions } = useSettings();

  const { menuItems: rightMenuItems } = useRightMenuItems();

  const { canEdit, canPublish } = contentPermissions;
  const saveDropdownDisabledMessage = !canEdit
    ? "You don't have permission to save/publish content"
    : undefined;

  const backToCampaignList = React.useCallback(() => {
    navigate(history.previous(['/app/content']));
  }, [history, navigate]);
  const exitHandler = React.useCallback(() => {
    let shouldExit = true;
    if (
      status.lastModified &&
      status.lastSaveSuccess &&
      status.lastModified > status.lastSaveSuccess
    ) {
      // eslint-disable-next-line no-alert
      shouldExit = window.confirm(
        'This post has unsaved changes. Are you sure that you want to exit?'
      );
    }

    if (shouldExit) backToCampaignList();
  }, [status, backToCampaignList]);

  const fullPath = React.useCallback(
    (page: string) => {
      return `/${programId}/edit/publisher/${publisherId}/${page}`;
    },
    [programId, publisherId]
  );

  const actionName = useMemo(() => {
    if (
      post.content.publicationState === 'draft' &&
      post.settings.publishedAt &&
      post.settings.publishedAt > DateTime.now()
    )
      return 'Schedule';

    if (post.content.publicationState === 'draft') return 'Publish';

    return 'Update';
  }, [post.content.publicationState, post.settings.publishedAt]);

  const successFlashMessage = useMemo(() => {
    if (post.content.permalinkUrl && !isScheduled(post.settings)) {
      if (isProcessing(post.content))
        return (
          <CampaignProcessingMessage
            action={pastTensify(actionName).toLowerCase()}
          />
        );

      return <ExperienceLinkMessage permalinkUrl={post.content.permalinkUrl} />;
    }

    return undefined;
  }, [post.content, post.settings, actionName]);

  const flashMessageText = useMemo(() => {
    return `Campaign ${
      isProcessing(post.content) ? 'Processing' : pastTensify(actionName)
    }`;
  }, [post.content, actionName]);

  const { mutate: mutateDraft } = useStateMutation({
    action: 'draft',
    shouldUpsert: true,
    onSuccess: () => {
      setFlashMessage({
        timeout: 5000,
        severity: 'info',
        message: 'moved to draft',
        children: !emailOnly ? successFlashMessage : undefined,
      });
    },
  });

  const saveAsDraft = React.useCallback(() => {
    mutateDraft({ post, programId });
  }, [mutateDraft, post, programId]);

  const { mutate: publish, isSaving } = useStateMutation({
    action: 'publish',
    shouldUpsert: true,
    onSuccess: () => {
      setFlashMessage({
        timeout: 5000,
        severity: 'info',
        message: flashMessageText,
        children: !emailOnly ? successFlashMessage : undefined,
      });
      backToCampaignList();
    },
    onError: (e) => {
      flashServerErrors(e, 'There was an error publishing.');
    },
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { publishConfirmed, usersCount } = usePublishConfirmed();

  const isLinkDisabled = useCallback(
    (linkAliases: string[]) => {
      if (!disabledLinks) {
        return false;
      }

      return linkAliases.some((link) => disabledLinks.includes(link));
    },
    [disabledLinks]
  );

  const postIsSaving =
    status.isSaving ||
    isSaving ||
    isDuplicating ||
    status.dynamicBlockStatus?.isSaving;
  const canPerformAction =
    isValid && !postIsSaving && canPublish && canEdit && !!post.content.id;

  const publishPost = React.useMemo(() => {
    return {
      ...post,
      settings,
    };
  }, [post, settings]);

  const saveAndClose = React.useCallback(() => {
    if (
      ['published', 'archived'].includes(publishPost.content.publicationState)
    ) {
      save({
        post: publishPost,
        onSuccess: () => {
          setFlashMessage({
            timeout: 5000,
            severity: 'info',
            message: flashMessageText,
            children: !emailOnly ? successFlashMessage : undefined,
          });
          backToCampaignList();
        },
        onError: (e) => {
          flashServerErrors(
            e,
            `There was an error ${
              publishPost.content.id !== 0 ? 'updating' : 'saving'
            }.`
          );
        },
      });
    } else {
      cancelAutosave();
      publish({ programId, post: publishPost });
    }
  }, [
    cancelAutosave,
    publishPost,
    programId,
    backToCampaignList,
    save,
    setFlashMessage,
    successFlashMessage,
    flashMessageText,
    flashServerErrors,
    publish,
    emailOnly,
  ]);

  const saveAndCloseConfirmed = () => {
    if (publishConfirmed) {
      setShowConfirmationModal(true);
    } else {
      saveAndClose();
    }
  };

  const saveConfirmed = () => {
    if (publishConfirmed) {
      setShowConfirmationModal(true);
    } else {
      save();
    }
  };

  const confirmationModalAction = React.useCallback(() => {
    if (['published', 'archived'].includes(post.content.publicationState)) {
      setShowConfirmationModal(false);
      save();
    } else {
      saveAndClose();
    }
  }, [post, save, saveAndClose]);

  const title = React.useMemo(() => {
    if (post.callToAction.title) {
      if (post.callToAction.title.length >= 55) {
        return `${post.callToAction.title.substring(0, 55)}...`;
      }
      return post.callToAction.title;
    }
    return 'Untitled';
  }, [post]);

  const { PAGES } = useProductVocabulary();

  const newPublisherFlagData = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.NewEditors'
  ).data;
  const usingNewPublisher = !!newPublisherFlagData?.value;

  const links = React.useMemo(
    () =>
      [
        {
          name: titleCase(PAGES.PUBLISHER.COMPOSE),

          href: fullPath('compose'),
          disabled: isLinkDisabled(['compose', 'design']),
        },
        {
          name: titleCase(PAGES.PUBLISHER.ORCHESTRATE),

          href: fullPath('deliver'),
          disabled: isLinkDisabled(['deliver', 'orchestrate']),
        },
        {
          name: titleCase(PAGES.PUBLISHER.REVIEW),
          href: fullPath('review'),
          validateClick: validateDelivery,
          disabled: isLinkDisabled(['review']),
        },
      ] as Link[],
    [
      PAGES.PUBLISHER.COMPOSE,
      PAGES.PUBLISHER.ORCHESTRATE,
      PAGES.PUBLISHER.REVIEW,
      fullPath,
      validateDelivery,
      isLinkDisabled,
    ]
  );

  // This could be better:
  // Next Page should reference a path fragment, not a label in a link.
  //
  // This hook is responsible for creating the list of links.
  // The components that use this tell it where to go next with a prop,
  // even though those are not responsible for creating the list itself.
  //
  // For now, this tries to adjust the next page based on the vocabulary.
  let nextPageLabel = nextPage;
  if (nextPage === 'compose') nextPageLabel = PAGES.PUBLISHER.COMPOSE;
  if (nextPage === 'orchestrate') nextPageLabel = PAGES.PUBLISHER.ORCHESTRATE;
  if (nextPage === 'review') nextPageLabel = PAGES.PUBLISHER.REVIEW;

  const saveMenuAction = ['scheduled', 'published'].includes(
    post.content.publicationState
  )
    ? saveConfirmed
    : (save as <T>(arg: T) => void);

  const topicOnly = isTopicOnly(post.content);
  const newProps: React.ComponentProps<typeof SplitFooter> = {
    action: saveAndCloseConfirmed,
    actionName,
    canPerformAction,
    exitHandler,
    isWorking: postIsSaving,
    leftMenuItems: {
      section1: leftMenuItems.section1,
      section2: rightMenuItems.section1,
      section3: leftMenuItems.section2,
    },
    links,
    nextPage: nextPageLabel,
    rightMenuAction: saveMenuAction,
    saveDropdownDisabledMessage,
    status: post.content.publicationState,
    title,
    disableSchedule: !canEdit,
    mutateDraftItem: saveAsDraft,
    topicOnly,
  };

  // Wait until we know which footer to render
  if (!newPublisherFlagData) return null;

  return (
    <>
      {usingNewPublisher && React.createElement(SplitFooter, newProps)}
      {!usingNewPublisher &&
        React.createElement(Footer, { ...newProps, validateDelivery })}
      {showNotesModal && <NotesModal />}
      {showCampaignHistoryModal && <CampaignHistoryModal />}
      {showConfirmationModal && (
        <ConfirmationModal
          usersCount={usersCount}
          submit={confirmationModalAction}
          toggle={setShowConfirmationModal}
          actionName={actionName}
        />
      )}
    </>
  );
};
