import React from 'react';
import cx from 'classnames';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { DateTime } from 'luxon';
import { Comment } from 'services/api-content';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Button } from 'shared/Button';

import {
  useDeleteCommentMutation,
  useClearFlagsMutation,
} from 'hooks/content/comment-actions';
import { ParentCommentView } from './ParentCommentView';

import styles from './comments-modal-v2.module.css';

type CommentItemProps = {
  comment: Comment;
  isFiltered: boolean;
  commentAttachmentsEnabled: boolean;
};

const formatDate = (d: string | undefined) => {
  return d ? DateTime.fromISO(d) : undefined;
};

export const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  isFiltered,
  commentAttachmentsEnabled,
}) => {
  const {
    author,
    rawContent,
    isReported,
    replyReportedCount,
    replyDeletedCount,
    createdAt,
    deletedAt,
    parentComment,
  } = comment;

  const {
    mutate: deleteComment,
    isLoading: isDeleting,
  } = useDeleteCommentMutation();
  const {
    mutate: deleteFlags,
    isLoading: isDeletingFlag,
  } = useClearFlagsMutation();

  const dateCreated = formatDate(createdAt)?.toFormat('hh:mma MM/dd/yyyy');
  const getActionLabel = () => {
    let actionLabel = '';

    if (isReported) {
      actionLabel = 'Flagged, ';
    }

    if (deletedAt) {
      actionLabel += 'Deleted, ';
    }

    if (!isFiltered && (replyReportedCount || replyDeletedCount)) {
      if (replyReportedCount) {
        const beginning =
          replyReportedCount > 1 ? `${replyReportedCount} Flagged` : 'Flagged';
        actionLabel += `${beginning} ${
          replyReportedCount > 1 ? 'replies' : 'reply'
        }, `;
      }

      if (replyDeletedCount) {
        const beginning =
          replyDeletedCount > 1 ? `${replyDeletedCount} Deleted` : 'Deleted';
        actionLabel += `${beginning} ${
          replyDeletedCount > 1 ? 'replies' : 'reply'
        }, `;
      }
    }

    if (actionLabel) {
      actionLabel = actionLabel.slice(0, -2);
    }

    return actionLabel;
  };

  const actionsLabel = getActionLabel();

  return (
    <div className={styles.commentBox}>
      <Flex start className={cx(styles.container)}>
        <div className={styles.avatarBox}>
          {author.avatar.url ? (
            <img
              src={author.avatar.url}
              alt="author"
              className={styles.avatar}
            />
          ) : (
            <Flex className={styles.fallbackAvatar}>
              {author.name[0]?.toUpperCase()}
            </Flex>
          )}
        </div>
        <div className={styles.commentItemContainer}>
          <Flex start className={styles.commentHeader}>
            <p className={styles.authorName}>{author.name}</p>
            <p className={styles.createdAt}>{dateCreated}</p>
            <p className={styles.actionAgainst}>{actionsLabel}</p>
          </Flex>
          <p>{rawContent}</p>
          {commentAttachmentsEnabled && comment.attachments.length > 0 && (
            <div className={styles.attachments}>
              {comment.attachments.map((attachment) => (
                <button type="button" key={attachment.url}>
                  <img
                    src={attachment.url}
                    alt={attachment.altText}
                    className={styles.image}
                  />
                </button>
              ))}
            </div>
          )}
          {isFiltered && parentComment && (
            <ParentCommentView comment={parentComment} />
          )}
          <Flex
            start
            className={cx(styles.actionButtons, {
              [styles.hide]: deletedAt,
            })}
          >
            <Button
              compact
              type="primary"
              onClick={() => deleteComment(comment)}
            >
              <Flex>
                {isDeleting ? (
                  <Flex>
                    <LoadingSpinner size="small" />
                    <Space />
                  </Flex>
                ) : null}
                <p>{isDeleting ? 'Deleting' : 'Delete'}</p>
              </Flex>
            </Button>
            <div className={styles.deleteButton}>
              {isReported ? (
                <Button
                  compact
                  type="primary"
                  onClick={() => deleteFlags(comment)}
                >
                  <Flex>
                    {isDeletingFlag ? (
                      <Flex>
                        <LoadingSpinner size="small" />
                        <Space />
                      </Flex>
                    ) : null}
                    <p>Remove flag</p>
                  </Flex>
                </Button>
              ) : null}
            </div>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};
