import { RouteComponentProps, useNavigate } from '@reach/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { AudienceQuery, useAudienceByIdQueries } from 'hooks/audience';
import {
  useBoxFolderByIdQuery,
  useBulkCreateBoxFolderAudienceMapping,
  useBulkDeleteBoxFolderAudienceMapping,
  useUpdateBoxFolder,
} from 'hooks/box-knowledge-mangement';
import {
  useCurrentUserAudiencesQuery,
  useCurrentUserMembershipsQuery,
} from 'hooks/user';
import { Audience } from 'models/audience';
import { AudienceReadData } from 'services/api-audiences';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MenuItemsType } from 'shared/BannerListItem';
import { Modal } from 'shared/Modal';
import { BoxFolderForm, BoxFolderFormContext } from '../shared/Form/Folder';
import styles from './styles.module.css';
import { useActions } from '../useActions';

export const EditBoxFolder: React.FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const {
    isLoading: isGettingFolderLoading,
    data: selectedFolder,
  } = useBoxFolderByIdQuery(programId, id as number);

  const { data: currentUserMemberships } = useCurrentUserMembershipsQuery();
  const { data: currentUserAudiences } = useCurrentUserAudiencesQuery();

  const audienceIds = useMemo(
    () => selectedFolder?.audiences.map((a) => a.audienceId) || [],
    [selectedFolder]
  );

  const audienceQueries = useAudienceByIdQueries(programId, audienceIds);

  const audiencesLoading = useMemo(
    () => audienceQueries.some(({ isLoading }) => isLoading),
    [audienceQueries]
  );

  const {
    isLoading: isUpdatingBoxFolder,
    updateAsync: updateBoxFolder,
  } = useUpdateBoxFolder();

  const {
    bulkCreateAsync: bulkCreateFolderAudienceMapping,
    isLoading: isCreatingFolderAudienceMapping,
  } = useBulkCreateBoxFolderAudienceMapping();

  const {
    bulkDeleteAsync: bulkDeleteFolderAudienceMapping,
    isLoading: isDeletingFolderAudienceMapping,
  } = useBulkDeleteBoxFolderAudienceMapping();

  const { setFlashMessage } = useFlashMessage();

  const [name, setName] = useState(selectedFolder?.name || '');
  const [description, setDescription] = useState(
    selectedFolder?.description || ''
  );
  const [audiences, setAudiences] = useState<Audience[]>([]);

  useEffect(() => {
    if (selectedFolder) {
      setName(selectedFolder.name || '');
      setDescription(selectedFolder.description || '');
    }
  }, [selectedFolder]);

  const [audienceData, hiddenAudienceIds] = useMemo(
    () => {
      const data = audienceQueries
        .filter(
          (audience): audience is Required<AudienceQuery> => !!audience.data
        )
        .map((audience) => audience.data);

      const accessibleAudienceIds = new Set(data.map((aud) => aud.id));

      const hiddenIds = audienceIds.filter(
        (audId) => !accessibleAudienceIds.has(audId)
      );

      return [data, hiddenIds];
    },
    // eslint-disable-next-line
    [JSON.stringify(audienceQueries)]
  );

  useEffect(() => {
    setAudiences(audienceData);
  }, [audienceData]);

  const onSave = useCallback(async () => {
    if (
      !name ||
      (!audiences.length && !hiddenAudienceIds.length) ||
      !selectedFolder
    ) {
      return;
    }

    await updateBoxFolder({
      programId,
      boxFolderId: selectedFolder.boxFolderId,
      name,
      description,
    });

    const selectedAudienceIds = audiences
      .filter((aud): aud is Required<Audience> => !!aud.id)
      .map((aud) => aud.id)
      .concat(hiddenAudienceIds);

    const audienceIdsToAdd = selectedAudienceIds.filter(
      (audId) => !audienceIds.includes(audId)
    );

    const currentUserMembershipIds = (currentUserMemberships || []).map((aud) =>
      String(aud.groupId)
    );
    const currentUserAudienceIds = (currentUserAudiences || [])
      .filter((aud): aud is Required<AudienceReadData> => !!aud.numericId)
      .map((aud) => String(aud.numericId));

    const currentCombinedAudienceIds = new Set(
      currentUserMembershipIds.concat(currentUserAudienceIds)
    );

    let audienceIdsToRemove = audienceIds.filter(
      (audId) => !selectedAudienceIds.includes(audId)
    );

    const currentMemberAudienceIdsToRemove: string[] = [];

    if (audienceIdsToAdd.length > 0) {
      try {
        await bulkCreateFolderAudienceMapping({
          programId,
          folderId: Number(selectedFolder.boxFolderId),
          audienceIds: audienceIdsToAdd,
        });
      } catch (err) {
        setFlashMessage({
          severity: 'error',
          message:
            'There was an error creating the folder audiences. Please try again.',
        });
        throw err;
      }
    }

    if (audienceIdsToRemove.length > 0) {
      // if the current user is a member of an audience that is being removed, remove it from audience
      if (currentCombinedAudienceIds.size > 0) {
        audienceIdsToRemove.forEach((aud) => {
          if (currentCombinedAudienceIds.has(aud)) {
            currentMemberAudienceIdsToRemove.push(aud);
          }
        });
        audienceIdsToRemove = audienceIdsToRemove.filter(
          (audId) => !currentMemberAudienceIdsToRemove.includes(audId)
        );
      }

      try {
        const resp = await bulkDeleteFolderAudienceMapping({
          programId,
          folderId: Number(selectedFolder.boxFolderId),
          audienceIds: audienceIdsToRemove,
        });
        if (resp && currentMemberAudienceIdsToRemove.length > 0) {
          await bulkDeleteFolderAudienceMapping({
            programId,
            folderId: Number(selectedFolder.boxFolderId),
            audienceIds: currentMemberAudienceIdsToRemove,
          });
        }
      } catch (err) {
        setFlashMessage({
          severity: 'error',
          message:
            'There was an error deleting the folder audiences. Please try again.',
        });
        throw err;
      }
    }

    await navigate('../..');
  }, [
    name,
    audiences,
    selectedFolder,
    updateBoxFolder,
    programId,
    description,
    hiddenAudienceIds,
    audienceIds,
    navigate,
    bulkCreateFolderAudienceMapping,
    setFlashMessage,
    currentUserMemberships,
    currentUserAudiences,
    bulkDeleteFolderAudienceMapping,
  ]);
  const {
    deleteBoxFolderMapping: { deleteAction, isLoading: deleteIsLoading },
  } = useActions();
  const [showingDeleteModal, setShowDeleteModal] = React.useState(false);
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onDelete = useCallback(async () => {
    try {
      await deleteAction(Number(selectedFolder?.boxFolderId));
      setFlashMessage({
        severity: 'info',
        message: 'Folder deleted successfully',
      });
      await navigate('../..');
    } catch (err) {
      setFlashMessage({
        severity: 'error',
        message: 'There was an error deleting the folder. Please try again.',
      });
      throw err;
    }
  }, [deleteAction, navigate, selectedFolder, setFlashMessage]);

  const menuItems: MenuItemsType = [
    {
      title: 'Delete',
      danger: true,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setShowDeleteModal(true);
      },
    },
  ];

  return (
    <>
      {showingDeleteModal && (
        <DeleteModal
          showModal={showingDeleteModal}
          title="Delete Folder"
          deleteAction={selectedFolder ? onDelete : () => {}}
          closeDeleteModal={closeDeleteModal}
        />
      )}
      <FormPage
        breadcrumbs={[
          { to: '../../../..', label: 'Configure' },
          { to: '../../..', label: 'Box Knowledge Management' },
          { label: 'Edit Folder' },
        ]}
        actions={[
          {
            label: 'Save',
            disabled:
              !name ||
              (!audiences.length && !hiddenAudienceIds.length) ||
              !selectedFolder,
            isLoading:
              isUpdatingBoxFolder ||
              isCreatingFolderAudienceMapping ||
              isDeletingFolderAudienceMapping,
            onClick: onSave,
          },
        ]}
        menuItems={menuItems}
        title="Edit Folder"
      >
        {isGettingFolderLoading || audiencesLoading || deleteIsLoading ? (
          <div className={styles.Loading}>
            <LoadingSpinner />
          </div>
        ) : (
          <BoxFolderFormContext.Provider
            value={{
              name,
              description,
              audiences,
              hiddenAudienceIds,
              savedBoxFolderName: selectedFolder?.boxFolderName,
              onNameChange: setName,
              onDescriptionChange: setDescription,
              onAudiencesChange: setAudiences,
              onBoxFolderChange: () => {},
            }}
          >
            <BoxFolderForm />
          </BoxFolderFormContext.Provider>
        )}
      </FormPage>
    </>
  );
};

type DeleteModalProps = {
  showModal: boolean;
  title: string;
  deleteAction: () => void;
  closeDeleteModal: () => void;
};

const DeleteModal: React.FC<DeleteModalProps> = ({
  showModal,
  title,
  deleteAction,
  closeDeleteModal,
}) => {
  return (
    <Modal
      showModal={showModal}
      title={title}
      showTitle
      onClose={closeDeleteModal}
      identifier="deleteModal"
    >
      <div className={styles.deleteWarning}>
        Are you sure you want to delete this mapped folder?
      </div>
      <div className={styles.actionWrapper}>
        <button
          className={styles.actionButton}
          type="button"
          onClick={closeDeleteModal}
        >
          Cancel
        </button>
        <button
          className={styles.actionButton}
          type="button"
          onClick={() => {
            closeDeleteModal();
            deleteAction();
          }}
        >
          Confirm Delete
        </button>
      </div>
    </Modal>
  );
};
