import React from 'react';
import { useQueryClient } from 'react-query';
import { LitmusRole } from 'models/role';
import { User } from 'models/user';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { NavigationFooter } from 'shared/NavigationFooter';
import { useUpdateUser } from 'hooks/user';
import { useUpdateTopic } from 'hooks/topics';
import { Topic } from 'models/topic';
import { useUpsertUserIdentity } from 'hooks/identities';
import { IdentityScope } from 'models/identity';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useRoleQuery } from 'hooks/roles';
import { useUser } from 'contexts/user';

export const UserViewFooter: React.FC<{
  data: User;
  pristineUserData: User;
  selectedTopics: Topic[];
  identityScopes: IdentityScope[];
  canPerformAction?: boolean;
  permissionsChanged: boolean;
}> = ({
  data,
  pristineUserData,
  selectedTopics,
  identityScopes,
  canPerformAction,
  permissionsChanged,
}) => {
  const { id: currentUserId } = useUser();
  const { id: programId } = useProgram();
  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );
  const { data: roleData } = useRoleQuery(programId, data.role as string);

  const [pristineRole, setPristineRole] = React.useState('');
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (!pristineUserData.role) return;
    setPristineRole(pristineUserData?.role);
  }, [pristineUserData]);

  const { update: updateTopic } = useUpdateTopic(programId, {
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not update user topics',
      }),
  });

  const { upsert: upsertUserIdentity } = useUpsertUserIdentity({
    onSuccess: () => {
      if (Number(data.id) === Number(currentUserId)) {
        window.location.reload();
      } else {
        refreshQueries();
      }
    },
    onError: () =>
      setFlashMessage({
        severity: 'error',
        message: 'Could not update user identity',
      }),
  });

  const { update } = useUpdateUser(programId, {
    onSuccess: () => {
      if (showPermissionsUI && permissionsChanged) {
        updatePermissions();
      } else {
        refreshQueries();
      }
      setFlashMessage({
        severity: 'info',
        message: 'User updated successfully',
      });

      // if (Number(data.id) !== Number(currentUserId)) {
      //   // updates queries upon success
      //   refreshQueries();

      //   setFlashMessage({
      //     severity: 'info',
      //     message: 'User updated successfully',
      //   });
      // }
    },
    onError: () =>
      setFlashMessage({ severity: 'error', message: 'Could not update user' }),
  });

  const exitPath = `/${programId}/app/people/users`;
  const menuItems = {
    section1: [
      {
        element: <div>Menu?</div>,
        visible: true,
      },
    ],
  };

  const links = [
    {
      name: '',
      href: '.',
    },
  ];

  const roleWithNoRestrictions =
    data?.role !== 'member' &&
    !roleData?.aliases.some(({ name }) => name === 'no_restrictions');

  function validateUser(): boolean {
    if (!hasScopesSelected(identityScopes, ['audience', 'topic'])) {
      setFlashMessage({
        severity: 'error',
        message:
          'At least one topic OR audience restriction must be set for this user',
      });

      return false;
    }

    return true;
  }

  function validateEmail(): boolean {
    if (data.email === data.secondaryEmail) {
      setFlashMessage({
        severity: 'error',
        message:
          'Secondary email cannot be the same as the primary email. Please enter a different email',
      });

      return false;
    }

    return true;
  }

  const updatePermissions = () => {
    // set query manually to avoid delay in query update which caused "flickering" on rerender
    queryClient.setQueryData(`identity-${programId}-${data.id}`, {
      tenant: `program:${programId}`,
      name: `user:${data.id}`,
      roles: data.role ? [{ name: data.role }] : [],
      active: true,
      scopes: identityScopes,
    });

    upsertUserIdentity({
      tenant: `program:${programId}`,
      name: `user:${data.id}`,
      roles: data.role ? [{ name: data.role }] : [],
      active: true,
      scopes: identityScopes,
    });
  };

  const handleSave = () => {
    const dataToUpdate = { ...data };

    if (data.email && !validateEmail()) return;

    if (showPermissionsUI?.value) {
      if (roleWithNoRestrictions && !validateUser()) return;
      dataToUpdate.admin_permission = pristineRole;
    } else {
      dataToUpdate.admin_permission = data.role;
    }

    // set query manually to avoid delay in query update which caused "flickering" on rerender
    queryClient.setQueryData(`User-${data.id}`, dataToUpdate);

    update(Number(data.id), dataToUpdate);
    if (selectedTopics && selectedTopics.length) {
      selectedTopics.forEach((topic: Topic) => {
        updateTopic({ ...topic });
      });
    }
  };

  async function refreshQueries() {
    // updates queries that were manually set to avoid errors with data
    queryClient.refetchQueries(`identity-${programId}-${data.id}`);
    queryClient.refetchQueries(`User-${data.id}`);
  }

  return (
    <NavigationFooter
      links={links}
      canPerformAction={canPerformAction ?? true}
      actionName="Save"
      action={handleSave}
      exitPath={exitPath}
      leftMenuItems={menuItems}
      title={`${data.firstName} ${data.lastName}`}
      hideMenu
    />
  );
};

function hasUserRoles(
  data: User,
  roleData: LitmusRole | undefined,
  user_role: string[]
): boolean {
  return (
    data?.role !== 'member' &&
    (roleData?.aliases.some(({ name }) => user_role.includes(name)) || false)
  );
}

function hasScopesSelected(
  identityScopes: IdentityScope[],
  needed_topics: string[]
): boolean {
  return identityScopes.some(({ type }) => needed_topics.includes(type));
}

export const test = { hasUserRoles, hasScopesSelected };
