import {
  MutationStatus,
  useInfiniteQuery,
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  fetchJourneys,
  fetchNewJourney,
  QueryParameters,
  fetchJourney,
  FetchNewJourneyParams,
  FetchJourneyParams,
  UpdateJourneyParams,
  upsertJourney,
  DraftJourneyParams,
  copyLiveToDraft,
  cancelJourneyProcessing,
  archiveJourney,
  ArchiveJourneyParams,
  validateJourney,
  ValidateJourneyParams,
  duplicateJourney,
  JourneyActionParams,
} from 'services/api-journey';

import {
  Journey,
  JourneyListItem,
  JourneyState,
} from 'models/journeys/journey';
import { ParameterizedFilters } from 'models/journeys/filter';
import { InfiniteQueryResponse, QueryResponse } from 'hooks/common';
import {
  JourneyCollectionData,
  JourneyPaginationData,
} from 'services/serializers/journey';
import { ValidationError } from 'services/Errors/ValidationError';
import { maybe } from 'utility/objectUtils';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { JourneyErrors } from 'models/journeys/journey-errors';
import { useFeatureFlagsQuery } from '../feature-flags';

export const journeysKeys = {
  all: ['journeys'] as const, // ['journeys']
  page: () => [...journeysKeys.all, 'paged'] as const, // ['journeys', 'paged']
  paged: (queryParams: QueryParameters, filters: ParameterizedFilters) =>
    [...journeysKeys.page(), queryParams, filters] as const, // ['journeys', 'paged', {page: 1, size: 10}, {editingState: 'processing'}]
  details: () => [...journeysKeys.all, 'details'] as const, // ['journeys', 'details']
  detail: (id: number) => [...journeysKeys.details(), id] as const, // ['journeys', 'details', 1]
  new: (templateId: number) =>
    [...journeysKeys.details(), 'new', templateId] as const, // ['journeys', 'details', 'new', 1],
};

export type JourneyPage = {
  data: JourneyListItem[];
  meta?: JourneyPaginationData;
};

type UseUpsertJourneyMutation<
  TData = Journey,
  TError = Error | ValidationError,
  TVariables = UpdateJourneyParams,
  TContext = unknown
> = (props?: {
  onSuccess?: UseMutationOptions<
    TData,
    TError,
    TVariables,
    TContext
  >['onSuccess'];
  onError?: UseMutationOptions<TData, TError, TVariables, TContext>['onError'];
}) => {
  mutate: UseMutateFunction<TData, TError, TVariables, TContext>;
  isLoading: boolean;
};

type UseCopyLiveToDraftMutation = (props?: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  isLoading: boolean;
  mutateCopyLiveToDraft: UseMutateFunction<
    Journey,
    unknown,
    DraftJourneyParams
  >;
};

export function nextJourneyPageToFetch(
  props: JourneyPaginationData
): number | undefined {
  const { total, page } = props;
  if (total > page.number * page.size) {
    return Number(page.number) + 1;
  }
  return undefined;
}

export function useJourneysInfiniteQuery(
  queryParams: QueryParameters,
  parameterizedFilters: ParameterizedFilters,
  refetchInterval: number | false | undefined = false
): InfiniteQueryResponse<JourneyListItem, JourneyPaginationData> {
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<JourneyPage, Error>(
    [...journeysKeys.paged(queryParams, parameterizedFilters)],
    async ({ pageParam = 1 }) => {
      const resp = await fetchJourneys(
        {
          page: pageParam,
          ...queryParams,
        },
        parameterizedFilters
      );
      if (!resp) return { data: [] };
      return { data: resp.data, meta: resp.meta };
    },
    {
      refetchOnMount: 'always',
      refetchInterval,
      getNextPageParam(lastGroup) {
        return maybe(lastGroup?.meta, nextJourneyPageToFetch);
      },
    }
  );

  return {
    data: data?.pages.flatMap((page) => page.data) || [],
    meta: data?.pages[0].meta,
    errorMessage: error?.message,
    isLoading: isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useJourneys<TData = JourneyCollectionData>(
  state: JourneyState,
  {
    pageSize = 40,
    select,
  }: {
    select?: (data: JourneyCollectionData) => TData;
    pageSize?: number;
  } = {}
) {
  const { id: programId } = useProgram();
  const { data, isLoading, error } = useQuery(
    [...journeysKeys.details(), state],
    () => fetchJourneys({ programId, pageSize }, { editingState: state }),
    {
      retry: 1,
      select,
    }
  );

  return {
    data,
    isLoading,
    errorMessage: error instanceof Error ? error?.message : '',
  };
}

export const useJourneyQuery = (
  props: FetchJourneyParams
): QueryResponse<Journey> => {
  const { programId, journeyId } = props;
  const { data, isLoading, error, isFetching } = useQuery<Journey, Error>(
    [...journeysKeys.detail(journeyId)],
    () => fetchJourney({ programId, journeyId }),
    { retry: 1 }
  );

  return {
    data,
    isLoading: isLoading || isFetching,
    errorMessage: error?.message,
  };
};

export const useNewJourneyQuery = (
  props: FetchNewJourneyParams
): QueryResponse<Journey> => {
  const { programId, templateId } = props;
  const { data, isLoading, error } = useQuery<Journey, Error>(
    [...journeysKeys.new(templateId ?? 0)],
    () =>
      fetchNewJourney({
        programId,
        templateId,
      })
  );

  return {
    data,
    isLoading,
    errorMessage: error?.message,
  };
};

export const useUpsertJourneyMutation: UseUpsertJourneyMutation = ({
  onSuccess,
  onError,
} = {}) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(upsertJourney, {
    onSuccess: (...args) => {
      if (onSuccess) {
        onSuccess(...args);
      }
      queryClient.invalidateQueries([...journeysKeys.all]);
    },
    onError,
  });

  return { mutate, isLoading };
};

export const useCopyLiveToDraftMutation: UseCopyLiveToDraftMutation = ({
  onSuccess,
  onError,
} = {}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate: mutateCopyLiveToDraft } = useMutation(
    ['journey_draft'],
    (data: DraftJourneyParams) => copyLiveToDraft(data),
    {
      onSuccess: async (_data, variables, _context) => {
        const { journeyId } = variables;
        await queryClient.invalidateQueries([
          ...journeysKeys.detail(journeyId),
        ]);

        if (onSuccess) onSuccess();
      },
      onError,
    }
  );
  return {
    isLoading,
    mutateCopyLiveToDraft,
  };
};

export function useCancelJourneyProcessing(): {
  cancelJourneyProcessing: UseMutateFunction<
    unknown,
    unknown,
    NonNullable<Journey['id']>
  >;
  status: MutationStatus;
  error: unknown;
} {
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const { mutate, status, error } = useMutation(
    (id: NonNullable<Journey['id']>) =>
      cancelJourneyProcessing({ programId, journeyId: id }),
    {
      onSuccess: () => {
        setFlashMessage({
          message: 'Publishing has been cancelled.',
          severity: 'info',
        });
      },
      onSettled(data) {
        if (data) {
          queryClient.invalidateQueries([...journeysKeys.all]);
        }
      },
    }
  );

  return {
    cancelJourneyProcessing: mutate,
    status,
    error,
  };
}

export function useArchiveJourneyMutation(
  onSuccess?: () => void,
  onError?: () => void
): {
  isLoading: boolean;
  mutateArchive: UseMutateFunction<
    JourneyListItem,
    unknown,
    ArchiveJourneyParams
  >;
} {
  const queryClient = useQueryClient();
  const { isLoading, mutate: mutateArchive } = useMutation(
    ['journey_archive'],
    (data: ArchiveJourneyParams) => archiveJourney(data),
    {
      onSuccess,
      onError,
      onSettled: () => {
        queryClient.invalidateQueries([...journeysKeys.all]);
      },
    }
  );
  return {
    isLoading,
    mutateArchive,
  };
}

export function useAcknowledgementsEnabled(): boolean {
  const programId = useProgram().id;
  const enabled = Boolean(
    useFeatureFlagsQuery(programId, 'Journey.CommunicationStep.Acknowledgments')
      .data?.value
  );
  return enabled;
}

export const useValidateJourney = (
  props: ValidateJourneyParams
): { errors?: JourneyErrors; isLoading: boolean } => {
  const { programId, journey } = props;

  const { data: journeyErrors, isLoading } = useQuery(
    ['validate_journey', programId, journey?.id],
    () => validateJourney({ programId, journey }),
    {
      enabled:
        journey &&
        journey.id !== undefined &&
        journey.draftGraph?.executionState === 'verifying',
      select: (data) => data.errors,
    }
  );

  return {
    errors: journeyErrors,
    isLoading,
  };
};

export function useDuplicateJourneyMutation(): {
  isLoading: boolean;
  mutateDuplicate: UseMutateFunction<number, unknown, JourneyActionParams>;
} {
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();

  const { mutate, isLoading } = useMutation(
    async (data: { programId: number; journeyId: number }) =>
      duplicateJourney(data),
    {
      onSuccess: (newJourneyId, variables) => {
        queryClient.invalidateQueries([...journeysKeys.page()]);

        setFlashMessage({
          timeout: 15000,
          severity: 'info',
          message: 'Journey duplicated',
          navigateButton: {
            text: 'Open',
            path: `/${variables.programId}/app/journeys/${newJourneyId}/edit`,
          },
        });
      },
      onError: () => {
        setFlashMessage({
          timeout: 15000,
          severity: 'error',
          message:
            'There was an issue duplicating the journey. Please try again.',
        });
      },
    }
  );

  return {
    isLoading,
    mutateDuplicate: mutate,
  };
}
