import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {
  FlashMessageKeyType,
  FlashMessageType,
  NavigateButtonType,
} from 'models/flash-message';
import { Icon } from 'shared/Icon';
import { ProgressBar } from 'shared/ProgressBar';
import { Clock, SaveSuccess, WarningError } from '../icons';
import styles from './flasher.module.css';

type DismissRefType = React.RefObject<
  ({ message, messageKey }: FlashMessageKeyType) => void
>;

type UpdateRefType = React.RefObject<
  (
    current: FlashMessageKeyType,
    replacement: Omit<FlashMessageType, 'messageKey'>
  ) => void
>;

const NavigateButton: React.FC<{
  navigateButton: NavigateButtonType;
  dismissMessage: () => void;
}> = ({ navigateButton, dismissMessage }) => {
  const navigate = useNavigate();
  const handler = () => {
    navigate(navigateButton.path);
    dismissMessage();
  };

  return (
    <button onClick={handler} className={styles.navigateButton} type="button">
      {navigateButton.text}
    </button>
  );
};

export const FlashMessage: React.FC<{
  message: FlashMessageType;
  dismissRef: DismissRefType;
  updateRef: UpdateRefType;
}> = ({ message, dismissRef, updateRef }) => {
  const [cssShowing, setCssShowing] = useState(false); // for initial slide in
  const [
    progressIntervalId,
    setProgressIntervalId,
  ] = useState<NodeJS.Timeout | null>(null);
  const [hideTimeoutId, setHideTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [progressValue, setProgressValue] = useState(
    message.progress?.defaultProgress ?? 0
  );

  const dismissMessage = useCallback(() => {
    if (progressIntervalId != null) clearInterval(progressIntervalId);
    if (hideTimeoutId != null) clearTimeout(hideTimeoutId);
    setCssShowing(false);

    setTimeout(() => {
      if (dismissRef.current)
        dismissRef.current({
          messageKey: message.messageKey,
          message: message.message,
        });
    }, 300);
  }, [dismissRef, setCssShowing, progressIntervalId, hideTimeoutId, message]);
  const boundDismissRef = useRef(dismissMessage);
  boundDismissRef.current = dismissMessage;

  const updateProgress = useCallback(() => {
    if (progressIntervalId !== null && message.progress)
      message.progress.update((value, replacement) => {
        if (value >= (message.progress?.max || 100)) {
          if (progressIntervalId != null) clearInterval(progressIntervalId);
          if (hideTimeoutId != null) clearTimeout(hideTimeoutId);
          if (replacement && updateRef.current)
            updateRef.current(message, replacement);
        } else {
          setProgressValue(value);
        }
      });
  }, [updateRef, hideTimeoutId, progressIntervalId, setProgressValue, message]);
  const boundProgressRef = useRef(updateProgress);
  boundProgressRef.current = updateProgress;

  useEffect(() => {
    if (message.timeout !== false)
      setHideTimeoutId(
        setTimeout(() => boundDismissRef.current(), message.timeout || 5000)
      );
  }, [boundDismissRef, setHideTimeoutId, message.timeout]);

  useEffect(() => {
    if (message.progress) {
      boundProgressRef.current();
      setProgressIntervalId(
        setInterval(
          () => boundProgressRef.current(),
          message.progress.interval || 5000
        )
      );
    }
  }, [boundProgressRef, setProgressIntervalId, message.progress]);

  useEffect(() => setCssShowing(true), [setCssShowing]);

  return (
    <CSSTransition
      in={cssShowing}
      timeout={300}
      classNames={{
        enter: styles.slidingEnter,
        enterActive: styles.slidingEnterActive,
        enterDone: styles.slidingEnterDone,
        exit: styles.slidingExit,
        exitActive: styles.slidingExitActive,
        exitDone: styles.slidingExitDone,
      }}
      unmountOnExit
    >
      <div
        id="flash-message-wrapper"
        className={cx(styles.messageWrapper, styles[message.severity])}
      >
        <div className={styles.iconContainer}>
          {message.severity === 'info' && <SaveSuccess />}
          {message.severity === 'error' && <WarningError />}
          {message.severity === 'progress' && <Clock />}
        </div>
        <div className={styles.text}>
          <div className={styles.message}>
            {!Array.isArray(message.message) && message.message}
          </div>
          {message.navigateButton && (
            <NavigateButton
              navigateButton={message.navigateButton}
              dismissMessage={dismissMessage}
            />
          )}
          {message.details && (
            <div className={styles.details}>{message.details}</div>
          )}
          {message.children && !message.inlineChildren && (
            <div>{message.children}</div>
          )}
          {message.severity === 'progress' &&
            message.progress !== undefined && (
              <ProgressBar
                value={progressValue}
                max={message.progress?.max || 100}
                color="var(--color-greenFull)"
                size={8}
                orientation="horizontal"
              />
            )}
        </div>
        {message.children && message.inlineChildren && (
          <div className={styles.inlineChildren}>{message.children}</div>
        )}
        <div className={styles.buttonContainer}>
          <button type="button" onClick={dismissMessage}>
            <Icon iconName="Times" size={12} iconType="SVG" />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};
