import { RouteComponentProps } from '@reach/router';
import { Alert, Button } from '@socialchorus/shared-ui-components';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useState } from 'react';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import { FormSubsection, Section } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Body, color } from 'DesignSystem/Typography';
import {
  useMergeIntegrations,
  useUserMappingConfiguration,
} from 'hooks/merge-integration';
import { useLinkNavigation } from 'hooks/use-link-navigation';
import { getIntegrationLabel } from 'models/MergeIntegration/integrations';
import { clearUserMappings } from 'services/api-merge-integration';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MergeIntegrationContext } from '../context';
import { MergeLinkConfigurationButton } from './MergeLinkConfigurationButton';
import { UserMappingConfigPreview } from './UserMappingConfigPreview';
import styles from './styles.module.css';

export const ConfigureIntegration: React.FC<RouteComponentProps> = () => {
  const { type } = useContext(MergeIntegrationContext);

  const navigate = useLinkNavigation();
  const { permissions } = usePermissions();
  const { id: programId } = useProgram();

  const { data, isLoading } = useMergeIntegrations(type);
  const existingIntegration = data?.[0];

  const {
    data: userMappingConfig,
    isLoading: isLoadingUserMappingConfig,
  } = useUserMappingConfiguration(existingIntegration?.id);

  const [isClearingUserMappings, setIsClearingUserMappings] = useState(false);
  const [shouldClearUserMappings, setShouldClearUserMappings] = useState(false);

  const onClearUserMappings = useCallback(async () => {
    if (!existingIntegration) {
      return;
    }

    setIsClearingUserMappings(true);
    try {
      await clearUserMappings(programId, existingIntegration.id);
      setShouldClearUserMappings(false);
    } finally {
      setIsClearingUserMappings(false);
    }
  }, [existingIntegration, programId]);

  const actionInProgress = isClearingUserMappings;

  if (!type) {
    return null;
  }

  const integrationLabel = getIntegrationLabel(type);

  return (
    <FormPage
      title={integrationLabel}
      breadcrumbs={[
        { to: '..', label: 'Configure' },
        { label: integrationLabel },
      ]}
    >
      <div className={styles.SectionsContainer}>
        <Section title="Link Account">
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            (existingIntegration?.status === 'active' ? (
              <>
                <span className={styles.ConfigurationSectionHeading}>
                  {integrationLabel} was configured for this program on{' '}
                  <strong>
                    {DateTime.fromJSDate(
                      new Date(existingIntegration.updatedAt)
                    ).toFormat('MMM dd, yyyy')}
                  </strong>
                  .
                </span>
              </>
            ) : (
              <div className={styles.ConfigurationSectionBody}>
                <div className={styles.CtaContainer}>
                  <span>Account has not been linked</span>
                  <MergeLinkConfigurationButton type={type} />
                </div>
              </div>
            ))}
        </Section>

        {!isLoading && existingIntegration && (
          <Section
            title="Configure User Identifiers"
            href={`${existingIntegration.id}/user-identifier`}
          >
            {isLoadingUserMappingConfig && <LoadingSpinner />}
            {!isLoadingUserMappingConfig && userMappingConfig && (
              <UserMappingConfigPreview
                integrationId={existingIntegration.id}
              />
            )}
            {!isLoadingUserMappingConfig && !userMappingConfig && (
              <div className={styles.ConfigurationSectionBody}>
                <Alert type="warning">
                  User identifiers must be configured before the integration can
                  be enabled
                </Alert>
                <div className={styles.CtaContainer}>
                  <span>User identifiers have not been configured</span>
                  <Button
                    variant="primary"
                    label="Configure"
                    href={navigate(
                      `/${programId}/configure/${type}/${existingIntegration.id}/user-identifier`
                    )}
                  />
                </div>
              </div>
            )}
          </Section>
        )}

        {permissions.mergeIntegrationsAdminActionsAccess &&
          !isLoading &&
          existingIntegration && (
            <>
              <Section title="Administrative Actions">
                <FormSubsection title="View User Mappings" divider={false}>
                  <div className={styles.AdministrativeAction}>
                    <Body color={color.gray60}>
                      View the existing user mappings between {integrationLabel}{' '}
                      and Firstup.
                    </Body>
                    <Button
                      variant="primary"
                      label="View User Mappings"
                      href={navigate(
                        `/${programId}/configure/${type}/${existingIntegration.id}/user-mapping/preview`
                      )}
                    />
                  </div>
                </FormSubsection>

                <hr />

                <FormSubsection title="Clear User Mappings" divider={false}>
                  <div className={styles.AdministrativeAction}>
                    <Body color={color.gray60}>
                      Changing your configuration can break existing user
                      mapping. Clear all existing user mapping to remap users.
                    </Body>
                    <Button
                      variant="danger"
                      label="Clear User Mappings"
                      isLoading={isClearingUserMappings}
                      disabled={actionInProgress}
                      onClick={() => setShouldClearUserMappings(true)}
                    />
                  </div>
                </FormSubsection>
              </Section>

              {shouldClearUserMappings && (
                <ConfirmModal
                  title="Clear User Mappings?"
                  onCancel={() => setShouldClearUserMappings(false)}
                  confirmButton={
                    <Button
                      variant="primary"
                      label="Clear mappings"
                      onClick={onClearUserMappings}
                      isLoading={isClearingUserMappings}
                    />
                  }
                >
                  <p>
                    You are about to clear all user mappings between{' '}
                    {integrationLabel} and Firstup. This action cannot be
                    undone.
                  </p>
                  <br />
                  <p>
                    Normally, this action is only needed when you have changed
                    the user mapping configuration. Are you sure you want to
                    continue with clearing the mappings?
                  </p>
                </ConfirmModal>
              )}
            </>
          )}
      </div>
    </FormPage>
  );
};
