import { createContext, useContext } from 'react';

export const NumberFormatContext = createContext<Intl.NumberFormat | null>(
  null
);

export function useNumberFormat(): Intl.NumberFormat {
  const numberFormat = useContext(NumberFormatContext);
  if (!numberFormat) {
    throw new Error(
      'useNumberFormat must be used within a NumberFormatProvider'
    );
  }
  return numberFormat;
}

export function NumberFormatProvider({
  children,
  numberFormat,
}: {
  children: React.ReactNode;
  numberFormat: Intl.NumberFormat;
}): JSX.Element {
  return (
    <NumberFormatContext.Provider value={numberFormat}>
      {children}
    </NumberFormatContext.Provider>
  );
}
