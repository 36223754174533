import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import {
  DropdownInput,
  DropdownList,
  DropdownListItem,
  DropdownRoot,
  Icon,
  TextInput,
} from '@socialchorus/shared-ui-components';
import { isAxiosError } from 'axios';
import deepEqual from 'fast-deep-equal';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import { Section, Subsection } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import {
  useUserMappingConfiguration,
  useUserMappingQueryPresets,
  useUserMappingSelectableMergeAttributes,
} from 'hooks/merge-integration';
import {
  QueryBlueprintParamFields,
  UserMappingConfig,
} from 'models/MergeIntegration/types';
import { getIntegrationLabel } from 'models/MergeIntegration/integrations';
import { upsertUserMappingConfig } from 'services/api-merge-integration';
import { NotFoundError } from 'services/Errors/NotFoundError';
import { ForbiddenError } from 'services/Errors/ForbiddenError';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { MergeIntegrationContext } from '../context';
import styles from './styles.module.css';

export const ConfigureUserIdentifier: React.FC<RouteComponentProps<{
  integrationId: string;
}>> = ({ integrationId }) => {
  const { type } = useContext(MergeIntegrationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { id: programId } = useProgram();

  const [presetKey, setPresetKey] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [paramsTemplate, setParamsTemplate] = useState<
    QueryBlueprintParamFields
  >();
  const [params, setParams] = useState<Record<string, unknown>>({});
  const [mergeAttribute, setMergeAttribute] = useState<string>();

  const [isUpdatingConfig, setIsUpdatingConfig] = useState(false);
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const {
    data: queryPresets,
    isLoading: isLoadingPresets,
  } = useUserMappingQueryPresets();

  const {
    data: selectableMergeAttributes,
    isLoading: isLoadingMergeAttributes,
  } = useUserMappingSelectableMergeAttributes();

  const lastPath = `${location.pathname}/../..`;

  const onPresetChange = (key: string) => {
    setPresetKey(key);
    setQuery(queryPresets?.[key].query);
    setParamsTemplate(queryPresets?.[key].params);
    setParams({});
  };

  const onFetchConfigSuccess = useCallback(
    (data: UserMappingConfig | null) => {
      setPresetKey(data?.firstupAttributeQueryBlueprint.key);
      setQuery(data?.firstupAttributeQueryBlueprint.query);
      setParamsTemplate(
        data
          ? queryPresets?.[data.firstupAttributeQueryBlueprint.key].params
          : undefined
      );
      setParams(data?.firstupAttributeQueryBlueprint.params || {});
      setMergeAttribute(data?.mergeAttribute);
    },
    [queryPresets]
  );

  const draftUserMappingConfig = useMemo(() => {
    if (!integrationId || !presetKey || !query || !mergeAttribute) {
      return undefined;
    }

    return {
      integrationId,
      firstupAttributeQueryBlueprint: {
        key: presetKey,
        name: queryPresets?.[presetKey].name || '',
        query,
        params,
      },
      mergeAttribute,
    };
  }, [integrationId, mergeAttribute, params, presetKey, query, queryPresets]);

  const onSave = useCallback(async () => {
    if (!draftUserMappingConfig) return;

    setIsUpdatingConfig(true);

    try {
      await upsertUserMappingConfig(programId, draftUserMappingConfig);

      if (lastPath) {
        navigate(lastPath);
      }
    } finally {
      setIsUpdatingConfig(false);
    }
  }, [draftUserMappingConfig, lastPath, navigate, programId]);

  const {
    data: userMappingConfig,
    isLoading: isLoadingUserMappingConfig,
  } = useUserMappingConfiguration(integrationId || '', {
    onSuccess: onFetchConfigSuccess,
    onError: (err) => {
      if (
        err instanceof NotFoundError ||
        err instanceof ForbiddenError ||
        (isAxiosError(err) &&
          err.response &&
          [403, 404].includes(err.response.status))
      ) {
        if (lastPath) {
          navigate(lastPath);
          return;
        }
      }
      throw err;
    },
  });

  const hasChanges = useMemo(() => {
    return (
      !userMappingConfig ||
      (!!draftUserMappingConfig &&
        !deepEqual(userMappingConfig, {
          ...draftUserMappingConfig,
          createdAt: userMappingConfig.createdAt,
          updatedAt: userMappingConfig.updatedAt,
        }))
    );
  }, [draftUserMappingConfig, userMappingConfig]);

  const isLoading =
    isLoadingUserMappingConfig || isLoadingPresets || isLoadingMergeAttributes;

  const hasMissingParams = paramsTemplate?.some(
    (param) => !params?.[param.name]
  );

  const saveDisabled =
    !hasChanges || !presetKey || !mergeAttribute || !query || hasMissingParams;

  if (!type) {
    return null;
  }

  const integrationLabel = getIntegrationLabel(type);

  return (
    <>
      <FormPage
        title="User Mapping Settings"
        breadcrumbs={[
          { to: '../../..', label: 'Configure' },
          { to: '../..', label: integrationLabel },
          { label: 'User Mapping' },
        ]}
        actions={[
          {
            label: 'Save',
            disabled: isLoading || saveDisabled,
            isLoading: isUpdatingConfig,
            onClick: () => {
              if (!userMappingConfig) {
                onSave();
              } else {
                setShouldConfirm(true);
              }
            },
          },
        ]}
      >
        <Section title="User Mapping Settings">
          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <>
              <DropdownRoot
                value={mergeAttribute}
                onValueChange={setMergeAttribute}
              >
                <DropdownInput
                  label={`${integrationLabel} Attribute`}
                  description={`Select the attribute from ${integrationLabel} that uniquely identifies the employee`}
                  placeholder="Select an attribute"
                />
                <DropdownList>
                  {(selectableMergeAttributes || []).map((attribute) => (
                    <DropdownListItem key={attribute} value={attribute}>
                      {attribute}
                    </DropdownListItem>
                  ))}
                </DropdownList>
              </DropdownRoot>

              <div className={styles.AttributeSyncHr}>
                <hr />
                <Icon className={styles.AttributeSyncIcon}>sync_alt</Icon>
                <hr />
              </div>

              <div className={styles.FirstupAttributeSection}>
                <DropdownRoot value={presetKey} onValueChange={onPresetChange}>
                  <DropdownInput
                    label="Firstup Attribute"
                    description="Select the attribute from Firstup that uniquely identifies the employee"
                    placeholder="Select an attribute"
                  />
                  <DropdownList>
                    {Object.entries(queryPresets || {}).map(([key, preset]) => (
                      <DropdownListItem key={key} value={key}>
                        {preset.name}
                      </DropdownListItem>
                    ))}
                  </DropdownList>
                </DropdownRoot>

                {!!paramsTemplate?.length && (
                  <Subsection title="Parameters">
                    <div
                      className={styles.FirstupAttributeParametersSubsection}
                    >
                      {paramsTemplate.map((param) => {
                        switch (param.type) {
                          case 'string':
                            return (
                              <TextInput
                                id={`${param.name.replace(/\s+/, '_')}--input`}
                                label={param.name}
                                value={params?.[param.name] as string}
                                onChange={(value) =>
                                  setParams((p) => ({
                                    ...p,
                                    [param.name]: value,
                                  }))
                                }
                              />
                            );
                          default:
                            return null;
                        }
                      })}
                    </div>
                  </Subsection>
                )}
              </div>
            </>
          )}
        </Section>
      </FormPage>

      {shouldConfirm && (
        <ConfirmModal
          title="Submit new user mapping settings?"
          confirmLabel="Submit"
          onCancel={() => setShouldConfirm(false)}
          onConfirm={onSave}
        >
          <p>This will replace the existing user mapping settings.</p>
          <br />
          <p>
            Users who are not mapped to a {integrationLabel} employee will be
            mapped based on the new settings, while the existing mappings will
            be left unchanged.
          </p>
          <br />
          <p>
            To reset all mappings, please use the{' '}
            <strong>Clear User Mappings</strong> action (under{' '}
            <strong>Administrative Actions</strong>) after submitting the new
            configuration.
          </p>
        </ConfirmModal>
      )}
    </>
  );
};
