import React from 'react';
import { TextInput } from '@socialchorus/shared-ui-components';
import { Box, FormModal } from 'DesignSystem/Components';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import { useUniqueId } from 'hooks/useUniqueId';
import { VariantAudienceBuilder } from './VariantAudienceBuilder';
import styles from './VariantModal.module.css';

export const VariantModal: React.FC<{
  variant: DynamicBlockVariant;
  onCancel: () => void;
  onSubmit: (variant: DynamicBlockVariant) => void;
}> = ({ variant, onCancel, onSubmit }) => {
  const id = useUniqueId();

  const [name, setName] = React.useState<string>(variant.name);
  const [audienceQuery, setAudienceQuery] = React.useState<string>(
    variant.audienceQuery ?? ''
  );
  const [totalUsers, setTotalUsers] = React.useState<number>(
    variant.totalUsers ?? 0
  );

  return (
    <FormModal
      entityText={variant.name}
      actionText="Edit"
      headerTitle={`Edit ${variant.name}`}
      submitLabel="Save"
      width="960px"
      onCancel={onCancel}
      onSubmit={() =>
        onSubmit({
          ...variant,
          name,
          audienceQuery,
          totalUsers,
        })
      }
    >
      <TextInput
        id={`edit-variant-modal-${id}`}
        label="Display name"
        value={name}
        onChange={setName}
        className={styles.variantNameInput}
      />
      <Box margin={[16, 0, 0]}>
        <VariantAudienceBuilder
          audienceQuery={audienceQuery}
          totalUsers={totalUsers}
          onChange={(change) => {
            setAudienceQuery(change.audienceQuery);
            setTotalUsers(change.totalUsers);
          }}
        />
      </Box>
    </FormModal>
  );
};
