import React from 'react';
import { Button } from 'DesignSystem/Form';
import { Modal } from 'DesignSystem/Components/Modal';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useFlashMessage } from 'contexts/flasher';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { WarningTriangle } from 'shared/icons';
import { FileUploadButton } from 'shared/FileUploadButton';
import { ImageUploader } from 'hooks/useImageUploader';
import styles from '../author-alias.module.css';

export type ImageUploadInputProps = {
  buttonLabel: string;
  onChange: (avatarUrl: string) => void;
  showRemoveButton: boolean;
  iconUploader: ImageUploader;
  setIsUploading: (isUploading: boolean) => void;
  type?: string;
};

export const ImageUploadInput: React.FC<ImageUploadInputProps> = ({
  buttonLabel = 'Upload image',
  onChange,
  showRemoveButton,
  iconUploader,
  setIsUploading,
  type = 'content',
}) => {
  const [clearCounter, setClearCounter] = React.useState(1);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = React.useState('');
  const [confirmModalDescription, setConfirmModalDescription] = React.useState(
    ''
  );
  const [
    confirmModalConfirmLabel,
    setConfirmModalConfirmLabel,
  ] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const { setFlashMessage } = useFlashMessage();

  const iconsMap = {
    warning: <WarningTriangle color="#f78c16" />,
    none: undefined,
  };

  const handleFileSelect = React.useCallback(
    (newFile: File) => {
      if (newFile.size >= 10 * 1000 * 1024) {
        setFlashMessage({
          message: 'Only files up to 10MB in size are accepted',
          severity: 'error',
        });
        setClearCounter((counter) => counter + 1);
      } else if (type === 'user') {
        setSelectedFile(newFile);
        setConfirmModalTitle('Update display avatar?');
        setConfirmModalDescription(
          'When saved, this will update the user’s author alias image, and their profile picture in the member experience and Studio.'
        );
        setConfirmModalConfirmLabel('Continue');
        setOpenConfirmModal(true);
      } else {
        setIsUploading(true);
        iconUploader.update(newFile);
      }
    },
    [iconUploader, setFlashMessage, setIsUploading, setClearCounter, type]
  );

  const handleConfirmFileSelect = () => {
    if (selectedFile) {
      setIsUploading(true);
      iconUploader.update(selectedFile);
      setOpenConfirmModal(false);
    }
  };

  const handleConfirmFileRemove = () => {
    onChange('');
    iconUploader.remove();
    setOpenConfirmModal(false);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };

  const handleFileRemove = React.useCallback(() => {
    if (type === 'user') {
      setSelectedFile(null);
      setConfirmModalTitle('Remove display avatar?');
      setConfirmModalDescription(
        'When saved, this will remove the user’s author alias image, and their profile picture in the member experience and Studio.'
      );
      setConfirmModalConfirmLabel('Continue');
      setOpenConfirmModal(true);
    } else {
      onChange('');
      iconUploader.remove();
    }
  }, [onChange, iconUploader, type]);

  return (
    <div className={styles.ImageUploadActions}>
      <FileUploadButton
        accept="image/*"
        shouldClear={clearCounter}
        type="secondary"
        onFileSelect={handleFileSelect}
      >
        {buttonLabel}
      </FileUploadButton>
      {showRemoveButton && (
        <Button
          icon={<Icon name="Delete" size={20} />}
          borderless
          secondary
          className={styles.ImageUploadRemove}
          onClick={handleFileRemove}
        />
      )}
      {openConfirmModal && (
        <Modal innerPadding={0} name="confirmModal">
          <Flex start column className={styles.modalWrapper}>
            <Flex start className={styles.modalHeader}>
              <Flex center className={styles.warningIcon}>
                {iconsMap.warning}
              </Flex>
              {confirmModalTitle}
            </Flex>
            <div className={styles.modalBody}>{confirmModalDescription}</div>
            <Flex end className={styles.modalFooter}>
              <Button
                borderless
                secondary
                label="Cancel"
                onClick={handleCloseModal}
              />
              <Button
                label={confirmModalConfirmLabel}
                onClick={
                  selectedFile
                    ? handleConfirmFileSelect
                    : handleConfirmFileRemove
                }
              />
            </Flex>
          </Flex>
        </Modal>
      )}
    </div>
  );
};
