import React, { useRef } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { Section, Subsection } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { TextInput } from 'shared/TextInput';
import { WithPermission } from 'shared/WithPermission';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { AliasData } from 'services/api-author-alias';
import { useUpdateUserAvatar } from 'hooks/user';
import { useAuthorAliasQuery } from 'hooks/author-alias';
import { DescriptionSection } from './components/DescriptionSection';
import { TeamAvatarSection } from './components/TeamAvatarSection';
import { useActions } from './useActions';
import { UserAvatarSection } from './components/UserAvatarSection';
import styles from './author-alias.module.css';

export const EditAuthorAlias: React.FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const { update } = useActions();
  const { data, errorMessage, isLoading } = useAuthorAliasQuery(
    programId,
    id as number
  );
  const { setFlashMessage } = useFlashMessage();

  const [displayNameError, setDisplayNameError] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState('');
  const [authorAlias, setAuthorAlias] = React.useState<AliasData>();
  const [redirectUrl, setRedirectUrl] = React.useState('');
  const [isUploading, setIsUploading] = React.useState(false);
  const [userAvatarUrl, setUserAvatarUrl] = React.useState('');
  const [userAvatarUpdated, setUserAvatarUpdated] = React.useState(false);

  const loaded = useRef(false);

  React.useEffect(() => {
    if (authorAlias?.aliasType === 'program') {
      setFlashMessage({
        severity: 'error',
        message: 'You cannot edit Program aliases',
      });
      setRedirectUrl(`../../${authorAlias.id}/details`);
    }
  }, [authorAlias, setFlashMessage, setRedirectUrl]);

  React.useEffect(() => {
    if (data) {
      setAuthorAlias(data);
      if (!loaded.current) {
        loaded.current = true;
      }
    }
  }, [data, loaded]);

  React.useEffect(() => {
    if (errorMessage)
      setFlashMessage({ severity: 'error', message: upcase(errorMessage) });
    if (errorMessage?.toLowerCase() === 'forbidden') setRedirectUrl('../../');
  }, [errorMessage, setFlashMessage, setRedirectUrl]);

  function upcase(word: string | undefined) {
    return word ? word[0].toUpperCase() + word.slice(1) : '';
  }

  function onChange(updatedData: Partial<AliasData>) {
    setDisplayNameError('');
    setDescriptionError('');
    setAuthorAlias({ ...(authorAlias as AliasData), ...updatedData });
  }

  function validate(field?: Array<string>) {
    let error = '';
    if (!authorAlias) error = 'There is nothing to save';
    else
      switch (authorAlias.aliasType) {
        case 'team':
          if (
            field?.includes('displayName') &&
            !(authorAlias.displayName || '').trim()
          ) {
            error = 'Display Name is a required field';
            setDisplayNameError(error);
          }
          if (
            field?.includes('description') &&
            (authorAlias.description || '').length > 140
          ) {
            error =
              'Description is too long. It must be 140 characters or less';
            setDescriptionError(error);
          }
          break;
        default:
      }
    return !error;
  }

  const { updateAvatar } = useUpdateUserAvatar(programId, {
    onSuccess: (newAvatarUrl: string) => {
      onChange({ avatarUrl: newAvatarUrl });
      setIsUploading(false);
    },
    onError: () => {
      setFlashMessage({
        severity: 'error',
        message: 'Could not update user avatar',
      });
      setIsUploading(false);
    },
  });

  return redirectUrl ? (
    <Redirect noThrow to={redirectUrl} />
  ) : (
    <WithPermission permissions={['confContentAccess']}>
      <FormPage
        title={authorAlias?.displayName || 'Edit Author Alias'}
        breadcrumbs={[
          { label: 'Configure', to: '../../..' },
          { label: 'Aliases', to: '../..' },
          { label: authorAlias?.displayName || 'Edit Author Alias' },
        ]}
        actions={
          authorAlias
            ? [
                {
                  label: 'Save',
                  disabled: isUploading,
                  onClick: () => {
                    if (validate(['displayName', 'description'])) {
                      if (userAvatarUpdated) {
                        updateAvatar(
                          Number(authorAlias?.userId),
                          userAvatarUrl
                        );
                      }
                      update(authorAlias);
                    }
                  },
                },
              ]
            : []
        }
      >
        <Section title="Alias Settings">
          {isLoading && (
            <div className={styles.Loading}>
              <LoadingSpinner />
            </div>
          )}
          {!isLoading && (
            <>
              {authorAlias?.aliasType === 'team' && (
                <Subsection
                  title="Display Name"
                  description="This name will be displayed to community members when publishing a campaign"
                  divider={false}
                >
                  <TextInput
                    className={styles.textField}
                    placeholder="Enter a display name"
                    value={authorAlias?.displayName}
                    hasError={!!displayNameError}
                    errorMessage={displayNameError}
                    onChange={(displayName) => onChange({ displayName })}
                    onBlur={() => validate(['displayName'])}
                  />
                </Subsection>
              )}
              <DescriptionSection
                aliasData={authorAlias || {}}
                onChange={(description) => onChange({ description })}
                hasError={!!descriptionError}
                errorMessage={descriptionError}
                onBlur={() => validate(['description'])}
              />
              {authorAlias?.aliasType === 'team' && (
                <TeamAvatarSection
                  aliasData={authorAlias}
                  onChange={(avatarUrl) => onChange({ avatarUrl })}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                />
              )}
              {authorAlias?.aliasType === 'user' && (
                <UserAvatarSection
                  aliasData={authorAlias}
                  onChange={(newAvatarUrl: string) => {
                    setUserAvatarUpdated(true);
                    setUserAvatarUrl(newAvatarUrl);
                    onChange({ avatarUrl: newAvatarUrl });
                    setIsUploading(false);
                  }}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                />
              )}
            </>
          )}
        </Section>
      </FormPage>
    </WithPermission>
  );
};
