import {
  Expression,
  emptyExpression,
  expressionToText,
  normalizeExpression,
  stripEnclosingParens,
  textToExpression,
  literalExpression,
} from 'models/expression';

export type Audience = {
  description?: string;
  expression?: Expression;
  id?: string;
  name: string;
  programId: number;
  query?: string;
  state: string;
  tags: Array<string>;
  title: string;
  totalUsers: number;
  type: string;
  updatedAt?: Date;
  createdAt?: Date;
  creator?: string;
  creatorId?: number;
  lastUpdatedBy?: string;
  includeDeactivatedUsers?: boolean;
  static?: boolean;
  apiSource?: string;
  expensive?: boolean;
};

export type ChannelAudience = {
  id: string;
  title: string;
  name: string;
  query: string;
  type: string;
};

export const defaultAudience = ({
  programId,
  query,
}: {
  programId: number;
  query?: string;
}): Audience => ({
  programId,
  expression: normalizeExpression(emptyExpression()),
  name: '',
  query: query || '*',
  tags: [],
  title: '',
  totalUsers: 0,
  type: 'custom',
  state: 'enabled',
  includeDeactivatedUsers: false,
});

export const ALL_USERS_ID = 'standard.all_users';
export const DYNAMIC_TYPE = 'dynamic';

export const canEditQuery = (audience: Audience): boolean =>
  audience.type === 'custom' && audience.apiSource !== 'scim';

export const canEditInfo = (audience: Audience): boolean =>
  audience.type === 'custom' || audience.type === 'snapshot';

export const canEditAny = (audience: Audience): boolean =>
  canEditQuery(audience) || canEditInfo(audience);

export const stringId = ({
  type,
  name,
}: Pick<Audience, 'type' | 'name'>): string => `${type}.${name}`;

export const setDescription = (
  audience: Audience,
  description: string
): Audience => ({ ...audience, description });

export const setQuery = (audience: Audience, query: string): Audience => ({
  ...audience,
  query,
});

export const setTagString = (
  audience: Audience,
  tagString: string
): Audience => {
  const tags = tagString
    .split(',')
    .map((t) => t.trim())
    .filter((t) => t !== '');
  return { ...audience, tags };
};

export const setTitle = (audience: Audience, title: string): Audience => ({
  ...audience,
  title,
});

export const tagString = (audience: Audience): string => {
  return (audience.tags || []).join(', ');
};

const isTempTitle = (title: string): boolean =>
  title.includes('Temporary-audience-');

export const getTempTitle = (value: string, title: string): string => {
  if (isTempTitle(value) || value.length === 0) return title;
  return value;
};

export type editableTextToQueryType = (
  text: string,
  includeDeactivatedUsers?: boolean | undefined
) => void;

type AudienceQueryDecorator = {
  getEditableText: () => string;
  getExpression: () => Expression;
  setEditableText: (query: string) => void;
  setExpression: (expression: Expression) => void;
  editableTextToQuery: editableTextToQueryType;
  queryToEditableText: (query: string) => string;
};

export const audienceQuery = (
  audience: Audience,
  setAudience: (audience: Audience) => void,
  newDateOperatorsEnabled = false
): AudienceQueryDecorator => {
  const editableTextToQuery = (
    text: string,
    includeDeactivatedUsers:
      | boolean
      | undefined = audience.includeDeactivatedUsers
  ) => {
    if (text === '') return text;

    return includeDeactivatedUsers
      ? text.replace(' AND status:not_blocked', '')
      : `(${text}) AND status:not_blocked`;
  };
  const queryToEditableText = (query: string) =>
    audience.includeDeactivatedUsers
      ? query
      : stripEnclosingParens(query.replace(' AND status:not_blocked', ''));
  const getEditableText = () => queryToEditableText(audience.query || '');
  const setEditableText = (query: string) => {
    const e = textToExpression({ query, newDateOperatorsEnabled });
    const n = normalizeExpression(e);
    setAudience({
      ...audience,
      expression: n,
      query: editableTextToQuery(query, audience.includeDeactivatedUsers),
    });
  };
  const getExpression = () => {
    if (audience.expression && audience.expensive) {
      const sanitized_query = (audience.query || '').replace(/:""/g, ":''");
      return literalExpression(sanitized_query);
    }
    if (audience.expression) {
      return audience.expression;
    }
    return normalizeExpression(
      textToExpression({ query: getEditableText(), newDateOperatorsEnabled })
    );
  };
  const setExpression = (expression: Expression) => {
    setAudience({
      ...audience,
      expression,
      query: editableTextToQuery(
        expressionToText(expression),
        audience.includeDeactivatedUsers
      ),
    });
  };
  return {
    getExpression,
    getEditableText,
    setExpression,
    setEditableText,
    editableTextToQuery,
    queryToEditableText,
  };
};

export function combinedAudiencesQuery(audiences: Array<Audience>): string {
  const subqueries: Array<string> = [];

  audiences.forEach((audience) => {
    if (audience.type === DYNAMIC_TYPE) {
      subqueries.push(`(${audience.query})`);
    } else if (audience.type === 'generated') {
      subqueries.push(`group:${audience.name}`);
    } else {
      subqueries.push(`group:${stringId(audience)}`);
    }
  });

  return subqueries.join(' OR ');
}

export function audienceIntersectionQuery(
  audiences1: Array<Audience>,
  audiences2: Array<Audience>
): string | null {
  const query1 = combinedAudiencesQuery(audiences1);
  const query2 = combinedAudiencesQuery(audiences2);

  if (query1 && query2) {
    return `(${query1}) AND (${query2}) AND status:registered`;
  }

  return null;
}

export function combineAudiences({
  programId,
  audiences,
}: {
  programId: number;
  audiences: Array<Audience>;
}): Audience {
  return defaultAudience({
    programId,
    query: combinedAudiencesQuery(audiences),
  });
}

export function isAudience(value: unknown): value is Audience {
  return (value as Audience).type !== undefined;
}
