import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { BossViewPage } from 'shared/BossView/Page';

export const CreatePage: React.FC<RouteComponentProps<{ id: string }>> = () => {
  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      const { data } = event;
      if (
        data.source === 'bossa' &&
        data.type === '@content-amplifiers-v2-items/COMMIT-SAVE'
      ) {
        const { id, programId } = data.payload;
        window.history.pushState(
          null,
          '',
          `/${programId}/configure/microapps/edit/${id}`
        );
      }
    });
  }, []);
  return (
    <BossViewPage
      app="samba"
      title="Microapps"
      fullScreen
      src="editor/content-anywhere"
      returnUrls={['/configure/microapps']}
    />
  );
};
