import * as React from 'react';
import cx from 'classnames';
import { RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import {
  Desktop as WebIcon,
  Mail as EmailIcon,
  Mobile as MobileIcon,
} from 'shared/icons';
import { Switcher } from 'components/publisher/previews/Switcher';
import { Flex } from 'DesignSystem/Layout/Flex';

import { Sidebar } from './Sidebar';
import styles from '../../theme/compose.module.css';
import { Mobile } from './Mobile';
import { Web } from './Web';
import { Email } from './Email';

type CardTab = { name: string; frame: React.FC; icon: React.FC };

const CARD_TABS: CardTab[] = [
  { name: 'mobile', frame: Mobile, icon: MobileIcon },
  { name: 'web', frame: Web, icon: WebIcon },
  { name: 'email', frame: Email, icon: EmailIcon },
];

export const Card: React.FC<RouteComponentProps> = () => {
  const [tab, setTab] = useState<CardTab>(CARD_TABS[0]);

  return (
    <>
      <Helmet>
        <title>Cover</title>
      </Helmet>

      <div className={styles.container}>
        <div className={cx(styles.sidebar)}>
          <Sidebar />
        </div>
        <div className={cx(styles.main, styles.transparent)}>
          <div>
            <Flex center>
              <tab.frame />
              <Switcher tab={tab} tabs={CARD_TABS} setTab={setTab} />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};
