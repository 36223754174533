import React from 'react';
import { Icon } from 'shared/Icon';
import { useFeatureFlags } from 'contexts/feature-flags';
import { User } from 'models/user';
import styles from './sidebar.module.css';

export const Sidebar: React.FC<{
  data: User;
}> = ({ data }) => {
  const { gdprComplianceEnabled } = useFeatureFlags();
  const showResetPassword = data.status === 'active';
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div>
          <div>NA</div>
          <div>90 day activity</div>
        </div>
        <div>
          <div>NA</div>
          <div>Avg session length</div>
        </div>
        <div>
          <div>NA</div>
          <div>Posts</div>
        </div>
      </div>
      <div className={styles.col}>
        <div>
          <span>Created</span>
          <span>NA</span>
        </div>
        <div>
          <span>Last active</span>
          <span>NA</span>
        </div>
        <div>
          <span>Preferred channel</span>
          <span>NA</span>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        <div>
          <span>
            <Icon iconName="ArrowNext" iconType="SVG" />
          </span>
          <span>Send Invite</span>
        </div>
        {showResetPassword && (
          <div>
            <span>
              <Icon iconName="ResetPassword" iconType="SVG" />
            </span>
            <span>Reset Password</span>
          </div>
        )}
        <div>
          <span>
            <Icon iconName="Deactivate" iconType="SVG" />
          </span>
          <span>Deactivate</span>
        </div>
        {gdprComplianceEnabled?.value && (
          <>
            <div>
              <span>
                <Icon iconName="Delete" iconType="SVG" />
              </span>
              <span>Forget User (GDPR)</span>
            </div>
            <div>
              <span>
                <Icon iconName="Export" iconType="SVG" />
              </span>
              <span>Export user data (GDPR)</span>
            </div>
          </>
        )}
        <div>
          <span>
            <Icon iconName="CheckCircleOutline" iconType="SVG" />
          </span>
          <span>Confirm User</span>
        </div>
      </div>
    </div>
  );
};
