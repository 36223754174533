import React from 'react';
import { Box } from 'DesignSystem/Components';
import {
  Body,
  Caption,
  color,
  Subheading,
  Text,
} from 'DesignSystem/Typography';
import {
  ComplexExpression,
  defaultComplexExpression,
  SimpleExpression,
} from 'models/expression';
import {
  EventTrigger,
  localeTimezone,
  RecurringTrigger,
  recurringTriggerTime,
  ScheduledTrigger,
  StartStep,
  timeOfDay,
  timeZoneOrUTC,
  timeZones,
  Trigger,
} from 'models/journeys/journey';
import { Radio } from 'DesignSystem/Form';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { DateTime } from 'luxon';
import { GenericSelect } from 'shared/Select';
import { useUniqueId } from 'hooks/useUniqueId';
import { CalendarSelect } from '../shared/CalendarSelect';
import { EventSelect, SUPPORTED_CAMPAIGN_EVENTS } from './EventSelect';
import { AudienceSelect } from '../shared/AudienceSelect';
import styles from './StartConfig.module.css';
import { TimeSelect } from '../shared/TimeSelect';
import { useJourneyValidationErrors } from '../../JourneyErrors/useJourneyValidationErrors';
import { TriggerSelect } from './TriggerSelect';
import {
  RecurringNextRuns,
  ScheduleFilter,
} from '../../JourneyCanvasHeader/PublishModals/PublishJourneyModal/RecurringBody';
import { nextTriggerDate } from './nextTriggerDate';
import { formatStartDate } from './utils';

const TRIGGER_SELECT_MAP = {
  immediate: 'date',
  scheduled: 'date',
  event: 'event',
  recurring: 'recurring',
} as const;

const startTypeOptions: {
  label: string;
  value: typeof TRIGGER_SELECT_MAP[keyof typeof TRIGGER_SELECT_MAP];
}[] = [
  { label: 'One-Time', value: 'date' },
  { label: 'Event', value: 'event' },
  { label: 'Daily', value: 'recurring' },
];

export const ReadOnlyStartConfig: React.FC<{ step: StartStep }> = ({
  step,
}) => {
  const { trigger, criterion } = step;
  const selectedType = trigger && TRIGGER_SELECT_MAP[trigger.type];
  const startLabel = startTypeOptions.find(
    (option) => option.value === selectedType
  );
  const expression =
    criterion ?? defaultComplexExpression(defaultComplexExpression());
  const isRecurringTrigger =
    selectedType === 'recurring' && trigger?.type === 'recurring';
  const formattedTime = isRecurringTrigger
    ? DateTime.fromObject(trigger?.timeOfDay).toFormat('hh:mm a')
    : undefined;
  return (
    <Box padding={[0, 0, 32, 0]}>
      <Box>
        <Subheading bold>Start Type</Subheading>
        <Box style={{ textTransform: 'capitalize' }}>
          {startLabel?.label}
          {selectedType === 'date' ? <>, {trigger?.type}</> : null}
        </Box>
        {isRecurringTrigger ? (
          <Box>
            <Text
              className={{
                Caption: true,
              }}
            >
              <time dateTime={formattedTime}>{formattedTime}</time>
              <span>, Time zone: </span>
              <time>{timeZoneOrUTC(trigger.timeZone).label}</time>
            </Text>
          </Box>
        ) : null}
      </Box>

      {selectedType === 'date' && trigger?.type === 'scheduled' && (
        <Box>
          <Caption>
            {trigger.date?.toLocaleString(DateTime.DATETIME_MED)}
          </Caption>
        </Box>
      )}

      {selectedType === 'event' && trigger?.type === 'event' && (
        <EventTriggerConfiguration mode="read-only" trigger={trigger} />
      )}

      {isRecurringTrigger && (
        <RecurringTriggerConfiguration mode="read-only" trigger={trigger} />
      )}

      <Box padding={[16, 0, 32, 0]}>
        <Subheading bold>Filter Members</Subheading>
        <Box padding={[16, 0, 0, 0]}>
          <AudienceSelect
            canEdit={false}
            showCount={selectedType === 'date'}
            value={expression}
            menuPlacement="bottom"
            onChange={() => {}}
          />
        </Box>
      </Box>
    </Box>
  );
};

const getDefaultTrigger = (
  triggerType: Trigger['type'] | 'date',
  ctx: {
    localeTimezone: string;
  }
): Trigger | undefined => {
  switch (triggerType) {
    case 'immediate':
      return {
        type: 'immediate',
      };
    case 'scheduled':
      return {
        date: undefined,
        type: 'scheduled',
      };
    case 'event':
      return {
        type: 'event',
        event: '',
      };
    case 'recurring':
      return {
        type: 'recurring',
        frequency: 'daily',
        timeOfDay: recurringTriggerTime(),
        timeZone: ctx.localeTimezone,
        startTriggeringOn: nextTriggerDate(
          recurringTriggerTime(),
          ctx.localeTimezone
        ),
      };
    default:
      return undefined;
  }
};

export const StartConfig: React.FC<{
  step: StartStep;
  onUpdateStep: (step: StartStep) => void;
}> = ({ step, onUpdateStep }) => {
  const { resolveErrors } = useJourneyValidationErrors();

  const { trigger, criterion } = step;
  const [selectedType, setSelectedType] = React.useState(
    trigger && TRIGGER_SELECT_MAP[trigger.type]
  );

  const expression =
    criterion ?? defaultComplexExpression(defaultComplexExpression());

  const onChangeStartType = React.useCallback(
    (newStartType: Trigger['type'] | 'date') => {
      const newTrigger = getDefaultTrigger(newStartType, { localeTimezone });
      if (newTrigger) {
        if (newTrigger.type === 'scheduled') {
          const scheduledTrigger = newTrigger as ScheduledTrigger;
          if (!scheduledTrigger.date) {
            scheduledTrigger.date = DateTime.now().plus({ hour: 1 });
          }
        } else {
          resolveErrors(step.id, ['scheduledTime']);
        }
        resolveErrors(step.id, [
          'trigger',
          ...(newTrigger.type === 'recurring'
            ? []
            : (['triggerCriterion'] as const)),
        ]);
      }
      onUpdateStep({ ...step, trigger: newTrigger });
    },
    [onUpdateStep, resolveErrors, step]
  );

  const onUpdateScheduleDate = (trig: ScheduledTrigger) => (
    date: DateTime | undefined
  ) => {
    if (date && date.diffNow().toMillis() > 0) {
      resolveErrors(step.id, ['scheduledTime']);
      onUpdateStep({
        ...step,
        trigger: { ...trig, date: date.set({ second: 0 }) },
      });
    }
  };

  return (
    <>
      <Box padding={[0, 0, 32, 0]}>
        <Subheading bold block>
          Start type
        </Subheading>
        <Box width={300}>
          <GenericSelect
            value={selectedType}
            onChange={(newStartType) => {
              setSelectedType(newStartType);
              onChangeStartType(newStartType);
            }}
            options={startTypeOptions}
            menuPlacement="bottom"
            placeholder="Choose a start type"
            keepBlank
            controlBorder="gray30"
          />
          {selectedType === 'date' && !trigger?.type && (
            <Caption color={color.redFull}>
              An additional selection is required
            </Caption>
          )}
        </Box>
      </Box>
      {selectedType === 'date' && (
        <Box padding={[0, 0, 32, 0]}>
          <Box>
            <Flex start className={styles.dateRadioGroup}>
              <FlexItem start>
                <Radio
                  type="radio"
                  name="dateType"
                  className={styles.dateRadio}
                  checked={trigger?.type === 'immediate'}
                  onSelect={() => {
                    onChangeStartType('immediate');
                  }}
                />
              </FlexItem>
              <FlexItem>
                <Flex column alignStart>
                  <FlexItem>
                    <Body>Immediate</Body>
                  </FlexItem>
                  <FlexItem>
                    <Caption>
                      The journey will be active immediately after publishing
                    </Caption>
                  </FlexItem>
                </Flex>
              </FlexItem>
            </Flex>
            <Flex start className={styles.dateRadioGroup}>
              <FlexItem start>
                <Radio
                  type="radio"
                  name="dateType"
                  className={styles.dateRadio}
                  checked={trigger?.type === 'scheduled'}
                  onSelect={() => {
                    onChangeStartType('scheduled');
                  }}
                />
              </FlexItem>
              <FlexItem>
                <Flex column alignStart>
                  <FlexItem>
                    <Body>Scheduled</Body>
                  </FlexItem>
                  <FlexItem>
                    <Caption>
                      Choose a date and time for the journey to become active
                    </Caption>
                  </FlexItem>
                </Flex>
              </FlexItem>
            </Flex>

            {trigger?.type === 'scheduled' && (
              <Flex start>
                <FlexItem className={styles.dateTimeInput}>
                  <CalendarSelect
                    date={trigger.date}
                    placeholder="Choose date"
                    onChange={onUpdateScheduleDate(trigger)}
                  />
                </FlexItem>
                <FlexItem className={styles.dateTimeInput}>
                  <TimeSelect
                    date={trigger.date}
                    placeholder="Choose time"
                    onChange={onUpdateScheduleDate(trigger)}
                  />
                </FlexItem>
              </Flex>
            )}
          </Box>
        </Box>
      )}

      {selectedType === 'event' && trigger?.type === 'event' && (
        <EventTriggerConfiguration
          mode="edit"
          trigger={trigger}
          onUpdateTriggerEvent={(event) => {
            onUpdateStep({
              ...step,
              trigger: { ...trigger, event },
            });
          }}
          onUpdateStartTriggeringOn={(startTriggeringOn) => {
            onUpdateStep({
              ...step,
              trigger: { ...trigger, startTriggeringOn },
            });
          }}
        />
      )}

      {selectedType === 'recurring' && trigger?.type === 'recurring' && (
        <RecurringTriggerConfiguration
          mode="edit"
          trigger={trigger}
          onUpdateRecurringTime={(time) => {
            onUpdateStep({
              ...step,
              trigger: {
                ...trigger,
                timeOfDay: time
                  ? timeOfDay(time.hour, time.minute)
                  : recurringTriggerTime(),
              },
            });
          }}
          onUpdateTriggerCriterion={(t) => {
            onUpdateStep({
              ...step,
              trigger: { ...trigger, triggerCriterion: t },
            });
            resolveErrors(step.id, ['triggerCriterion']);
          }}
          onUpdateTriggerTimezone={(tz) => {
            onUpdateStep({
              ...step,
              trigger: { ...trigger, timeZone: tz },
            });
          }}
          onUpdateStartTriggeringOn={(startTriggeringOn) => {
            onUpdateStep({
              ...step,
              trigger: {
                ...trigger,
                startTriggeringOn,
              },
            });
          }}
        />
      )}

      {selectedType && (
        <Box padding={[0, 0, 32, 0]}>
          <Subheading bold>Filter Members</Subheading>
          <Box padding={[16, 0, 0, 0]}>
            <AudienceSelect
              showCount={selectedType === 'date'}
              value={expression}
              menuPlacement="bottom"
              onChange={(updatedExpression: ComplexExpression) => {
                onUpdateStep({
                  ...step,
                  criterion: updatedExpression,
                });
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

type RecurringTriggerConfigurationProps =
  | {
      mode: 'edit';
      trigger: Pick<
        RecurringTrigger,
        'timeOfDay' | 'timeZone' | 'triggerCriterion' | 'startTriggeringOn'
      >;
      onUpdateRecurringTime: (time: DateTime | undefined) => void;
      onUpdateTriggerCriterion: (triggerCriterion: SimpleExpression) => void;
      onUpdateTriggerTimezone: (timezone: string) => void;
      onUpdateStartTriggeringOn: (startTriggeringOn: DateTime) => void;
    }
  | {
      mode: 'read-only';
      trigger: Pick<
        RecurringTrigger,
        'timeOfDay' | 'timeZone' | 'triggerCriterion' | 'startTriggeringOn'
      >;
    };

function RecurringTriggerConfiguration(
  props: RecurringTriggerConfigurationProps
) {
  const { trigger, mode } = props;
  const timezoneValue = timeZones.find(
    (tz) => tz.name === trigger.timeZone || tz.group.includes(trigger.timeZone)
  );

  const journeyStartDateLabelId = useUniqueId();

  switch (mode) {
    case 'read-only':
      return (
        <Box margin={[32, 0, 0, 0]}>
          <Subheading bold>Next Three Run Times:</Subheading>
          <Box padding={[8, 0, 32, 0]}>
            <RecurringNextRuns
              trigger={{
                ...trigger,
                startTriggeringOn: DateTime.max(
                  trigger.startTriggeringOn,
                  DateTime.now().startOf('hour')
                ),
              }}
              scheduleFilter={ScheduleFilter.Future}
            />
          </Box>
          <Subheading bold>Trigger</Subheading>
          <Box padding={[16, 0, 0, 0]} width="100%" maxWidth="1000px">
            <TriggerSelect expression={trigger.triggerCriterion} isDisabled />
          </Box>
        </Box>
      );
    case 'edit': {
      const {
        onUpdateRecurringTime,
        onUpdateTriggerCriterion,
        onUpdateTriggerTimezone,
        onUpdateStartTriggeringOn,
      } = props;

      const date = DateTime.now().set({
        hour: trigger.timeOfDay.hour,
        minute: trigger.timeOfDay.minute,
        second: 0,
      });
      return (
        <>
          <Box padding={[0, 0, 32, 0]}>
            <Box
              padding={[16, 0, 16, 0]}
              width="100%"
              maxWidth="1000px"
              style={{ flexDirection: 'row', display: 'flex', gap: '16px' }}
            >
              <Box className={styles.recurringInputsContainer}>
                <Subheading bold id={journeyStartDateLabelId}>
                  Journey Start Date
                </Subheading>
                <Box>
                  <Flex start className={styles.dailyRunTimeContainer}>
                    <FlexItem className={styles.dailyRunTimeInput}>
                      <CalendarSelect
                        onChange={(newStartTriggeringOn) =>
                          onUpdateStartTriggeringOn(
                            newStartTriggeringOn ??
                              nextTriggerDate(
                                trigger.timeOfDay,
                                trigger.timeZone
                              )
                          )
                        }
                        date={trigger.startTriggeringOn}
                        minDate={nextTriggerDate(
                          trigger.timeOfDay,
                          trigger.timeZone
                        )}
                        triggerAriaLabelledBy={journeyStartDateLabelId}
                      />
                    </FlexItem>
                  </Flex>
                </Box>
              </Box>
              <Box className={styles.recurringInputsContainer}>
                <Subheading bold>Daily Run Time</Subheading>
                <Box>
                  <Flex start className={styles.dailyRunTimeContainer}>
                    <FlexItem className={styles.dailyRunTimeInput}>
                      <TimeSelect
                        label="Daily run time for the journey, in the selected timezone"
                        date={date}
                        placeholder="Choose time"
                        onChange={onUpdateRecurringTime}
                      />
                    </FlexItem>
                    <FlexItem className={styles.dailyRunTimeInput}>
                      <GenericSelect
                        label="Timezone for the daily run time"
                        value={timezoneValue?.name}
                        onChange={onUpdateTriggerTimezone}
                        options={timeZones}
                        menuPlacement="bottom"
                        controlBorder="gray30"
                      />
                    </FlexItem>
                  </Flex>
                </Box>
              </Box>
            </Box>
            <Subheading bold>Next Three Run Times:</Subheading>
            <Box padding={[8, 0, 0, 0]}>
              <RecurringNextRuns trigger={trigger} />
            </Box>
          </Box>
          <Box padding={[0, 0, 32, 0]}>
            <Subheading bold>Trigger</Subheading>
            <Box padding={[16, 0, 0, 0]} width="100%" maxWidth="1000px">
              <TriggerSelect
                expression={trigger.triggerCriterion}
                onChange={onUpdateTriggerCriterion}
              />
            </Box>
          </Box>
        </>
      );
    }
    default:
      exhaustiveCheck(mode);
      return null;
  }
}

type EventTriggerConfigurationProps =
  | {
      mode: 'edit';
      trigger: Pick<EventTrigger, 'event' | 'startTriggeringOn'>;
      onUpdateTriggerEvent: (event: string) => void;
      onUpdateStartTriggeringOn: (dateTime: DateTime | undefined) => void;
    }
  | {
      mode: 'read-only';
      trigger: Pick<EventTrigger, 'event' | 'startTriggeringOn'>;
    };

function EventTriggerConfiguration(props: EventTriggerConfigurationProps) {
  const { mode, trigger } = props;

  switch (mode) {
    case 'read-only':
      return (
        <>
          {trigger.startTriggeringOn && (
            <Box margin={[32, 0, 0, 0]}>
              <Subheading bold>Journey Start Date</Subheading>
              <Box>{formatStartDate(trigger.startTriggeringOn)}</Box>
            </Box>
          )}
          <Box margin={[32, 0, 32, 0]}>
            <Subheading bold>Event Name</Subheading>
            <Box>
              {
                SUPPORTED_CAMPAIGN_EVENTS[
                  trigger.event as keyof typeof SUPPORTED_CAMPAIGN_EVENTS
                ]
              }
            </Box>
          </Box>
        </>
      );
    case 'edit': {
      const { onUpdateTriggerEvent, onUpdateStartTriggeringOn } = props;

      return (
        <Box padding={[0, 0, 32, 0]}>
          <Flex start className={styles.dateRadioGroup}>
            <FlexItem start>
              <Radio
                type="radio"
                name="dateType"
                className={styles.dateRadio}
                checked={!trigger.startTriggeringOn}
                onSelect={() => {
                  onUpdateStartTriggeringOn(undefined);
                }}
              />
            </FlexItem>
            <FlexItem>
              <Flex column alignStart>
                <FlexItem>
                  <Body>Immediate</Body>
                </FlexItem>
                <FlexItem>
                  <Caption>
                    The journey will be active immediately after publishing
                  </Caption>
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
          <Flex start className={styles.dateRadioGroup}>
            <FlexItem start>
              <Radio
                type="radio"
                name="dateType"
                className={styles.dateRadio}
                checked={Boolean(trigger.startTriggeringOn)}
                onSelect={() => {
                  onUpdateStartTriggeringOn(
                    DateTime.local({ zone: localeTimezone })
                  );
                }}
              />
            </FlexItem>
            <FlexItem>
              <Flex column alignStart>
                <FlexItem>
                  <Body>Scheduled</Body>
                </FlexItem>
                <FlexItem>
                  <Caption>
                    Choose a date and time for the journey to become active
                  </Caption>
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
          {trigger.startTriggeringOn && (
            <Box
              padding={[0, 0, 16, 32]}
              width="100%"
              maxWidth="1000px"
              style={{ flexDirection: 'row', display: 'flex', gap: '16px' }}
            >
              <Box className={styles.recurringInputsContainer}>
                <Subheading bold>Journey Start Date</Subheading>
                <Flex start className={styles.dailyRunTimeContainer}>
                  <FlexItem className={styles.dailyRunTimeInput}>
                    <CalendarSelect
                      onChange={(newDate) => {
                        if (newDate) onUpdateStartTriggeringOn(newDate);
                      }}
                      date={trigger.startTriggeringOn}
                      minDate={DateTime.now()}
                    />
                  </FlexItem>
                </Flex>
              </Box>
              <Box className={styles.recurringInputsContainer}>
                <Subheading bold>Start Time</Subheading>
                <Flex start className={styles.dailyRunTimeContainer}>
                  <FlexItem className={styles.dailyRunTimeInput}>
                    <TimeSelect
                      date={trigger.startTriggeringOn}
                      placeholder="Choose time"
                      onChange={(newTime) => {
                        if (newTime) onUpdateStartTriggeringOn(newTime);
                      }}
                    />
                  </FlexItem>
                  <FlexItem className={styles.dailyRunTimeInput}>
                    <GenericSelect
                      label="Timezone for the journey start time"
                      value={trigger.startTriggeringOn?.zoneName ?? undefined}
                      onChange={(newTimeZone) => {
                        onUpdateStartTriggeringOn(
                          trigger.startTriggeringOn?.setZone(newTimeZone)
                        );
                      }}
                      options={timeZones}
                      menuPlacement="bottom"
                      controlBorder="gray30"
                    />
                  </FlexItem>
                </Flex>
              </Box>
            </Box>
          )}
          <Box>
            <Subheading bold>Event Name</Subheading>
            <Box width={300} padding={[8, 0, 0, 0]}>
              <EventSelect
                event={trigger.event}
                onChange={(event) => {
                  if (event) onUpdateTriggerEvent(event);
                }}
              />
            </Box>
          </Box>
        </Box>
      );
    }
    default:
      exhaustiveCheck(mode);
      return null;
  }
}

function exhaustiveCheck(type: never) {
  throw new Error(`Unhandled type: ${type}`);
}
