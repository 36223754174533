import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MetabaseEmbedIframe } from 'App/Program/Main/Insight/components/MetabaseEmbedIframe';
import { CollectionsContext } from 'App/Program/Main/Insight/contexts/CollectionsContext';
import { NonFeaturedCollection } from 'App/Program/Main/Insight/pages/NonFeaturedCollection';
import { useProgram } from '../../../../../contexts/program';
import { useUser } from '../../../../../contexts/user';
import { useFeatureFlagsQuery } from '../../../../../hooks/feature-flags';
import { useTableauLibrary } from '../../../../../components/tableauEmbed/useTableau/useTableauLibrary';
import { WorkbooksProvider } from '../../Tableau/contexts/WorkbooksProvider';
import { WorkbookListPage } from '../../Tableau/Workbooks/WorkbookListPage';
import { Text } from '../../../../../DesignSystem/Typography';
import { usePermissions } from '../../../../../contexts/permissions';
import { fetchVerifyUserEntitlement } from '../../../../../services/api-insights';

/*
 * The overview page is rendered within the CollectionsProvider
 * and the CollectionsSidebarTheme
 * the overview report is accessed via the CollectionsContext
 * */
export const OverviewPage: React.FC<RouteComponentProps> = () => {
  const {
    customCollectionsQuery,
    overviewCollectionQuery,
    auditDownload,
  } = React.useContext(CollectionsContext);

  const { data: overviewMetabaseReport } = overviewCollectionQuery;

  const nonFeaturedCollections = React.useMemo(() => {
    const collections = customCollectionsQuery.data || [];

    return collections.filter(
      ({ tags }) =>
        !tags.some((tag) => tag.indexOf('legacy') === 0) &&
        tags.filter((tag) => tag.indexOf('featured') === 0).length === 0
    );
  }, [customCollectionsQuery.data]);

  const hasNoReports =
    !overviewMetabaseReport && nonFeaturedCollections.length === 0;
  const programId = useProgram().id;

  const { id: currentUserId } = useUser();

  const {
    data: tableauIntegrationEnabled,
    isLoading: integrationFlagLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Insights.TableauIntegration');

  const {
    data: tableauIntegrationOverride,
    isLoading: overrideFlagLoading,
  } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration.Override',
    currentUserId
  );

  const flagsLoading = integrationFlagLoading || overrideFlagLoading;
  const ready = useTableauLibrary();
  const [useTableau, setUseTableau] = React.useState(false);

  React.useEffect(() => {
    if (
      !flagsLoading &&
      (tableauIntegrationEnabled?.value || tableauIntegrationOverride?.value) &&
      ready
    ) {
      setUseTableau(true);
    }
  }, [
    tableauIntegrationEnabled,
    tableauIntegrationOverride,
    flagsLoading,
    ready,
  ]);

  const { permissions } = usePermissions();
  const canSeeTableau = useTableau && permissions.insightsPlusAccess;

  const [hasVerifiedEntitlement, setHasVerifiedEntitlement] = React.useState(
    false
  );
  useEffect(() => {
    if (canSeeTableau && !hasVerifiedEntitlement) {
      fetchVerifyUserEntitlement(programId).then();
      setHasVerifiedEntitlement(true);
    }
  }, [canSeeTableau, hasVerifiedEntitlement, programId]);

  return (
    <>
      {canSeeTableau && (
        <WorkbooksProvider path="workbooks">
          <WorkbookListPage path="/" componentOnly />
        </WorkbooksProvider>
      )}
      {hasNoReports ? (
        <h3>You don&apos;t have access to Insights Reports</h3>
      ) : (
        <>
          {canSeeTableau && (
            <Text as="h2" className={{ Heading: true, Bold: true }}>
              All Reports
            </Text>
          )}
          {overviewMetabaseReport && (
            <MetabaseEmbedIframe
              metabaseReport={overviewMetabaseReport}
              auditDownload={auditDownload}
            />
          )}
          {nonFeaturedCollections.map((collection) => (
            <NonFeaturedCollection
              key={collection.id}
              collection={collection}
            />
          ))}
        </>
      )}
    </>
  );
};
