import * as React from 'react';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useSimpleBlocks } from 'hooks/publisher/useSimpleBlocks';
import { Plus } from 'shared/icons';
import cx from 'classnames';
import { PasteFromClipboard } from 'DevMode';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import styles from '../dnd.module.css';

const PEEK_PROXIMITY = 150;

type Proximity = {
  top: number;
  bottom: number;
  right: number;
  left: number;
};

function useProximity<T extends Element = HTMLDivElement>(): {
  element: MutableRefObject<T | null>;
  distance?: Proximity;
} {
  const element = useRef<T>(null);
  const [distance, setDistance] = useState<Proximity>();

  useEffect(() => {
    function onMouseMove(e: MouseEvent) {
      const el = element.current?.getBoundingClientRect();

      const scroll = {
        left: document.body.scrollLeft + document.documentElement.scrollLeft,
        top: document.body.scrollTop + document.documentElement.scrollTop,
      };

      if (el) {
        const top = el.top + scroll.top - e.y;
        const bottom = el.bottom + scroll.top - e.y;
        const left = el.left + scroll.left - e.x;
        const right = el.right + scroll.left - e.x;
        setDistance({ top, bottom, left, right });
      } else {
        setDistance(undefined);
      }
    }

    window.addEventListener('mousemove', onMouseMove);
    return () => window.removeEventListener('mousemove', onMouseMove);
  }, []);

  return { element, distance };
}

export const InsertBar: React.FC<{
  index: number;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onClick: () => void;
}> = ({ index, isOpen, onOpen, onClose, onClick, children }) => {
  const { distance, element } = useProximity();

  const peek =
    distance === undefined
      ? false // specifically omits the right side, as to not distract any inline editing.
      : distance.left < PEEK_PROXIMITY &&
        distance.top < PEEK_PROXIMITY &&
        distance.bottom > -PEEK_PROXIMITY;
  const { insert } = React.useContext(BlocksEditorContext);
  const onPaste = useCallback(
    (value) => {
      insert([value], index);
    },
    [index, insert]
  );

  return (
    <>
      <div
        ref={element}
        data-test="insert-bar"
        data-name="insert-bar"
        className={cx(styles.insertBar, {
          [styles.open]: isOpen,
          [styles.peek]: peek,
        })}
        onMouseLeave={onClose}
      >
        <div className={styles.insertButton} onMouseEnter={onOpen}>
          <button type="button" onClick={onClick}>
            <Plus width={18} height={18} />
          </button>
        </div>

        <div className={styles.insertActions}>{children}</div>
        <PasteFromClipboard onPaste={onPaste} />
      </div>
      <DropBar index={index} />
    </>
  );
};

export const DropBar: React.FC<{ index: number }> = ({ index }) => {
  const { insert: canvasInsert } = React.useContext(BlocksEditorContext);

  const { id: programId } = useProgram();

  const audienceBlockTargetingEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.AudienceBlockTargeting'
  ).data?.value;

  const includeNames: string[] = [
    audienceBlockTargetingEnabled && 'dynamic_block',
  ].filter(Boolean) as string[];

  const blocks = useSimpleBlocks({
    extended: true,
    includeNames: includeNames.length > 0 ? includeNames : undefined,
  });
  const insert = React.useCallback(
    (title: string) => {
      const data = blocks[blocks.findIndex((block) => block.title === title)];
      if (data) canvasInsert(data.block.asset.blocks, index);
    },
    [canvasInsert, blocks, index]
  );
  const inserter = React.useRef(insert);
  inserter.current = insert;

  const [{ isOver, canDrop, title }, dropRef] = useDrop<
    { id: string },
    unknown,
    { isOver: boolean; canDrop: boolean; title: string }
  >(() => ({
    accept: 'add-block-from-panel-to-canvas',
    drop: (item) => {
      inserter.current(item.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      title: monitor.getItem()?.id ?? '',
    }),
  }));

  return (
    <div
      style={{
        display: canDrop ? 'block' : 'none',
        height: isOver ? '100px' : '0px',
        position: 'relative',
        overflow: 'visible',
        transition: 'height 0.15s',
      }}
    >
      <div
        ref={dropRef}
        style={{
          position: 'relative',
          zIndex: Number.MAX_SAFE_INTEGER,
          height: '100px',
          maxHeight: '100px',
          overflow: 'visible',
          transition: 'opacity .3s ease-out',
          opacity: isOver ? 1 : 0,
        }}
      >
        {isOver ? (
          <div
            style={{
              position: 'relative',
              height: '200px',
              top: '-50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Drop to insert
            <strong style={{ display: 'inline-block', margin: '0 .4em' }}>
              {title}
            </strong>
            here.
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              height: '100%',
              top: '-50px',
            }}
          />
        )}
      </div>
    </div>
  );
};
