import cx from 'classnames';
import styles from 'DesignSystem/Table/filters.module.css';
import * as React from 'react';

export const DashboardFilterTriggerButton: React.FC<{
  name: string;
  value: string[];
  onClose?: () => void;
}> = ({ name, value, onClose }) => {
  const classNames = React.useMemo(
    () => ({
      FilterTrigger: styles.FilterTrigger,
      FilterTriggerCloseable: styles.FilterTriggerCloseable,
      FilterTriggerApplied: styles.FilterTriggerApplied,
      FilterTriggerCloseButton: styles.FilterTriggerCloseButton,
      FontWeight400: styles.FontWeight400,
    }),
    []
  );

  const label = React.useMemo(() => {
    if (!value.length) return '';
    const text =
      value[0].length > 30 ? `${value[0].slice(0, 30).trimEnd()}...` : value[0];
    if (value.length === 1) return text;
    return `${text} +${value.length - 1}`;
  }, [value]);

  return (
    <div
      className={cx(classNames.FilterTrigger, {
        [classNames.FilterTriggerCloseable]: !!onClose,
        [classNames.FilterTriggerApplied]: !!onClose || value?.length > 0,
      })}
    >
      <span className={styles.FlexView}>
        {name}: {label}
      </span>
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.append}
      >
        <path
          d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};
