import { useProgram } from 'contexts/program';
import { DynamicBlock } from 'models/dynamic_blocks/dynamic_block';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import {
  DefinitionBlock,
  duplicateFieldData,
  DynamicBlockFieldData,
} from 'models/publisher/block';
import { useCallback, useEffect, useState } from 'react';
import { UseMutateFunction, useMutation, useQueries } from 'react-query';
import { fetchById, upsertDynamicBlock } from 'services/api-dynamic-blocks';
import { useFlashServerErrors } from 'utility/errors';
import { v4 } from 'uuid';

export type DynamicBlocks = {
  [id: string]: DynamicBlock;
};

export type DynamicBlockStatus = {
  isSaving: boolean;
  isLoading: boolean;
  error?: 'unauthorized' | 'not-found';
};

export type UseDynamicBlocks = {
  dynamicBlocks: { [id: string]: DynamicBlock };
  save: (resourceId: number) => void;
  update: (uuid: string, changes: Partial<DynamicBlock>) => void;
  status: DynamicBlockStatus;
};

export const dynamicBlocksContextPrototype: UseDynamicBlocks = {
  dynamicBlocks: {},
  save: () => {},
  update: () => {},
  status: { isSaving: false, isLoading: false },
};

type UseDynamicBlocksProps = {
  blocks: DefinitionBlock[];
  resourceId: number | 'new';
  resourceType: 'Content' | 'Design';
  onUpdate: () => void;
};

export const useDynamicBlocks = ({
  blocks,
  resourceId,
  resourceType,
  onUpdate,
}: UseDynamicBlocksProps): UseDynamicBlocks => {
  const [dynamicBlocks, setDynamicBlocks] = useState<DynamicBlocks>({});
  const dynamicBlockInstances = blocks.filter(
    (i) => i.name === 'dynamic_block'
  );
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { id: programId } = useProgram();
  const flashServerErrors = useFlashServerErrors();

  const queries = useQueries(
    dynamicBlockInstances.map((instance) => ({
      queryKey: [
        'dynamic_block',
        programId,
        (instance.field_data.dynamic_block as DynamicBlockFieldData).uuid,
      ],
      queryFn: () =>
        fetchById(
          programId,
          (instance.field_data.dynamic_block as DynamicBlockFieldData).uuid
        ),
      retry: false,
      enabled: !initialLoadComplete,
    }))
  );

  const allQueriesLoaded =
    queries.length > 0 && queries.every((query) => query.isFetched === true);

  useEffect(() => {
    if (allQueriesLoaded) {
      setInitialLoadComplete(true);
    }
  }, [allQueriesLoaded]);

  useEffect(() => {
    let hasChanged = false;
    const newDynamicBlocks: DynamicBlocks = { ...dynamicBlocks };

    const instanceUUIDs = new Set(
      dynamicBlockInstances.map(
        (instance) =>
          (instance.field_data.dynamic_block as DynamicBlockFieldData).uuid
      )
    );

    // Remove dynamic blocks that are no longer present
    Object.keys(newDynamicBlocks).forEach((uuid) => {
      if (!instanceUUIDs.has(uuid)) {
        delete newDynamicBlocks[uuid];
        hasChanged = true;
      }
    });

    if (allQueriesLoaded) {
      dynamicBlockInstances.forEach((instance) => {
        const { uuid } = instance.field_data
          .dynamic_block as DynamicBlockFieldData;
        const queryResultByUuid = queries.find(
          (q) =>
            q.isError === false &&
            q.data &&
            (q.data as DynamicBlock).uuid === uuid
        );
        // we have a result from the backend and it's not yet present in the state
        if (queryResultByUuid && !newDynamicBlocks[uuid]) {
          const newData = queryResultByUuid.data as DynamicBlock;
          if (!newData.dynamicBlockVariants && newData.id) {
            // just in case - normally this shouldn't happen
            // we want to make sure that we always have at least two variants
            newData.dynamicBlockVariants = [
              {
                name: 'Untitled Variant',
                order: 1,
                default: false,
                uuid: v4(),
                programId,
                dynamicBlockId: newData.id,
              },
              {
                name: 'Default Content',
                order: 2,
                default: true,
                uuid: v4(),
                programId,
                dynamicBlockId: newData.id,
              },
            ];
          }

          newDynamicBlocks[uuid] = newData as DynamicBlock;
          hasChanged = true;
        }
        // we don't have a result from the backend and it's not yet present in the state
        // this is a newly added block
        if (!queryResultByUuid && !newDynamicBlocks[uuid]) {
          const { copyFrom } = instance.field_data
            .dynamic_block as DynamicBlockFieldData;
          if (copyFrom) {
            const sourceBlock = newDynamicBlocks[copyFrom];
            const newDynamicBlock = {
              uuid,
              resourceId,
              resourceType,
              programId,
              dynamicBlockVariants: sourceBlock.dynamicBlockVariants.map(
                (variant) => ({
                  ...variant,
                  id: undefined,
                  uuid: v4(),
                  design: {
                    ...variant.design,
                    blocks: variant.design?.blocks.map((block) => ({
                      ...block,
                      field_data: duplicateFieldData(block.field_data),
                    })),
                  },
                })
              ),
            } as DynamicBlock;
            newDynamicBlocks[uuid] = newDynamicBlock;
          } else {
            newDynamicBlocks[uuid] = {
              uuid,
              resourceId,
              resourceType,
              programId,
              dynamicBlockVariants: [
                {
                  name: 'Untitled Variant',
                  order: 1,
                  default: false,
                  programId,
                  uuid: v4(),
                },
                {
                  name: 'Default Content',
                  order: 2,
                  default: true,
                  uuid: v4(),
                  programId,
                },
              ],
            } as DynamicBlock;
          }
          hasChanged = true;
        }
      });
    }

    if (hasChanged) {
      setDynamicBlocks(newDynamicBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allQueriesLoaded,
    dynamicBlockInstances,
    queries,
    resourceId,
    resourceType,
  ]);

  const persistDynamicBlocks = usePersistDynamicBlocks({
    onIndividualBlockSaveSuccess: (res: DynamicBlock) => {
      setDynamicBlocks((current) => {
        const newDynamicBlocks = { ...current };
        const newVariants = res.dynamicBlockVariants;
        newDynamicBlocks[res.uuid] = {
          ...res,
          dynamicBlockVariants: newVariants as DynamicBlockVariant[],
        };
        return newDynamicBlocks;
      });
    },
  });

  const save = useCallback<UseDynamicBlocks['save']>(
    (newResourceId: number) => {
      const dynamicBlocksToSave = Object.keys(dynamicBlocks).reduce(
        (acc, key) => {
          acc[key] = {
            ...dynamicBlocks[key],
            resourceId: newResourceId,
          };
          return acc;
        },
        {} as DynamicBlocks
      );
      persistDynamicBlocks.save({
        dynamicBlocks: dynamicBlocksToSave,
        onError: (error: Error) => {
          flashServerErrors(error, 'Could not update dynamic block');
        },
      });
    },
    [dynamicBlocks, flashServerErrors, persistDynamicBlocks]
  );

  const update = (uuid: string, changes: Partial<DynamicBlock>) => {
    const newDynamicBlocks = { ...dynamicBlocks };
    newDynamicBlocks[uuid] = { ...newDynamicBlocks[uuid], ...changes };
    setDynamicBlocks(newDynamicBlocks);
    onUpdate();
  };

  return {
    dynamicBlocks,
    save,
    update,
    status: {
      isSaving: persistDynamicBlocks.status.isLoading,
      isLoading: !allQueriesLoaded,
    },
  };
};

type UsePersistDynamicBlocksProps = {
  onIndividualBlockSaveSuccess: (dynamicBlock: DynamicBlock) => void;
};

function useDynamicBlockMutation(
  onSuccess: (dynamicBlock: DynamicBlock) => void
): [boolean, UseMutateFunction<DynamicBlock, Error, DynamicBlock>] {
  const { id: programId } = useProgram();

  const { isLoading, mutate } = useMutation<DynamicBlock, Error, DynamicBlock>(
    ['dynamic_block/persist'],
    (dynamicBlock) => upsertDynamicBlock(programId, dynamicBlock),
    { onSuccess }
  );
  return [isLoading, mutate];
}

export type UseSaveBatch = {
  save: (options: {
    onError: (error: Error) => void;
    dynamicBlocks: DynamicBlocks;
  }) => void;
  status: {
    isLoading: boolean;
  };
};

export const usePersistDynamicBlocks = ({
  onIndividualBlockSaveSuccess,
}: UsePersistDynamicBlocksProps): UseSaveBatch => {
  const [isMutateLoading, mutate] = useDynamicBlockMutation(
    onIndividualBlockSaveSuccess
  );
  const isLoading = isMutateLoading;

  const saveBatch = useCallback<UseSaveBatch['save']>(
    async ({ onError, dynamicBlocks }) => {
      try {
        Object.keys(dynamicBlocks).map((key: string) => {
          const block = dynamicBlocks[key];
          return mutate(block, {
            onSuccess: onIndividualBlockSaveSuccess,
            onError,
          });
        });
      } catch (error) {
        onError(error as Error);
      }
    },
    [mutate, onIndividualBlockSaveSuccess]
  );

  return {
    save: saveBatch,
    status: {
      isLoading,
    },
  };
};
